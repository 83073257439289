import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

function CircularLoading({ height }) {
  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height
      }}
    >
      <CircularProgress />
    </Box>
  );
}

CircularLoading.propTypes = {
  height: PropTypes.number.isRequired
};

export default CircularLoading;
