import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import { saveAs } from 'file-saver';
import moment from 'moment';
// components
import FileContext from '../../context/file/fileContext';
import SnackbarContext from '../../context/snackbar/snackbarContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import { getFileFromServer } from '../../utils/functionUtils';

const previousScreen = '/dashboard/configuration/files';

export default function FileDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const fileContext = useContext(FileContext);
  const { openErrorSnackbar } = useContext(SnackbarContext);
  const { loading, clearData, errors, getFile, file: fileStore } = fileContext;
  const [fileData, setFileData] = useState({
    name: '',
    description: '',
    pathFile: '',
    createdAt: '',
    updatedAt: '',
    user: ''
  });
  // Id received by previous screen
  const fileId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (fileId) {
      getFile(fileId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && fileStore && !errors) {
      setFileData({
        name: fileStore.name || '',
        description: fileStore.description || '',
        pathFile: fileStore.pathFile || '',
        createdAt: fileStore?.createdAt
          ? moment(fileStore.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        updatedAt: fileStore?.updatedAt
          ? moment(fileStore.updatedAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: fileStore.user?.name || ''
      });
    }
  }, [loading, fileStore, errors]);

  const onDownloadFile = async () => {
    const path = fileData.pathFile;
    const titleFile = `${fileData.name}-${Date.now()}`;
    try {
      const resFile = await getFileFromServer(path);
      // Get the MIME type of the file from the server
      const mimeType = resFile.headers['content-type'];
      const blob = new Blob([resFile.data], { type: mimeType });
      saveAs(blob, `${titleFile}`);
    } catch (error) {
      openErrorSnackbar();
    }
  };

  return (
    <Page title="Archivos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del archivo"
            title="Detalles del Archivo"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  placeholder="Ingrese nombre del archivo"
                  label="Nombre"
                  name="name"
                  onChange={null}
                  required
                  value={fileData.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Descripción"
                  placeholder="Información adicional"
                  name="description"
                  multiline
                  onChange={null}
                  value={fileData.description}
                  rows={4}
                  variant="outlined"
                />
              </Grid>
              <Grid item style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                <Grid item>
                  <FormHelperText style={{ fontSize: 14 }}>Archivo</FormHelperText>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={onDownloadFile}
                    startIcon={<Iconify icon="eva:cloud-download-outline" />}
                  >
                    Descargar Archivo
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  value={fileData.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Modificación"
                  name="updatedAt"
                  onChange={null}
                  value={fileData.updatedAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  value={fileData.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              sx={{
                mr: 2
              }}
              variant="outlined"
              type="button"
              onClick={() => navigate(previousScreen)}
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
