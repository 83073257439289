import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Alert,
  Typography
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
// components
import ClientContext from '../../context/client/clientContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Esta seguro de subir el documento?'
};

const fileTypes = ['PDF'];

export default function AddClientDocument() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const clientContext = useContext(ClientContext);
  const { loading, clearData, addDocument, errors, setLoading: setLoadingClient } = clientContext;
  const [document, setDocument] = useState({
    title: '',
    observations: ''
  });
  const [file, setFile] = useState(null);
  const [errorUpload, setErrorUpload] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const clientId = state?.clientId;
  const typeClient = state?.typeClient;
  const previousScreen = '/dashboard/clients/documents';

  // Fetch data
  useEffect(() => {
    if (typeClient && clientId) {
      setLoadingClient(false);
    } else {
      navigate('/');
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [typeClient, clientId]);

  // File
  useEffect(() => {
    if (file) {
      setErrorUpload('');
    }
  }, [file]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDocument({
      ...document,
      [name]: value
    });
  };

  const onChangeFile = (file) => {
    setFile(file);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    // Validate options
    const formData = {
      title: document.title,
      observations: document.observations
    };

    // Add file
    const formFile = new FormData();
    formFile.append('file', file);

    // If file was input
    if (typeof formFile.get(`file`) === 'object' && formFile.get(`file`) !== 'undefined') {
      formData.file = 'file';
    }

    setOpenDialog(false);
    if ((await addDocument(clientId, formData, formFile)) === true) {
      navigate(previousScreen, {
        state: {
          id: clientId,
          typeClient
        }
      });
    }
  };

  return (
    <Page title="Documentos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: clientId, typeClient }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para añadir un nuevo documento"
            title="Añadir documento"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.title)}
                    helperText={errors?.title}
                    fullWidth
                    label="Título"
                    name="title"
                    onChange={handleChange}
                    value={document.title}
                    required
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormHelperText style={{ fontSize: 14, marginBottom: 10 }}>
                    Subir Archivo *
                  </FormHelperText>
                  <FileUploader
                    multiple={false}
                    handleChange={onChangeFile}
                    name="file"
                    types={fileTypes}
                    // eslint-disable-next-line
                    onSizeError={(file) => setErrorUpload(`El tipo de archivo es demasiado grande`)}
                    // eslint-disable-next-line
                    onTypeError={(err) =>
                      setErrorUpload(
                        'El tipo de archivo no es compatible'
                      )
                    }
                    required
                    maxSize={process.env.REACT_APP_MAX_SIZE_UPLOAD}
                    label="Cargue o suelte un archivo aquí mismo"
                    hoverTitle="Suelte aquí"
                  />
                  {errorUpload && <FormHelperText error>{errorUpload}</FormHelperText>}
                  {errors?.file && <FormHelperText error>{errors.file}</FormHelperText>}
                  <Typography variant="body2" style={{ marginTop: 10 }}>
                    {file?.name || 'No se ha seleccionado ningún archivo'}
                  </Typography>
                  {/* prettier-ignore */}
                  <Alert severity="warning" style={{ marginTop: 10 }}>
                    Nota: solo permite subir archivos con extensiones PDF. Tamaño máximo para subir archivos al servidor:{' '}
                    {process.env.REACT_APP_MAX_SIZE_UPLOAD} MB
                  </Alert>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.observations)}
                    fullWidth
                    helperText={errors?.observations}
                    label="Observaciones"
                    name="observations"
                    onChange={handleChange}
                    rows={4}
                    multiline
                    value={document.observations}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen, { state: { id: clientId, typeClient } })}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
