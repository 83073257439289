import React, { useReducer, useContext } from 'react';
import axios from 'axios';
// import { saveAs } from 'file-saver';

import { config } from '../../config/requestConfig';
import ControlContext from './controlContext';
import controlReducer from './controlReducer';
import SnackbarContext from '../snackbar/snackbarContext';
import { printFile } from '../../utils/printFile';

import {
  GET_CONTROLS,
  GET_CONTROL,
  CREATE_CONTROL,
  UPDATE_CONTROL,
  UPDATE_IS_COMPLETED_CONTROL,
  DELETE_CONTROL,
  GET_PAYMENTS_CONTROL,
  GET_PAYMENT_CONTROL,
  ADD_PAYMENT_CONTROL,
  UPDATE_PAYMENT_CONTROL,
  DELETE_PAYMENT_CONTROL,
  SET_LOADING_CONTROL,
  GET_DISCOUNT_CONTROL,
  ADD_DISCOUNT_CONTROL,
  UPDATE_DISCOUNT_CONTROL,
  DELETE_DISCOUNT_CONTROL,
  ADD_SERVICE_CONTROL,
  GET_SERVICE_CONTROL,
  UPDATE_SERVICE_CONTROL,
  DELETE_SERVICE_CONTROL,
  FUSION_CONTROL_SUCCESS,
  GET_BALANCE_NOTIFICATION_CONTROL,
  UPDATE_BALANCE_NOTIFICATION_CONTROL,
  GET_BALANCES_NOTIFICATION_CLIENT,
  SUCCESS_CONTROL_FILE,
  SUCCESS_INVOICE_PAYMENT_FILE,
  CONTROL_ERROR,
  CLEAR_PAYMENTS,
  CLEAR_CONTROL
} from '../types';

function ControlState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    controls: null,
    control: null,
    payments: null,
    payment: null,
    notifications: null,
    notification: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(controlReducer, initialState);

  // Fetch all controls
  const getControls = async ({
    keyword = '',
    field = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/controls?keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_CONTROLS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch all controls
  const getControlsWithCurrentControl = async (controlId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/controls/fusion-control/${controlId}`);
      dispatch({
        type: GET_CONTROLS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single control
  const getControl = async (controlId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/controls/${controlId}`);
      dispatch({
        type: GET_CONTROL,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new control
  const createControl = async (formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/controls`, formData, config);
      dispatch({
        type: CREATE_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update control
  const updateControl = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/controls/${id}`, formData, config);
      dispatch({
        type: UPDATE_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update isCompleted state
  const updateIsCompleted = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/controls/${id}/iscompleted`, formData, config);
      dispatch({
        type: UPDATE_IS_COMPLETED_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete control
  const deleteControl = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/controls/${id}`, config);
      dispatch({
        type: DELETE_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Fusion control
  const fusionControl = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/controls/${id}/fusion`, formData, config);
      dispatch({
        type: FUSION_CONTROL_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Get payments of control
  const getPayments = async (controlId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/controls/${controlId}/payments`);
      dispatch({
        type: GET_PAYMENTS_CONTROL,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Get payment of control
  const getPayment = async (controlId, paymentId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/controls/${controlId}/payments/${paymentId}`);
      dispatch({
        type: GET_PAYMENT_CONTROL,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Add payment of control
  const addPayment = async (controlId, formData) => {
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/controls/${controlId}/payments`, formData, config);
      dispatch({
        type: ADD_PAYMENT_CONTROL,
        payload: res.data?.data
      });
      openSuccessSnackbar();
      // Download invoice
      const paymentId = res.data.data?._id;
      dispatch(
        createInvoicePaymentFile(controlId, paymentId, {
          isDuplicatedPayment: true,
          isTypePrintPaperLetter: true
        })
      );
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update payment of control
  const updatePayment = async (controlId, paymentId, formData) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `/api/v1/controls/${controlId}/payments/${paymentId}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_PAYMENT_CONTROL,
        payload: res.data?.data
      });
      openSuccessSnackbar();
      // Download Invoice
      dispatch(
        createInvoicePaymentFile(controlId, paymentId, {
          isDuplicatedPayment: true,
          isTypePrintPaperLetter: true
        })
      );
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete payment of control
  const deletePayment = async (controlId, paymentId) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/controls/${controlId}/payments/${paymentId}`, config);
      dispatch({
        type: DELETE_PAYMENT_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Get discount of control
  const getDiscount = async (controlId, discountId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/controls/${controlId}/discounts/${discountId}`);
      dispatch({
        type: GET_DISCOUNT_CONTROL,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Add discount of control
  const addDiscount = async (controlId, formData) => {
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/controls/${controlId}/discounts`, formData, config);
      dispatch({
        type: ADD_DISCOUNT_CONTROL,
        payload: res.data?.data
      });
      openSuccessSnackbar();
      // Download invoice
      const discountId = res.data.data?._id;
      dispatch(
        createInvoicePaymentFile(controlId, discountId, {
          isDuplicatedPayment: true,
          isTypePrintPaperLetter: true
        })
      );
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update discount of control
  const updateDiscount = async (controlId, discountId, formData) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `/api/v1/controls/${controlId}/discounts/${discountId}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_DISCOUNT_CONTROL,
        payload: res.data?.data
      });
      openSuccessSnackbar();
      // Download Invoice
      dispatch(
        createInvoicePaymentFile(controlId, discountId, {
          isDuplicatedPayment: true,
          isTypePrintPaperLetter: true
        })
      );
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete discount of control
  const deleteDiscount = async (controlId, discountId) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/controls/${controlId}/discounts/${discountId}`, config);
      dispatch({
        type: DELETE_DISCOUNT_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Add service of control
  const addService = async (controlId, formData) => {
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/controls/${controlId}/services`, formData, config);
      dispatch({
        type: ADD_SERVICE_CONTROL,
        payload: res.data?.data
      });
      openSuccessSnackbar();
      // Download invoice
      const serviceId = res.data.data?._id;
      dispatch(
        createInvoicePaymentFile(controlId, serviceId, {
          isDuplicatedPayment: true,
          isTypePrintPaperLetter: true
        })
      );
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update service of control
  const updateService = async (controlId, serviceId, formData) => {
    try {
      setLoading(true);
      const res = await axios.put(
        `/api/v1/controls/${controlId}/services/${serviceId}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_SERVICE_CONTROL,
        payload: res.data?.data
      });
      openSuccessSnackbar();
      // Download Invoice
      dispatch(
        createInvoicePaymentFile(controlId, serviceId, {
          isDuplicatedPayment: true,
          isTypePrintPaperLetter: true
        })
      );
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Get service of control
  const getService = async (controlId, serviceId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/controls/${controlId}/services/${serviceId}`);
      dispatch({
        type: GET_SERVICE_CONTROL,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Delete service of control
  const deleteService = async (controlId, serviceId) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/controls/${controlId}/services/${serviceId}`, config);
      dispatch({
        type: DELETE_SERVICE_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Fetch notification of control
  const getBalanceNotification = async (controlId, notificationId) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/controls/${controlId}/notifications/balances/${notificationId}`,
        config
      );
      dispatch({
        type: GET_BALANCE_NOTIFICATION_CONTROL,
        payload: res.data?.data
      });
      return true;
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update visibility of notification or financial kardex
  const updateVisibilityControl = async (controlId, type) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/controls/${controlId}/visibility`, { type }, config);
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Edit notification of control
  const updateBalanceNotification = async (controlId, notificationId, formData) => {
    try {
      setLoading(true);
      await axios.put(
        `/api/v1/controls/${controlId}/notifications/balances/${notificationId}`,
        formData,
        config
      );
      dispatch({
        type: UPDATE_BALANCE_NOTIFICATION_CONTROL
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Generate invoice payment file
  const createControlFile = async (controlId) => {
    try {
      setLoading(true);
      // Create File
      await axios.post(`/api/v1/reports/controls/${controlId}`, config);

      // Get file and download
      const resFile = await axios.get(`/api/v1/reports/control.pdf`, {
        responseType: 'blob'
      });
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      // saveAs(pdfBlob, `tarjeta-de-control-${Date.now()}.pdf`);
      printFile(pdfBlob);
      dispatch({
        type: SUCCESS_CONTROL_FILE
      });
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: error.response?.data?.errors
      });
    }
  };

  // Generate invoice payment file
  const createInvoicePaymentFile = async (controlId, paymentId, formData) => {
    try {
      setLoading(true);
      // Create File
      await axios.post(
        `/api/v1/reports/controls/${controlId}/payments/${paymentId}`,
        formData,
        config
      );
      // Get file and download
      const resFile = await axios.get(`/api/v1/reports/payment.pdf`, {
        responseType: 'blob'
      });
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      // saveAs(pdfBlob, `recibo-de-pago-${Date.now()}.pdf`);
      printFile(pdfBlob);
      dispatch({
        type: SUCCESS_INVOICE_PAYMENT_FILE
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CONTROL_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Fetch all controls me
  const getMyControls = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/v1/controls/me');
      dispatch({
        type: GET_CONTROLS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch balance notification me
  const getBalanceNotificationsClient = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/v1/controls/me/notifications/balances');
      dispatch({
        type: GET_BALANCES_NOTIFICATION_CLIENT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONTROL_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Set loading control
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_CONTROL,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_CONTROL
    });
  };

  // Clear data
  const clearPayments = async () => {
    dispatch({
      type: CLEAR_PAYMENTS
    });
  };

  return (
    <ControlContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        controls: state.controls,
        control: state.control,
        payments: state.payments,
        payment: state.payment,
        notifications: state.notifications,
        notification: state.notification,
        loading: state.loading,
        errors: state.errors,
        getControls,
        getControlsWithCurrentControl,
        getControl,
        createControl,
        updateControl,
        updateIsCompleted,
        deleteControl,
        fusionControl,
        getPayments,
        getPayment,
        addPayment,
        updatePayment,
        deletePayment,
        getDiscount,
        addDiscount,
        updateDiscount,
        deleteDiscount,
        addService,
        updateService,
        getService,
        deleteService,
        getBalanceNotification,
        updateVisibilityControl,
        updateBalanceNotification,
        createControlFile,
        createInvoicePaymentFile,
        getMyControls,
        getBalanceNotificationsClient,
        setLoading,
        clearData,
        clearPayments
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </ControlContext.Provider>
  );
}

export default ControlState;
