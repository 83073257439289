import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';
// components
import TransactionContext from '../../context/transaction/transactionContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

export default function UpdateTransaction() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const transactionContext = useContext(TransactionContext);
  const {
    loading,
    clearData,
    updateTransaction,
    getTransaction,
    transaction: transactionData,
    errors
  } = transactionContext;
  const [openDialog, setOpenDialog] = useState(false);
  // Params send another screen
  const transactionType = state?.transactionType;
  const transactionId = state?.id;
  const [transaction, setTransaction] = useState({
    date: moment().format('YYYY-MM-DD'),
    concept: '',
    amount: 0,
    transactionType
  });
  const previousScreen = `/dashboard/finances/transactions/listing?transactionType=${transactionType}`;
  // eslint-disable-next-line
  const titleLowercase = transactionType === 'Ingreso' ? 'ingreso' : transactionType === 'Egreso' ? 'egreso' : '';
  const dialog = {
    message: `¿Estas seguro de actualizar el ${titleLowercase}?`
  };

  useEffect(() => {
    if (transactionType && transactionId) {
      getTransaction(transactionId);
    } else {
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [transactionType, transactionId]);

  useEffect(() => {
    if (!loading && transactionData && !errors) {
      setTransaction({
        date: moment(transactionData.date.slice(0, 10)).format('YYYY-MM-DD'),
        concept: transactionData.concept,
        amount: transactionData.amount || transactionData.amount === 0 ? transactionData.amount : 0,
        transactionType
      });
    }
  }, [loading, transactionData, errors, transactionType]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setTransaction({
      ...transaction,
      [name]: value
    });
  };

  // Validate input number and decimals
  const handleChangeAmount = async (e) => {
    const { value } = e.target;
    let valueField = transaction.amount;

    // eslint-disable-next-line
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      valueField = value;
    }

    if (e.target.value === '') {
      valueField = '';
    }

    setTransaction({
      ...transaction,
      amount: valueField
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      date: transaction.date,
      concept: transaction.concept,
      amount: transaction.amount,
      transactionType: transaction.transactionType
    };

    setOpenDialog(false);
    if ((await updateTransaction(transactionId, formData)) === true) {
      navigate(previousScreen);
    }
  };

  return (
    <Page title="Mi Flujo de Caja | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader={`Ingrese los datos en el formulario para actualizar el ${titleLowercase}`}
            title={`Actualizar ${transactionType}`}
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.date)}
                    helperText={errors?.date}
                    fullWidth
                    label={`Fecha de ${transactionType}`}
                    name="date"
                    onChange={handleChange}
                    required
                    type="date"
                    value={transaction.date}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.amount)}
                    helperText={errors?.amount}
                    fullWidth
                    label="Importe (Bs.)"
                    placeholder="Ingrese monto en bolivianos"
                    name="amount"
                    onChange={handleChangeAmount}
                    required
                    value={transaction.amount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.concept)}
                    helperText={errors?.concept}
                    fullWidth
                    label="Concepto"
                    placeholder="Ingrese concepto de la transacción"
                    name="concept"
                    onChange={handleChange}
                    required
                    multiline
                    rows={4}
                    value={transaction.concept}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen)}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
