import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// components
import Scrollbar from '../../components/Scrollbar';
import ControlContext from '../../context/control/controlContext';
import AuthContext from '../../context/auth/authContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import KardexMoreMenu from '../../components/KardexMoreMenu';
import DialogConfirmation from '../../components/DialogConfirmation';
import CircularLoading from '../../components/CircularLoading';
import { rolesUser } from '../../constants';

const previousScreen = '/dashboard/controls/listing';

const GridStyle = styled(Grid)({
  display: 'inline-flex',
  gap: 10
});

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f2f2'
  }
}));

const TypographyStyle = styled(Typography)({
  display: 'inline-block',
  fontWeight: 'bold'
});

const TypographyTextStyle = styled(Typography)({
  display: 'inline-block'
});

const styles = {
  textTitleTable: {
    border: '1px solid #ddd',
    padding: '5px',
    fontWeight: 'bold'
  },
  textTable: {
    border: '1px solid #ddd',
    padding: '5px'
  }
};

const IconifyStyle = styled(Iconify)(() => ({
  marginBottom: -5,
  height: 30,
  width: 30,
  marginRight: 5
}));

export default function PaymentListing() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const controlContext = useContext(ControlContext);
  const authContext = useContext(AuthContext);
  const {
    loading,
    clearData: clearDataControl,
    errors,
    control: controlData,
    getControl,
    deletePayment,
    deleteDiscount,
    deleteService,
    createControlFile,
    createInvoicePaymentFile,
    updateVisibilityControl
  } = controlContext;
  const { user } = authContext;
  const [dataTable, setDataTable] = useState([]);
  const [control, setControl] = useState({
    controlNumber: '',
    createdAt: '',
    client: {
      name: '',
      nit: '',
      owner: '',
      phoneNumber: '',
      phone: '',
      mainActivity: '',
      secondaryActivity: ''
    },
    phoneNumber: '',
    observations: '',
    typeOfTaxPayer: '',
    dueTaxDate: '',
    billingModality: '',
    service: {
      concept: ''
    },
    isCompleted: false,
    price: 0,
    discount: 0,
    accountAmount: 0,
    balanceAmount: 0,
    years: [],
    user: '',
    isVisibleNotification: false
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: '',
    type: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  // If received by previous screen
  const controlId = state?.id;
  const isAdminUser = user?.data?.role === rolesUser.administrator;
  const isAssistantUser = user?.data?.role === rolesUser.assistant;

  useEffect(() => {
    // Verify if id was passed
    if (controlId) {
      getControl(controlId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearDataControl();
    // eslint-disable-next-line
  }, [controlId, previousScreen]);

  // Load control data
  useEffect(() => {
    if (!loading && controlData) {
      const dateFormatted = controlData.createdAt
        ? moment(controlData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
        : '';

      setControl({
        controlNumber: controlData.controlNumber || '',
        createdAt: dateFormatted,
        client: {
          name: controlData.client?.name || '-',
          owner: controlData.client?.owner || '-',
          nit: controlData.client?.nit || '-',
          phoneNumber: controlData.client?.phoneNumber || '-',
          phone: controlData.client?.phone || '',
          mainActivity: controlData.client?.mainActivity || '-',
          secondaryActivity: controlData.client?.secondaryActivity || '-'
        },
        typeOfTaxPayer: controlData.typeOfTaxPayer || '',
        dueTaxDate: controlData.dueTaxDate || '',
        billingModality: controlData.billingModality || '',
        observations: controlData.observations || '',
        service: {
          concept: controlData.service?.concept || '-'
        },
        price: controlData.price || controlData.price === 0 ? controlData.price.toFixed(2) : 0,
        discount:
          controlData.discount || controlData.discount === 0 ? controlData.discount.toFixed(2) : 0,
        accountAmount:
          controlData.accountAmount || controlData.accountAmount === 0
            ? controlData.accountAmount.toFixed(2)
            : 0,
        balanceAmount:
          controlData.balanceAmount || controlData.balanceAmount === 0
            ? controlData.balanceAmount.toFixed(2)
            : 0,
        isCompleted: controlData.isCompleted,
        years: controlData.years || [],
        isVisibleNotification: controlData.balanceNotification?.isVisibleNotification || false,
        isNewNotification: controlData.balanceNotification?.isNewNotification || false,
        user: controlData?.user?.name || '-'
      });

      // Get payments
      const itemsTable =
        controlData.payments?.map((item) => ({
          id: item._id,
          date: moment(item.createdAt).format('DD/MM/YYYY'),
          paymentNumber: item.paymentNumber,
          concept: item.concept,
          accountAmount: item.accountAmount.toFixed(2),
          balanceAmount: item.balanceAmount.toFixed(2),
          diffBalanceAmount: item.diffBalanceAmount.toFixed(2),
          type: item.type,
          observations: item.observations || '-'
        })) || [];
      setDataTable(itemsTable);
    }
  }, [loading, controlData]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog) {
      setDialog({ message: '', action: '' });
      setItemRowSelected({ id: '', type: '' });
    }
  }, [openDialog]);

  const onReload = async () => {
    getControl(controlId);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    if (dialog.action === 'delete' && itemRowSelected.type === 'Pago') {
      if ((await deletePayment(controlId, itemRowSelected.id)) === true) {
        onReload();
      }
    }

    if (dialog.action === 'delete' && itemRowSelected.type === 'Descuento') {
      if ((await deleteDiscount(controlId, itemRowSelected.id)) === true) {
        onReload();
      }
    }

    if (dialog.action === 'delete' && itemRowSelected.type === 'Adición') {
      if ((await deleteService(controlId, itemRowSelected.id)) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
  };

  const onCreateControlFile = async () => {
    await createControlFile(controlId);
  };

  const handleOperation = (action, id) => {
    if (action === 'update') {
      // find payment
      const payment = controlData.payments.find((p) => p._id === id);
      if (payment.type === 'Pago') {
        navigate('/dashboard/controls/kardex/payments/update', {
          state: { controlId, paymentId: id }
        });
        return;
      }
      if (payment.type === 'Descuento') {
        navigate('/dashboard/controls/kardex/discounts/update', {
          state: { controlId, paymentId: id }
        });
        return;
      }
      if (payment.type === 'Adición') {
        navigate('/dashboard/controls/kardex/services/update', {
          state: { controlId, paymentId: id }
        });
      }
      return;
    }

    if (action === 'pdf') {
      createInvoicePaymentFile(controlId, id, { isTypePrintPaperLetter: true });
      return;
    }

    if (action === 'pdfWithCopy') {
      createInvoicePaymentFile(controlId, id, {
        isDuplicatedPayment: true,
        isTypePrintPaperLetter: true
      });
      return;
    }

    if (action === 'pdfWithPaperRoll') {
      createInvoicePaymentFile(controlId, id, { isTypePrintPaperRoll: true });
      return;
    }

    if (action === 'pdfWithPaperDuplicatedRoll') {
      createInvoicePaymentFile(controlId, id, {
        isTypePrintPaperRoll: true,
        isTypePrintDuplicatedPaperRoll: true
      });
      return;
    }

    if (action === 'details') {
      // find payment
      const payment = controlData.payments.find((p) => p._id === id);
      if (payment.type === 'Pago') {
        navigate('/dashboard/controls/kardex/payments/details', {
          state: { controlId, paymentId: id }
        });
      }
      if (payment.type === 'Descuento') {
        navigate('/dashboard/controls/kardex/discounts/details', {
          state: { controlId, paymentId: id }
        });
      }
      if (payment.type === 'Adición') {
        navigate('/dashboard/controls/kardex/services/details', {
          state: { controlId, paymentId: id }
        });
      }
      return;
    }

    if (action === 'delete') {
      // find payment
      const payment = controlData.payments.find((p) => p._id === id);
      const message = `Esta acción eliminará el recibo con Nº Pago ${payment.paymentNumber} de forma permanente. Esta de seguro de continuar?`;
      setDialog({ message, action });
      setItemRowSelected({
        id: payment._id,
        type: payment.type
      });
      setOpenDialog(true);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleVisibilityChange = (type) => {
    const message =
      type === 'notification'
        ? '¿Está seguro de que desea cambiar la visibilidad de las actualizaciones de saldo? Esto permitirá o restringirá el acceso al menú de actualizaciones de saldo.'
        : '¿Está seguro de que desea cambiar la visibilidad del kardex financiero? Esto permitirá o restringirá el acceso al menú de kardex financiero.';
    setDialog({ message, action: 'changeVisibility', type });
    setOpenDialog(true);
  };

  const handleVisibilitySubmit = async () => {
    if (dialog.action === 'changeVisibility') {
      const success = await updateVisibilityControl(controlId, dialog.type);
      if (success) {
        onReload();
      }
    }
    setOpenDialog(false);
  };

  return (
    <Page title="Kardex Financiero | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Button
                sx={{
                  marginBottom: 2
                }}
                variant="outlined"
                onClick={() => navigate(previousScreen)}
                startIcon={<Iconify icon="eva:arrow-circle-left-outline" />}
              >
                Regresar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mt={3}
              display="flex"
              width="100%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
            >
              <Button
                disabled={loading}
                variant="outlined"
                to="#"
                color="secondary"
                onClick={() => onReload()}
                startIcon={<Iconify icon="eva:refresh-fill" />}
              >
                Recargar
              </Button>
              {isAdminUser && (
                <>
                  <Button
                    disabled={loading}
                    variant="contained"
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    startIcon={<Iconify icon="eva:more-vertical-fill" />}
                  >
                    Acciones
                  </Button>
                  <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'actions-button'
                    }}
                  >
                    <MenuItem
                      disabled={control.isCompleted}
                      onClick={() => {
                        navigate('/dashboard/controls/kardex/payments/create', {
                          state: { id: controlId }
                        });
                        handleClose();
                      }}
                    >
                      <Iconify icon="eva:plus-circle-outline" style={{ marginRight: 8 }} />
                      Nuevo Recibo
                    </MenuItem>
                    <MenuItem
                      disabled={control.isCompleted}
                      onClick={() => {
                        navigate('/dashboard/controls/kardex/services/create', {
                          state: { id: controlId }
                        });
                        handleClose();
                      }}
                    >
                      <Iconify icon="eva:plus-circle-outline" style={{ marginRight: 8 }} />
                      Nuevo Servicio
                    </MenuItem>
                    <MenuItem
                      disabled={control.isCompleted}
                      onClick={() => {
                        navigate('/dashboard/controls/kardex/discounts/create', {
                          state: { id: controlId }
                        });
                        handleClose();
                      }}
                    >
                      <Iconify icon="eva:minus-circle-outline" style={{ marginRight: 8 }} />
                      Nuevo Descuento
                    </MenuItem>
                    <MenuItem
                      disabled={control.isCompleted}
                      onClick={() => {
                        navigate('/dashboard/controls/kardex/notification/update', {
                          state: {
                            id: controlId,
                            notificationId: controlData.balanceNotification?._id || null
                          }
                        });
                        handleClose();
                      }}
                    >
                      <Iconify icon="eva:refresh-outline" style={{ marginRight: 8 }} />
                      Actualizar Saldos
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        onCreateControlFile();
                        handleClose();
                      }}
                    >
                      <Iconify icon="eva:file-text-outline" style={{ marginRight: 8 }} />
                      Imprimir Tarjeta en PDF
                    </MenuItem>
                  </Menu>
                </>
              )}
              {isAssistantUser && (
                <Button
                  disabled={control.isCompleted}
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard/controls/kardex/payments/create', {
                      state: { id: controlId }
                    });
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Recibo
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>

        <Card>
          {/* Content */}
          <CardHeader
            title="Tarjeta de Control de Pagos"
            subheader={`Nº Control ${control.controlNumber}`}
            sx={{
              mb: 1,
              textAlign: 'center'
            }}
          />
          <Divider />
          <CardContent>
            {/* Loading */}
            {loading && <CircularLoading height={100} />}

            <Grid container spacing={3}>
              {control.client.name !== '-' && (
                <GridStyle item md={12} xs={12}>
                  <TypographyStyle>Nombre o Razón Social de la Empresa:</TypographyStyle>
                  <Typography style={{ display: 'inline-block' }}>{control.client.name}</Typography>
                </GridStyle>
              )}
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Responsable Legal y/o Propietario:</TypographyStyle>
                <TypographyTextStyle>{control.client.owner}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>NIT:</TypographyStyle>
                <TypographyTextStyle>{control.client.nit}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Tipo de Contribuyente:</TypographyStyle>
                <TypographyTextStyle>{control.typeOfTaxPayer}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Fecha de cierre de E.E.F.F.:</TypographyStyle>
                <TypographyTextStyle>{control.dueTaxDate}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Teléfonos de Contacto:</TypographyStyle>
                <WhatsAppIcon />
                <a
                  href={`https://api.whatsapp.com/send?phone=${control.client.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {control.client.phoneNumber}
                </a>
                {' - '}
                {control.client.phone && (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${control.client.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {control.client.phone}
                  </a>
                )}
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Modalidad de Facturación:</TypographyStyle>
                <TypographyTextStyle>{control.billingModality}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Gestiones:</TypographyStyle>
                <TypographyTextStyle>
                  {control.years.map((item) => item.year).join(' - ')}
                </TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Actividad Comercial Principal:</TypographyStyle>
                <TypographyTextStyle>{control.client.mainActivity}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Actividad Comercial Secundaria:</TypographyStyle>
                <TypographyTextStyle>{control.client.secondaryActivity}</TypographyTextStyle>
              </GridStyle>
              {isAdminUser && (
                <>
                  <GridStyle item md={12} xs={12}>
                    <TypographyStyle>Servicio:</TypographyStyle>
                    <TypographyTextStyle>{control.service.concept}</TypographyTextStyle>
                  </GridStyle>
                  <GridStyle item md={12} xs={12}>
                    <TypographyStyle>Observaciones:</TypographyStyle>
                    <TypographyTextStyle>{control.observations}</TypographyTextStyle>
                  </GridStyle>
                  <GridStyle item md={6} xs={12}>
                    <TypographyStyle>Precio Total:</TypographyStyle>
                    <TypographyTextStyle>{control.price} Bs.</TypographyTextStyle>
                  </GridStyle>
                  <GridStyle item md={6} xs={12}>
                    <TypographyStyle>Descuento:</TypographyStyle>
                    <TypographyTextStyle>{control.discount} Bs.</TypographyTextStyle>
                  </GridStyle>
                  <GridStyle item md={6} xs={12}>
                    <TypographyStyle>A Cuenta:</TypographyStyle>
                    <TypographyTextStyle>{control.accountAmount} Bs.</TypographyTextStyle>
                  </GridStyle>
                  <GridStyle item md={6} xs={12}>
                    <TypographyStyle>Saldo Actualizado:</TypographyStyle>
                    <TypographyTextStyle>{control.balanceAmount} Bs.</TypographyTextStyle>
                  </GridStyle>
                </>
              )}
            </Grid>
            {errors?.error && (
              <Grid item md={12} xs={12} lg={12}>
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.error}</FormHelperText>
                </Box>
              </Grid>
            )}

            {/* Payments */}
            <Scrollbar>
              <Table
                sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}
                aria-label="simple table"
              >
                <TableHead>
                  <StyledTableRow>
                    <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                      Fecha de Pago
                    </TableCell>
                    <TableCell colSpan={3} align="center" style={styles.textTitleTable}>
                      Pagos Parciales por Servicios - Profesionales
                    </TableCell>
                    <TableCell colSpan={1} align="center" style={styles.textTitleTable}>
                      Costo - Servicios
                    </TableCell>
                    <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                      Observaciones
                    </TableCell>
                    <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                      {' '}
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Nº Pago
                    </TableCell>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Concepto y/o Detalle de Pago
                    </TableCell>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Pago Parcial
                    </TableCell>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Saldo
                    </TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item, i) => (
                    <StyledTableRow key={i}>
                      <TableCell component="th" scope="row" align="center" style={styles.textTable}>
                        {item.date}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        {item.paymentNumber}
                      </TableCell>
                      <TableCell align="left" style={styles.textTable}>
                        {item.concept}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        {item.accountAmount}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        {item.balanceAmount}
                      </TableCell>
                      <TableCell align="left" style={styles.textTable}>
                        {item.observations}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        <KardexMoreMenu
                          id={item.id}
                          isDisabled={!(dataTable.length - 1 === i)}
                          isCompleted={control.isCompleted}
                          isAdminUser={isAdminUser}
                          handleOperation={handleOperation}
                        />
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              {dataTable.length === 0 && (
                <Grid item sx={{ p: 5, textAlign: 'center' }}>
                  <IconifyStyle icon="eva:inbox-outline" />
                  <Typography>Lo sentimos no existen pagos realizados</Typography>
                </Grid>
              )}
              <Divider />
              {isAdminUser && !control.isNewNotification && (
                <Grid container spacing={3}>
                  <Grid item md={12} xs={12} style={{ marginTop: 10 }}>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      Visibilidad de Actualizaciones de Saldo
                    </FormHelperText>
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <Box
                      gap={3}
                      display="flex"
                      alignItems="center"
                      flexDirection={{
                        xs: 'column',
                        md: 'row'
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        Mostrar Actualizaciones de Saldo:
                      </Typography>
                      <Box gap={1} display="flex" alignItems="center">
                        <Switch
                          checked={control.isVisibleNotification}
                          onChange={() => handleVisibilityChange('notification')}
                          color="primary"
                        />
                        <Typography
                          variant="body1"
                          color={control.isVisibleNotification ? '#229A16' : '#B72136'}
                        >
                          {control.isVisibleNotification ? 'Activado' : 'Desactivado'}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Scrollbar>
          </CardContent>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={dialog.action === 'changeVisibility' ? handleVisibilitySubmit : onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
