import React, { useReducer } from 'react';
import axios from 'axios';
import ReportContext from './reportContext';
import reportReducer from './reportReducer';
import {
  GET_TRANSACTIONS_REPORT,
  GET_INVOICES_REPORT,
  SET_LOADING_REPORT,
  REPORT_ERROR,
  CLEAR_ERRORS_REPORT,
  CLEAR_DATA_REPORT
} from '../types';

function ReportState(props) {
  const initialState = {
    transactions: null,
    invoices: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(reportReducer, initialState);

  const getTransactionsReport = async ({ dateBegin = '', dateEnd = '', transactionType = '' }) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/reports/transactions?dateBegin=${dateBegin}&dateEnd=${dateEnd}&transactionType=${transactionType}`
      );
      dispatch({
        type: GET_TRANSACTIONS_REPORT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  const getInvoicesReport = async ({ voucher = '', client = '' }) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/reports/invoices?voucher=${voucher}&client=${client}`);
      dispatch({
        type: GET_INVOICES_REPORT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: REPORT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_REPORT,
      payload: state
    });
  };

  // Clear errors
  const clearErrors = async () => {
    dispatch({
      type: CLEAR_ERRORS_REPORT
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_REPORT
    });
  };

  return (
    <ReportContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        transactions: state.transactions,
        invoices: state.invoices,
        errors: state.errors,
        loading: state.loading,
        getTransactionsReport,
        getInvoicesReport,
        setLoading,
        clearErrors,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </ReportContext.Provider>
  );
}

export default ReportState;
