import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import VoucherContext from './voucherContext';
import voucherReducer from './voucherReducer';
import { config } from '../../config/requestConfig';
import SnackbarContext from '../snackbar/snackbarContext';
import {
  GET_VOUCHERS,
  GET_VOUCHER,
  SET_LOADING_VOUCHER,
  VOUCHER_CREATE_SUCCESS,
  VOUCHER_UPDATE_SUCCESS,
  VOUCHER_DELETE_SUCCESS,
  VOUCHER_ERROR,
  CLEAR_DATA_VOUCHER
} from '../types';

function VoucherState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    vouchers: null,
    voucher: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(voucherReducer, initialState);

  // Fetch all vouchers
  const getVouchers = async ({
    keyword = '',
    field = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/vouchers?keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_VOUCHERS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: VOUCHER_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch all vouchers of client
  const getVouchersByClient = async ({
    client = '',
    keyword = '',
    field = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/vouchers?client=${client}&keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_VOUCHERS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: VOUCHER_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single voucher
  const getVoucher = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/vouchers/${id}`);
      dispatch({
        type: GET_VOUCHER,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: VOUCHER_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new voucher
  const createVoucher = async (formData, client = '') => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/vouchers?client=${client}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: VOUCHER_CREATE_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: VOUCHER_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update voucher
  const updateVoucher = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/vouchers/${id}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: VOUCHER_UPDATE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: VOUCHER_ERROR,
        payload: err.response.data.errors
      });
      openErrorSnackbar();
      return false;
    }
  };

  // Delete voucher
  const deleteVoucher = async (id, client = '') => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/vouchers/${id}?client=${client}`, config);
      openSuccessSnackbar();
      dispatch({
        type: VOUCHER_DELETE_SUCCESS
      });
      return true;
    } catch (err) {
      dispatch({
        type: VOUCHER_ERROR,
        payload: err.response?.data?.errors
      });
      openErrorSnackbar();
      return false;
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_VOUCHER,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_VOUCHER
    });
  };

  return (
    <VoucherContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        vouchers: state.vouchers,
        voucher: state.voucher,
        errors: state.errors,
        loading: state.loading,
        getVouchers,
        getVouchersByClient,
        getVoucher,
        createVoucher,
        updateVoucher,
        deleteVoucher,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </VoucherContext.Provider>
  );
}

export default VoucherState;
