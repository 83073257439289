import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';

import GoBackButton from '../../components/GoBackButton';
import InvoiceContext from '../../context/invoice/invoiceContext';
import ButtonMoreMenu from '../../components/ButtonMoreMenu';
import Page from '../../components/Page';
import ButtonSearchFilter from '../../components/ButtonSearchFilter';
import Iconify from '../../components/Iconify';
import DialogConfirmation from '../../components/DialogConfirmation';
import Search from '../../components/Search';
import SearchDate from '../../components/SearchDate';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';

const TableStyle = styled(Table)(() => ({ minWidth: 650 }));

const optionBtnFilter = ['Nº Factura', 'Fecha', 'NIT/CI/CEX'];

const columnsTable = [
  { id: 0, name: 'Nº Factura' },
  { id: 1, name: 'Fecha de la Factura' },
  { id: 2, name: 'NIT/CI/CEX' },
  { id: 3, name: 'Nombre o Razón Social del Cliente' },
  { id: 4, name: 'Importe (Bs.)' },
  { id: 6, name: 'Observaciones' },
  { id: 7, name: '' }
];

export default function InvoicesListing() {
  const { state } = useLocation();
  const invoiceContext = useContext(InvoiceContext);
  const { loading, getInvoicesByClient, invoices, clearData, deleteInvoice, errors } =
    invoiceContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({ id: '' });
  const [dialog, setDialog] = useState({ message: '', action: '' });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [field, setField] = useState('invoiceIdentifier');
  const [selectedIndex, setSelectedIndex] = useState(0);
  // Id received by previous screen
  const clientId = state?.id;
  const typeClient = state?.typeClient;
  const previousScreen = `/dashboard/clients/listing?typeClient=${typeClient}`;

  useEffect(() => {
    if (clientId) {
      getInvoicesByClient({ client: clientId, field });
    } else {
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  const rows = useMemo(() => {
    const computedItems =
      invoices?.invoices?.map((item) => ({
        id: item._id,
        invoiceIdentifier: item.invoiceIdentifier,
        invoiceDate: moment(item.invoiceDate.slice(0, 10)).format('DD/MM/YYYY'),
        authorizationCode: item.authorizationCode,
        clientIdentification: item.clientIdentification,
        clientName: item.clientName,
        amount: item.amount || item.amount === 0 ? item.amount.toFixed(2) : item.amount,
        observations: item.observations || '-'
      })) || [];

    return computedItems;
  }, [invoices]);

  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    getInvoicesByClient({
      client: clientId,
      keyword: search,
      field,
      pageNumber: page,
      rowsPerPage
    });
  };

  const handleAction = (action, id, data = {}) => {
    if (action === 'update') {
      navigate(`/dashboard/clients/invoices/update`, { state: { id, clientId, typeClient } });
    }
    if (action === 'delete') {
      const { name } = data;
      const message = `Esta seguro de eliminar la factura ${name}?`;
      handleOpenDialog(message, id, 'delete');
    }
    if (action === 'details') {
      navigate(`/dashboard/clients/invoices/details`, { state: { id, clientId, typeClient } });
    }
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    getInvoicesByClient({
      client: clientId,
      keyword: search,
      field,
      pageNumber: newPage + 1,
      rowsPerPage
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getInvoicesByClient({
      client: clientId,
      keyword: search,
      field,
      pageNumber: page,
      rowsPerPage: parseInt(event.target.value, 10)
    });
  };

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteInvoice(itemRowSelected.id, clientId)) === true) {
        onReload();
      }
    }
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  const onClearSearch = () => {
    setSearch('');
    setField('invoiceIdentifier');
    setSelectedIndex(0);
    setPage(0);
    setRowsPerPage(10);
    getInvoicesByClient({ client: clientId, field: 'invoiceIdentifier' });
  };

  const handleBtnSearch = (option) => {
    setPage(0);
    setRowsPerPage(10);

    if (option === 'Nº Factura') {
      setField('invoiceIdentifier');
      setSearch('');
      return;
    }

    if (option === 'Fecha') {
      setField('invoiceDate');
      setSearch('');
      return;
    }

    if (option === 'NIT/CI/CEX') {
      setField('clientIdentification');
      setSearch('');
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (search.trim()) {
      // Search with keyword
      getInvoicesByClient({
        client: clientId,
        keyword: search,
        field,
        pageNumber: page,
        rowsPerPage
      });
      return;
    }

    // Reset get initial data
    onReload();
  };

  return (
    <Page title="Facturas | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />

        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Facturas
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                <Button
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard/clients/invoices/create', {
                      state: { clientId, typeClient }
                    });
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Registro
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Registros del Cliente
                </Typography>
                <form onSubmit={submitHandler}>
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                    alignItems="center"
                    width={{
                      sm: '100%',
                      xs: 650
                    }}
                  >
                    {field === 'invoiceDate' ? (
                      <SearchDate search={search} setSearch={setSearch} type="date" />
                    ) : (
                      <Search search={search} setSearch={setSearch} placeholder="Buscar" />
                    )}
                    <Button type="submit" variant="contained" color="primary">
                      Buscar
                    </Button>
                    <Button onClick={onClearSearch} variant="outlined" color="secondary">
                      Limpiar
                    </Button>
                    <Typography variant="body1" paddingLeft={2}>
                      Buscar por:
                    </Typography>
                    <ButtonSearchFilter
                      options={optionBtnFilter}
                      handleBtnSearch={handleBtnSearch}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  </Box>
                </form>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.invoiceIdentifier}
                      </TableCell>
                      <TableCell>{row.invoiceDate}</TableCell>
                      <TableCell>{row.clientIdentification}</TableCell>
                      <TableCell>{row.clientName}</TableCell>
                      <TableCell>{row.amount}</TableCell>
                      <TableCell>{row.observations}</TableCell>
                      <TableCell>
                        <ButtonMoreMenu
                          id={row.id}
                          name={`Nro ${row.invoiceIdentifier}`}
                          handleAction={handleAction}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={7}
                      count={invoices?.total || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}

        {/* Dialog */}
        <DialogConfirmation
          openDialog={openDialog}
          handleCloseDialog={handleCloseDialog}
          onSubmit={onSubmit}
          dialog={dialog}
          loading={loading}
        />
      </Container>
    </Page>
  );
}
