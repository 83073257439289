import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from './Iconify';

function GoBackButton({ path, params = {} }) {
  const navigate = useNavigate();

  return (
    <Grid container mb={5}>
      <Button
        variant="outlined"
        onClick={() => navigate(path, { state: params })}
        startIcon={<Iconify icon="eva:arrow-circle-left-outline" />}
      >
        Regresar
      </Button>
    </Grid>
  );
}

GoBackButton.propTypes = {
  path: PropTypes.string.isRequired,
  params: PropTypes.object
};

export default GoBackButton;
