const roleOptionsList = [
  {
    _id: '0',
    value: '0',
    label: 'Seleccione una opción'
  },
  {
    _id: '1',
    value: 'auxiliar',
    label: 'Auxiliar'
  },
  {
    _id: '1',
    value: 'administrador',
    label: 'Administrador'
  }
];

// Config with users roles database
const rolesUser = {
  administrator: 'administrador',
  assistant: 'auxiliar',
  client: 'cliente'
};

export { rolesUser, roleOptionsList };
