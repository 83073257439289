import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import ServiceContext from '../../context/service/serviceContext';
import CategoryContext from '../../context/category/categoryContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de actualizar el servicio?'
};

export default function UpdateService() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const serviceContext = useContext(ServiceContext);
  const categoryContext = useContext(CategoryContext);
  const {
    loading,
    getService,
    service: serviceData,
    clearData: clearDataService,
    updateService,
    errors
  } = serviceContext;
  const {
    loading: loadingCategory,
    getCategories,
    categories: categoriesData,
    clearData: clearDataCategory
  } = categoryContext;
  const [service, setService] = useState({
    concept: '',
    price: 0,
    category: '',
    description: ''
  });
  const [categoryOptionsList, setCategoryOptionsList] = useState([
    {
      _id: '0',
      value: '',
      label: 'Seleccione una opción'
    }
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const serviceId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (serviceId) {
      getService(serviceId);
      getCategories();
    } else {
      // Go back screen
      navigate('/');
    }

    return () => {
      clearDataService();
      clearDataCategory();
    };
    // eslint-disable-next-line
  }, [serviceId]);

  // Load categories
  useEffect(() => {
    if (!loadingCategory && categoriesData && !errors) {
      const itemsData = categoriesData.map((item) => ({
        _id: item._id,
        value: item._id,
        label: item.name
      }));
      setCategoryOptionsList([
        {
          _id: '0',
          value: '',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingCategory, categoriesData, errors]);

  useEffect(() => {
    if (!loading && serviceData) {
      setService({
        concept: serviceData.concept || '',
        price: serviceData.price || 0,
        category: serviceData.category?._id || '',
        description: serviceData.description || ''
      });
    }
  }, [loading, serviceData]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    /* eslint no-restricted-globals: ["error", { name: "event", message: "Use local parameter instead." }] */
    if (name === 'price') {
      if (!isNaN(parseFloat(value)) && isFinite(value)) {
        return setService({
          ...service,
          [name]: value
        });
      }
      // if initial value
      if (value === '') {
        return setService({
          ...service,
          [name]: ''
        });
      }
      return setService({
        ...service,
        [name]: service.price
      });
    }

    return setService({
      ...service,
      [name]: value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      concept: service.concept,
      price: service.price,
      category: service.category,
      description: service.description
    };

    setOpenDialog(false);
    if ((await updateService(serviceId, formData)) === true) {
      navigate('/dashboard/configuration/services');
    }
  };

  return (
    <Page title="Servicios | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/configuration/services" />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar el servicio"
            title="Actualizar Servicio"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.concept)}
                    helperText={errors?.concept}
                    fullWidth
                    placeholder="Ingrese nombre del servicio"
                    label="Nombre"
                    name="concept"
                    onChange={handleChange}
                    required
                    value={service.concept}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.price)}
                    fullWidth
                    label="Precio (Bs)"
                    name="price"
                    helperText={errors?.price || 'Monto expresado en Bolivianos'}
                    onChange={handleChange}
                    required
                    value={service.price}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.category)}
                    fullWidth
                    name="category"
                    helperText={errors?.category || 'Categoría'}
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={service.category}
                    variant="outlined"
                  >
                    {categoryOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.description)}
                    fullWidth
                    helperText={errors?.description}
                    label="Descripción"
                    placeholder="Información adicional"
                    name="description"
                    multiline
                    onChange={handleChange}
                    value={service.description}
                    rows={4}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate('/dashboard/configuration/services')}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
