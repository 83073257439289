import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import UserContext from '../../context/user/userContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import { roleOptionsList } from '../../constants';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de crear un nuevo usuario?'
};

export default function CreateUser() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const { loading, clearData, createUser, errors, setLoading } = userContext;
  const [user, setUser] = useState({
    name: '',
    email: '',
    password: '',
    password2: '',
    role: '0'
  });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setLoading(false);

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      name: user.name,
      email: user.email,
      password: user.password,
      password2: user.password,
      role: user.role !== '0' ? user.role : ''
    };

    setOpenDialog(false);
    if ((await createUser(formData)) === true) {
      navigate('/dashboard/users');
    }
  };

  return (
    <Page title="Usuarios | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/users" />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para crear un nuevo usuario"
            title="Crear Usuario"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    fullWidth
                    helperText={errors?.name || 'Identificación única del sistema'}
                    label="Nombre"
                    placeholder="Ej: nombre.apellido"
                    name="name"
                    onChange={handleChange}
                    required
                    value={user.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.email)}
                    fullWidth
                    helperText={errors?.email}
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    value={user.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.password)}
                    fullWidth
                    helperText={errors?.password}
                    label="Contraseña"
                    name="password"
                    onChange={handleChange}
                    required
                    value={user.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.role)}
                    helperText={errors?.role}
                    fullWidth
                    label="Rol"
                    name="role"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={user.role}
                    variant="outlined"
                  >
                    {roleOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate('/dashboard/users')}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
