import React from 'react';
import { TextField, InputAdornment, Box } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from './Iconify';

function Search({ search, setSearch, placeholder }) {
  return (
    <Box width="50%">
      <TextField
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          )
        }}
        className="form-control"
        placeholder={placeholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </Box>
  );
}

Search.defaultProps = {
  placeholder: 'Buscar'
};

Search.propTypes = {
  search: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSearch: PropTypes.func.isRequired,
  placeholder: PropTypes.string
};

export default Search;
