import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config } from '../../config/requestConfig';
import CategoryContext from './categoryContext';
import categoryReducer from './categoryReducer';
import SnackbarContext from '../snackbar/snackbarContext';

import {
  GET_CATEGORIES,
  GET_CATEGORY,
  CREATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  SET_LOADING_CATEGORY,
  CATEGORY_ERROR,
  CLEAR_CATEGORY
} from '../types';

function CategoryState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    categories: null,
    category: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(categoryReducer, initialState);

  // Fetch all categories
  const getCategories = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/categories`);
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single category
  const getCategory = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/categories/${id}`);
      dispatch({
        type: GET_CATEGORY,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new category
  const createCategory = async (formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/categories`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: CREATE_CATEGORY_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CATEGORY_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update category
  const updateCategory = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/categories/${id}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: UPDATE_CATEGORY_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete category
  const deleteCategory = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/categories/${id}`, config);
      dispatch({
        type: DELETE_CATEGORY_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CATEGORY_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Set Loading
  const setLoading = (state) => {
    dispatch({
      type: SET_LOADING_CATEGORY,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_CATEGORY
    });
  };

  return (
    <CategoryContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        categories: state.categories,
        category: state.category,
        errors: state.errors,
        loading: state.loading,
        getCategories,
        getCategory,
        createCategory,
        updateCategory,
        deleteCategory,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </CategoryContext.Provider>
  );
}

export default CategoryState;
