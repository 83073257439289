import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
// context
import FileContext from '../../context/file/fileContext';
import SnackbarContext from '../../context/snackbar/snackbarContext';
// components
import Label from '../../components/Label';
import ButtonMoreMenu from '../../components/ButtonMoreMenu';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import DialogConfirmation from '../../components/DialogConfirmation';
import Search from '../../components/Search';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';
import { getFileFromServer } from '../../utils/functionUtils';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Nombre'
  },
  {
    id: 1,
    name: 'Tipo de Archivo'
  },
  {
    id: 2,
    name: 'Descripción'
  },
  {
    id: 3,
    name: ''
  }
];

export default function Categories() {
  const fileContext = useContext(FileContext);
  const { openErrorSnackbar } = useContext(SnackbarContext);
  const { loading, getFiles, files, clearData, deleteFile, errors } = fileContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    getFiles();

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      files?.map((item) => ({
        id: item._id,
        name: item.name,
        fileType: item.fileType,
        pathFile: item.pathFile,
        description: item.description || '-'
      })) || [];

    if (search) {
      computedItems = computedItems.filter(
        (comment) =>
          comment.name.toLowerCase().includes(search.toLowerCase()) ||
          comment.fileType.toLowerCase().includes(search.toLowerCase()) ||
          comment.description.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [files, page, rowsPerPage, search]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    getFiles();
  };

  const handleAction = async (action, id, data = {}) => {
    if (action === 'update') {
      navigate(`/dashboard/configuration/files/update`, {
        state: { id }
      });
    }

    if (action === 'delete') {
      const { name } = data;
      const message = `Esta acción eliminará el registro de forma permanente. Esta seguro de eliminar el archivo ${name}?`;
      handleOpenDialog(message, id, 'delete');
    }

    if (action === 'details') {
      navigate(`/dashboard/configuration/files/details`, {
        state: { id }
      });
    }

    if (action === 'download') {
      const { name, pathFile } = data;
      const extension = pathFile.split('.').pop();
      const titleFile = `${name}-${Date.now()}.${extension}`;
      try {
        const resFile = await getFileFromServer(pathFile);
        // Get the MIME type of the file from the server
        const mimeType = resFile.headers['content-type'];
        const blob = new Blob([resFile.data], { type: mimeType });
        saveAs(blob, titleFile);
      } catch (error) {
        openErrorSnackbar();
      }
    }
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteFile(itemRowSelected.id)) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  const onClearSearch = () => {
    setSearch('');
    setPage(0);
    getFiles();
  };

  return (
    <Page title="Configuración | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Archivos del Sistema
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                <Button
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard/configuration/files/create');
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Archivo
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Archivos
                </Typography>
                <Box
                  display="flex"
                  gap={2}
                  justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                >
                  <Search
                    search={search}
                    setSearch={setSearch}
                    placeholder="Buscar por nombre, tipo de archivo, descripción"
                  />
                  <Button onClick={onClearSearch} variant="outlined" color="secondary">
                    Limpiar
                  </Button>
                </Box>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    let color;
                    if (row.fileType === 'Imagen') {
                      color = 'info';
                    } else if (row.fileType === 'Documento') {
                      color = 'success';
                    } else {
                      color = 'warning';
                    }

                    return (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.name}
                        </TableCell>
                        <TableCell>
                          {/* prettier-ignore */}
                          <Label
                            variant="ghost"
                            color={color}
                          >
                            {row.fileType}
                          </Label>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.description}
                        </TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={() =>
                              handleAction('download', row.id, {
                                name: row.name,
                                pathFile: row.pathFile
                              })
                            }
                            startIcon={<Iconify icon="eva:cloud-download-outline" />}
                          >
                            Descargar Archivo
                          </Button>
                        </TableCell>
                        <TableCell>
                          <ButtonMoreMenu id={row.id} name={row.name} handleAction={handleAction} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={5}
                      count={files?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
