import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import ClientContext from '../../context/client/clientContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

export default function ClientLegalEntityDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const clientContext = useContext(ClientContext);
  const { loading, clearData, errors, getClient, client: clientData } = clientContext;
  const [client, setClient] = useState({
    name: '',
    owner: '',
    nit: '',
    commercialRegister: '',
    phoneNumber: '',
    phone: '',
    address: '',
    department: '',
    municipality: '',
    ministryOfLabor: {
      roe: '',
      ministryBankAccount: '',
      workers: ''
    },
    healthInsurance: {
      healthInsurance: '',
      code: '',
      insureds: ''
    },
    afp: {
      afp: '',
      cua: '',
      nInsuredUnder65years: '',
      nInsuredOver65years: ''
    },
    userClient: {
      name: '',
      email: ''
    },
    mainActivity: '',
    secondaryActivity: '',
    observations: '',
    createdAt: '',
    user: ''
  });
  // Id received by previous screen
  const clientId = state?.id;
  const typeClient = state?.typeClient;
  const previousScreen = `/dashboard/clients/listing?typeClient=${typeClient}`;

  // Fetch data
  useEffect(() => {
    if (clientId) {
      getClient(clientId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [clientId]);

  // Load legal entity
  useEffect(() => {
    if (!loading && clientData) {
      setClient({
        name: clientData.name || '',
        owner: clientData.owner || '',
        nit: clientData.nit || '',
        commercialRegister: clientData.commercialRegister || '',
        phoneNumber: clientData.phoneNumber || '',
        phone: clientData.phone || '',
        address: clientData.address || '',
        department: clientData.municipality?.department?.name || '',
        municipality: clientData.municipality?.name || '',
        ministryOfLabor: {
          roe: clientData.ministryOfLabor?.roe || '',
          ministryBankAccount: clientData.ministryOfLabor?.ministryBankAccount || '',
          workers:
            clientData.ministryOfLabor?.workers || clientData.ministryOfLabor?.workers === 0
              ? clientData.ministryOfLabor?.workers
              : ''
        },
        healthInsurance: {
          healthInsurance: clientData.healthInsurance?.healthInsurance?.name || '',
          code: clientData.healthInsurance?.code || '',
          insureds:
            clientData.healthInsurance?.insureds || clientData.healthInsurance?.insureds === 0
              ? clientData.healthInsurance?.insureds
              : ''
        },
        afp: {
          afp: clientData.afp?.afp?.name || '',
          cua: clientData.afp?.cua || '',
          nInsuredUnder65years:
            clientData.afp?.nInsuredUnder65years || clientData.afp?.nInsuredUnder65years === 0
              ? clientData.afp?.nInsuredUnder65years
              : '',
          nInsuredOver65years:
            clientData.afp?.nInsuredOver65years || clientData.afp?.nInsuredOver65years === 0
              ? clientData.afp?.nInsuredOver65years
              : ''
        },
        mainActivity: clientData.mainActivity || '',
        secondaryActivity: clientData.secondaryActivity || '',
        userClient: {
          name: clientData.userClient?.name || '',
          email: clientData.userClient?.email || ''
        },
        observations: clientData.observations || '',
        createdAt: clientData?.createdAt
          ? moment(clientData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: clientData?.user?.name || ''
      });
    }
  }, [loading, clientData]);

  return (
    <Page title="Clientes | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del cliente"
            title="Detalles Cliente Empresa Unipersonal"
            sx={{ mb: 1 }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              {/* Legal Representative */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Empresa
                </FormHelperText>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Razón Social"
                  name="name"
                  onChange={null}
                  value={client.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Propietario"
                  name="owner"
                  onChange={null}
                  value={client.owner}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="NIT"
                  name="nit"
                  onChange={null}
                  value={client.nit}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Matricula de Comercio"
                  name="commercialRegister"
                  onChange={null}
                  value={client.commercialRegister}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Teléfono 1"
                  name="phoneNumber"
                  onChange={null}
                  value={client.phoneNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Teléfono 2"
                  name="phone"
                  onChange={null}
                  value={client.phone}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Actividad Comercial Principal"
                  name="mainActivity"
                  onChange={null}
                  value={client.mainActivity}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Actividad Comercial Secundaria"
                  name="secondaryActivity"
                  onChange={null}
                  value={client.secondaryActivity}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Dirección"
                  name="address"
                  onChange={null}
                  value={client.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Departamento"
                  name="department"
                  onChange={null}
                  value={client.department}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Municipio"
                  name="municipality"
                  onChange={null}
                  value={client.municipality}
                  variant="outlined"
                />
              </Grid>

              {/* ministryOfLabor */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Ministerio de Trabajo
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Registro Obligatorio de Empleador"
                  name="roe"
                  onChange={null}
                  value={client.ministryOfLabor.roe}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de CTA Banco Ministerio"
                  name="ministryBankAccount"
                  onChange={null}
                  value={client.ministryOfLabor.ministryBankAccount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Trabajadores"
                  name="workers"
                  onChange={null}
                  value={client.ministryOfLabor.workers}
                  variant="outlined"
                />
              </Grid>

              {/* HealthInsurance */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Seguro de Salud
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="healthInsurance"
                  label="Seguro"
                  onChange={null}
                  value={client.healthInsurance.healthInsurance}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº Seguro de Salud"
                  name="code"
                  onChange={null}
                  value={client.healthInsurance.code}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Asegurados"
                  name="insureds"
                  onChange={null}
                  value={client.healthInsurance.insureds}
                  variant="outlined"
                />
              </Grid>
              {/* Afp */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Administradoras de Fondos de Pensiones
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  name="afp"
                  label="AFP"
                  onChange={null}
                  value={client.afp.afp}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="CUA/NUA/Otro"
                  name="cua"
                  onChange={null}
                  value={client.afp.cua}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Asegurados menores a 65 años"
                  name="nInsuredUnder65years"
                  onChange={null}
                  value={client.afp.nInsuredUnder65years}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Asegurados menores a 65 años"
                  name="nInsuredUnder65years"
                  onChange={null}
                  value={client.afp.nInsuredOver65years}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observations"
                  onChange={null}
                  rows={4}
                  multiline
                  value={client.observations}
                  variant="outlined"
                />
              </Grid>

              {/* Auth */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Acceso al Sistema
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={null}
                  value={client.userClient.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={null}
                  value={client.userClient.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Contraseña"
                  name="password"
                  onChange={null}
                  value="*******"
                  variant="outlined"
                />
              </Grid>

              {/* Auth */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  value={client.createdAt}
                  variant="outlined"
                />
              </Grid>

              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  value={client.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              sx={{
                mr: 2
              }}
              variant="outlined"
              type="button"
              onClick={() => navigate(previousScreen)}
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
