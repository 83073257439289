import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Switch
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Iconify from '../../components/Iconify';
import ClientContext from '../../context/client/clientContext';
import DialogConfirmation from '../../components/DialogConfirmation';

const GridStyle = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  gap: 10
});

export default function ClientSettings() {
  const navigate = useNavigate();
  const { id } = useParams();
  const clientContext = useContext(ClientContext);
  const { loading, client, getClient, updateClientFinancialKardexView } = clientContext;

  const [openDialog, setOpenDialog] = useState(false);
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });

  useEffect(() => {
    const fetchInitialData = () => {
      if (id) {
        getClient(id);
      }
    };

    fetchInitialData();
    // eslint-disable-next-line
  }, [id]);

  const handleVisibilityChange = (type) => {
    const message =
      type === 'kardex'
        ? '¿Está seguro de que desea cambiar la visibilidad del kardex financiero? Esto permitirá o restringirá el acceso al menú de kardex financiero.'
        : '';
    setDialog({ message, action: 'changeVisibility', type });
    setOpenDialog(true);
  };

  const handleVisibilitySubmit = async () => {
    if (dialog.action === 'changeVisibility') {
      const success = await updateClientFinancialKardexView(id);
      if (success) {
        getClient(id);
      }
    }
    setOpenDialog(false);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  return (
    <Container>
      <Grid container mb={5}>
        <Grid item xs={12} md={4}>
          <Button
            variant="outlined"
            onClick={() => navigate(-1)}
            startIcon={<Iconify icon="eva:arrow-circle-left-outline" />}
          >
            Regresar
          </Button>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box
            mt={3}
            display="flex"
            width="100%"
            justifyContent={{ xs: 'center', md: 'flex-end' }}
            flexDirection={{ xs: 'column', md: 'row' }}
            flexWrap="wrap"
            gap={3}
          >
            <Button
              disabled={loading}
              variant="outlined"
              to="#"
              color="secondary"
              onClick={() => getClient(id)}
              startIcon={<Iconify icon="eva:refresh-fill" />}
            >
              Recargar
            </Button>
          </Box>
        </Grid>
      </Grid>

      <Card>
        <CardHeader
          title="Configuraciones del Cliente"
          subheader="Gestiona la visibilidad del kardex financiero"
          sx={{
            mb: 1
          }}
        />
        <CardContent>
          <Grid container spacing={3}>
            <GridStyle item md={12} xs={12}>
              <Box
                gap={3}
                display="flex"
                alignItems="center"
                flexDirection={{
                  xs: 'column',
                  md: 'row'
                }}
              >
                <Typography variant="body1" fontWeight="bold">
                  Acceso al Kardex Financiero:
                </Typography>
                <Switch
                  checked={client?.enableFinancialKardexView || false}
                  onChange={() => handleVisibilityChange('kardex')}
                  color="primary"
                />
                <Typography
                  variant="body1"
                  color={client?.enableFinancialKardexView ? '#229A16' : '#B72136'}
                >
                  {client?.enableFinancialKardexView ? 'Activado' : 'Desactivado'}
                </Typography>
              </Box>
            </GridStyle>
          </Grid>
        </CardContent>
      </Card>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={handleVisibilitySubmit}
        dialog={dialog}
        loading={loading}
      />
    </Container>
  );
}
