import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config } from '../../config/requestConfig';
import UserContext from './userContext';
import userReducer from './userReducer';
import SnackbarContext from '../snackbar/snackbarContext';

import { GET_USERS, GET_USER, SET_LOADING_USER, USER_ERROR, CLEAR_USER } from '../types';

function UserState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    users: null,
    user: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(userReducer, initialState);

  // Fetch all users
  const getUsers = async () => {
    try {
      setLoading(true);
      const res = await axios.get('/api/v1/users');
      dispatch({
        type: GET_USERS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single user
  const getUser = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/users/${id}`);
      dispatch({
        type: GET_USER,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new user
  const createUser = async (formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/users`, formData, config);
      openSuccessSnackbar();
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: USER_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update user
  const updateUser = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/users/${id}`, formData, config);
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update state user
  const updateStateUser = async (id, formData) => {
    try {
      setLoading(true);
      await axios.patch(`/api/v1/users/${id}`, formData, config);
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update auth user
  const updateAuthUser = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/users/auth/${id}`, formData, config);
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete user
  const deleteUser = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/users/${id}`);
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: USER_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Set loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_USER,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_USER
    });
  };

  return (
    <UserContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        users: state.users,
        user: state.user,
        errors: state.errors,
        loading: state.loading,
        getUsers,
        getUser,
        createUser,
        updateUser,
        updateStateUser,
        deleteUser,
        updateAuthUser,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </UserContext.Provider>
  );
}

export default UserState;
