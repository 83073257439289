import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';

// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Esta seguro de añadir un nuevo descuento?'
};
const previousScreen = '/dashboard/controls/kardex';

export default function AddDiscountControl() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const {
    loading,
    errors,
    clearData,
    addDiscount,
    getControl,
    control: controlData
  } = controlContext;
  const [payment, setPayment] = useState({
    concept: '',
    observations: '',
    srv: 0,
    otherObservations: []
  });
  const [control, setControl] = useState({
    years: [],
    accountAmount: 0,
    balanceAmount: 0
  });
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const controlId = state?.id;

  // Fetch data
  useEffect(() => {
    if (controlId) {
      getControl(controlId);
    } else {
      navigate(previousScreen, {
        state: {
          id: controlId
        }
      });
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [controlId, previousScreen]);

  useEffect(() => {
    if (!loading && controlData) {
      setControl({
        ...control,
        years: controlData.years || [],
        accountAmount:
          controlData.accountAmount || controlData.accountAmount === 0
            ? controlData.accountAmount
            : 0,
        balanceAmount:
          controlData.balanceAmount || controlData.balanceAmount === 0
            ? controlData.balanceAmount
            : 0
      });
    }
    // eslint-disable-next-line
  }, [loading, controlData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    return setPayment({
      ...payment,
      [name]: value
    });
  };

  const handleChangeSrv = (e) => {
    let { value } = e.target;
    const { name } = e.target;

    if (value === '') {
      // Get balance amount from store
      const balanceAmountData =
        controlData.balanceAmount || controlData.balanceAmount === 0
          ? controlData.balanceAmount
          : 0;

      // if initial value
      setPayment({
        ...payment,
        [name]: ''
      });
      setControl({
        ...control,
        accountAmount: 0,
        balanceAmount: balanceAmountData
      });
      return;
    }

    // Sum total accountAmount
    // Validate input number and decimals
    if (!Number.isNaN(parseFloat(Number(value))) && Number.isFinite(Number(value))) {
      // Get balance amount from store
      const balanceAmountData =
        controlData.balanceAmount || controlData.balanceAmount === 0
          ? controlData.balanceAmount
          : 0;
      // Check if value is not higher than balanceAmount else set max amount balance amount
      value = value <= balanceAmountData ? value : balanceAmountData;
      setPayment({
        ...payment,
        [name]: value
      });
      setControl({
        ...control,
        accountAmount: value,
        balanceAmount: balanceAmountData - value > 0 ? balanceAmountData - value : 0
      });
    }
  };

  const handleChangeOtherObservation = (e, i) => {
    setPayment({
      ...payment,
      otherObservations: payment.otherObservations.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        }
        return item;
      })
    });
  };

  const onHandleRemoveOtherObservation = (i) => {
    setPayment({
      ...payment,
      otherObservations: payment.otherObservations.filter((item, index) => index !== i)
    });
  };

  const onAddOtherObservation = () => {
    setPayment({
      ...payment,
      otherObservations: [
        ...payment.otherObservations,
        {
          observation: ''
        }
      ]
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      concept: payment.concept,
      observations: payment.observations,
      srv: payment.srv,
      otherObservations: payment.otherObservations
    };

    setOpenDialog(false);
    if ((await addDiscount(controlId, formData)) === true) {
      navigate(previousScreen, {
        state: { id: controlId }
      });
    }
  };

  return (
    <Page title="Descuentos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: controlId }} />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para añadir un nuevo descuento"
            title="Añadir Descuento"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.concept)}
                    helperText={errors?.concept}
                    fullWidth
                    label="Concepto"
                    required
                    name="concept"
                    onChange={handleChange}
                    value={payment.concept}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.srv)}
                    helperText={errors?.srv}
                    fullWidth
                    label="Monto Bs."
                    name="srv"
                    onChange={handleChangeSrv}
                    value={payment.srv}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.observations)}
                    fullWidth
                    helperText={errors?.observations}
                    label="Observaciones"
                    name="observations"
                    onChange={handleChange}
                    value={payment.observations}
                    variant="outlined"
                  />
                </Grid>
                {/* Other observations */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Otras Observaciones
                  </FormHelperText>
                </Grid>
                <Grid item md={12} xs={12} sx={{ marginBottom: 4 }}>
                  {payment.otherObservations.map((item, i) => {
                    const errorPeriod =
                      (errors?.otherObservations && errors.otherObservations[i]) || null;
                    return (
                      <Grid
                        key={i}
                        container
                        spacing={3}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          marginBottom: 3
                        }}
                        paddingLeft={{
                          sm: 0,
                          md: 10
                        }}
                        paddingRight={{
                          sm: 0,
                          md: 10
                        }}
                      >
                        <Grid item md={10} xs={12}>
                          <TextField
                            error={Boolean(errorPeriod?.observation)}
                            fullWidth
                            helperText={errorPeriod?.observation}
                            label="Observación"
                            name="observation"
                            required
                            onChange={(e) => handleChangeOtherObservation(e, i)}
                            value={item.observation}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => onHandleRemoveOtherObservation(i)}
                            endIcon={<Iconify icon="eva:trash-2-outline" />}
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container marginTop={2} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAddOtherObservation}
                      endIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      Añadir Observación
                    </Button>
                  </Grid>
                  {errors?.errorMonths && (
                    <Grid item md={12} xs={12} lg={12}>
                      <FormHelperText error>{errors.errorMonths}</FormHelperText>
                    </Grid>
                  )}
                </Grid>

                {/* Summary */}
                <Grid item md={12} xs={12}>
                  <Box>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      Resumen
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="A Cuenta Bs."
                    name="accountAmount"
                    onChange={null}
                    value={control.accountAmount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Saldo Actualizado Bs."
                    name="balanceAmount"
                    onChange={null}
                    value={control.balanceAmount.toFixed(2)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Gestiones"
                    name="years"
                    onChange={null}
                    value={control.years.map((item) => item.year).join(' - ')}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() =>
                  navigate(previousScreen, {
                    state: {
                      id: controlId
                    }
                  })
                }
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
