import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import password from 'secure-random-password';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';

// components
import HealthInsuranceContext from '../../context/healthInsurance/healthInsuranceContext';
import AfpContext from '../../context/afp/afpContext';
import ClientContext from '../../context/client/clientContext';
import DepartmentContext from '../../context/department/departmentContext';
import MunicipalityContext from '../../context/municipality/municipalityContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message:
    'Esta acción actulizará el cliente y genera un archivo PDF con usuario y contraseña para acceso al sistema. ¿Estas seguro de actualizar el cliente?'
};

export default function UpdateClientUnipersonalEnterprise() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const healthInsuranceContext = useContext(HealthInsuranceContext);
  const afpContext = useContext(AfpContext);
  const departmentContext = useContext(DepartmentContext);
  const municipalityContext = useContext(MunicipalityContext);
  const clientContext = useContext(ClientContext);
  const {
    getHealthInsurances,
    loading: loadingHealthInsurance,
    healthInsurances,
    clearData: clearDataHealthInsurance
  } = healthInsuranceContext;
  const { getAfps, loading: loadingAfp, afps, clearData: clearDataAfp } = afpContext;
  const {
    getDepartments,
    loading: loadingDepartment,
    departments,
    clearData: clearDataDepartment
  } = departmentContext;
  const {
    loading: loadingMunicipality,
    municipalities,
    getMunicipalities,
    clearData: clearDataMunicipality
  } = municipalityContext;
  const {
    loading,
    clearData: clearDataClient,
    getClient,
    client: clientData,
    updateClient,
    errors
  } = clientContext;
  const [client, setClient] = useState({
    name: '',
    owner: '',
    nit: '',
    commercialRegister: '',
    phoneNumber: '',
    phone: '',
    address: '',
    department: '0',
    municipality: '0',
    ministryOfLabor: {
      roe: '',
      ministryBankAccount: '',
      workers: ''
    },
    healthInsurance: {
      healthInsurance: '0',
      code: '',
      insureds: ''
    },
    afp: {
      afp: '0',
      cua: '',
      nInsuredUnder65years: '',
      nInsuredOver65years: ''
    },
    mainActivity: '',
    secondaryActivity: '',
    userClient: {
      name: '',
      email: '',
      password: password.randomPassword({
        length: 8,
        characters: [password.upper, password.lower, password.digits]
      })
    },
    observations: ''
  });
  const [afpOptionsList, setAfpOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [healthInsuranceOptionsList, setHealthInsuranceOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [departmentOptionsList, setDepartmentOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [municipalityOptionsList, setMunicipalityOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const clientId = state?.id;
  const typeClient = state?.typeClient;
  const previousScreen = `/dashboard/clients/listing?typeClient=${typeClient}`;

  // Fetch data
  useEffect(() => {
    if (clientId) {
      getAfps();
      getDepartments();
      getHealthInsurances();
      getClient(clientId);
    } else {
      navigate('/');
    }

    return () => {
      clearDataClient();
      clearDataAfp();
      clearDataHealthInsurance();
      clearDataDepartment();
      clearDataMunicipality();
    };
    // eslint-disable-next-line
  }, [clientId]);

  // Load afps
  useEffect(() => {
    if (!loadingAfp && afps) {
      const itemsData = afps.map((item) => ({
        _id: item._id,
        value: item._id,
        label: `${item.name}`
      }));
      setAfpOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingAfp, afps]);

  // Load healthInsurances
  useEffect(() => {
    if (!loadingHealthInsurance && healthInsurances) {
      const itemsData = healthInsurances.map((item) => ({
        _id: item._id,
        value: item._id,
        label: `${item.name}`
      }));
      setHealthInsuranceOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingHealthInsurance, healthInsurances]);

  // Load departments
  useEffect(() => {
    if (!loadingDepartment && departments) {
      const itemsData = departments.map((item) => ({
        _id: item._id,
        value: item._id,
        label: `${item.name}`
      }));
      setDepartmentOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
    // eslint-disable-next-line
  }, [loadingDepartment, departments]);

  // load municipalities
  useEffect(() => {
    if (!loadingMunicipality && municipalities) {
      // Convert data in option list
      const itemsData = municipalities.map((item) => ({
        _id: item._id,
        value: item._id,
        label: item.name
      }));
      setMunicipalityOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
    // eslint-disable-next-line
  }, [loadingMunicipality, municipalities]);

  // Load unipersonal enterprise
  useEffect(() => {
    if (!loading && clientData && !errors) {
      setClient({
        name: clientData.name || '',
        owner: clientData.owner || '',
        nit: clientData.nit || '',
        commercialRegister: clientData.commercialRegister || '',
        phoneNumber: clientData.phoneNumber || '',
        phone: clientData.phone || '',
        address: clientData.address || '',
        department: clientData.municipality?.department?._id || '0',
        municipality: clientData.municipality?._id || '0',
        ministryOfLabor: {
          roe: clientData.ministryOfLabor?.roe || '',
          ministryBankAccount: clientData.ministryOfLabor?.ministryBankAccount || '',
          workers:
            clientData.ministryOfLabor?.workers || clientData.ministryOfLabor?.workers === 0
              ? clientData.ministryOfLabor?.workers
              : ''
        },
        healthInsurance: {
          healthInsurance: clientData.healthInsurance?.healthInsurance?._id || '0',
          code: clientData.healthInsurance?.code || '',
          insureds:
            clientData.healthInsurance?.insureds || clientData.healthInsurance?.insureds === 0
              ? clientData.healthInsurance?.insureds
              : ''
        },
        afp: {
          afp: clientData.afp?.afp?._id || '0',
          cua: clientData.afp?.cua || clientData.afp?.cua === 0 ? clientData.afp.cua : '',
          nInsuredUnder65years:
            clientData.afp?.nInsuredUnder65years || clientData.afp?.nInsuredUnder65years === 0
              ? clientData.afp?.nInsuredUnder65years
              : '',
          nInsuredOver65years:
            clientData.afp?.nInsuredOver65years || clientData.afp?.nInsuredOver65years === 0
              ? clientData.afp?.nInsuredOver65years
              : ''
        },
        mainActivity: clientData.mainActivity || '',
        secondaryActivity: clientData.secondaryActivity || '',
        userClient: {
          name: clientData.userClient?.name || '',
          email: clientData.userClient?.email || '',
          password: password.randomPassword({
            length: 6,
            characters: [password.upper, password.lower, password.digits]
          })
        },
        observations: clientData.observations || ''
      });

      // Load municipalities
      if (clientData.municipality?.department?._id) {
        getMunicipalities(clientData.municipality?.department?._id);
      }
    }

    // eslint-disable-next-line
  }, [loading, clientData, errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'nit' || name === 'phoneNumber' || name === 'phone') {
      // Validate int number without decimal
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setClient({
          ...client,
          [name]: value
        });
      }
      return true;
    }

    return setClient({
      ...client,
      [name]: value
    });
  };

  const handleChangeDepartment = async (e) => {
    const { name, value } = e.target;

    if (value || value !== '0') {
      setClient({
        ...client,
        [name]: value,
        municipality: '0'
      });
      getMunicipalities(value);
      return true;
    }

    // Reset fields
    return setClient({
      ...client,
      [name]: '',
      municipality: ''
    });
  };

  const handleChangeMinistryOfLabor = (e) => {
    const { name, value } = e.target;

    if (name === 'workers') {
      // Validate int number without decimal
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setClient({
          ...client,
          ministryOfLabor: {
            ...client.ministryOfLabor,
            [name]: value
          }
        });
      }
      return true;
    }

    return setClient({
      ...client,
      ministryOfLabor: {
        ...client.ministryOfLabor,
        [name]: value
      }
    });
  };

  const handleChangeHealthInsurance = (e) => {
    const { name, value } = e.target;

    if (name === 'healthInsurance') {
      setClient({
        ...client,
        healthInsurance: {
          ...client.healthInsurance,
          [name]: value,
          code: '',
          insureds: ''
        }
      });
      return true;
    }

    if (name === 'insureds') {
      // Validate int number without decimal
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setClient({
          ...client,
          healthInsurance: {
            ...client.healthInsurance,
            [name]: value
          }
        });
      }
      return true;
    }

    return setClient({
      ...client,
      healthInsurance: {
        ...client.healthInsurance,
        [name]: value
      }
    });
  };

  const handleChangeAfp = (e) => {
    const { name, value } = e.target;

    if (name === 'afp') {
      setClient({
        ...client,
        afp: {
          ...client.afp,
          [name]: value,
          cua: '',
          nInsuredUnder65years: '',
          nInsuredOver65years: ''
        }
      });
    }

    if (name === 'cua' || name === 'nInsuredUnder65years' || name === 'nInsuredOver65years') {
      // Validate int number without decimal
      const re = /^[0-9\b]+$/;
      if (value === '' || re.test(value)) {
        setClient({
          ...client,
          afp: {
            ...client.afp,
            [name]: value
          }
        });
      }
      return true;
    }

    return setClient({
      ...client,
      afp: {
        ...client.afp,
        [name]: value
      }
    });
  };

  const handleChangeUser = (e) => {
    setClient({
      ...client,
      userClient: {
        ...client.userClient,
        [e.target.name]: e.target.value
      }
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    // Validate options
    const formData = {
      name: client.name,
      owner: client.owner,
      nit: client.nit,
      commercialRegister: client.commercialRegister,
      phoneNumber: client.phoneNumber,
      phone: client.phone,
      address: client.address,
      municipality: client.municipality !== '0' ? client.municipality : '',
      ministryOfLabor: {
        roe: client.ministryOfLabor.roe,
        ministryBankAccount: client.ministryOfLabor.ministryBankAccount,
        workers: client.ministryOfLabor.workers
      },
      healthInsurance: {
        healthInsurance:
          client.healthInsurance.healthInsurance !== '0'
            ? client.healthInsurance.healthInsurance
            : null,
        code: client.healthInsurance.code,
        insureds: client.healthInsurance.insureds
      },
      afp: {
        afp: client.afp.afp !== '0' ? client.afp.afp : null,
        cua: client.afp.cua,
        nInsuredUnder65years: client.afp.nInsuredUnder65years,
        nInsuredOver65years: client.afp.nInsuredOver65years
      },
      mainActivity: client.mainActivity,
      secondaryActivity: client.secondaryActivity,
      userClient: {
        name: client.userClient.name,
        password: client.userClient.password,
        email: client.userClient.email
      },
      type: 'Empresas Unipersonales',
      observations: client.observations
    };

    setOpenDialog(false);
    setOpenDialog(false);
    if ((await updateClient(clientId, formData)) === true) {
      navigate(previousScreen);
    }
  };

  return (
    <Page title="Clientes | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar el cliente"
            title="Actualizar Cliente Empresa Unipersonal"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                {/* Legal Representative */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Empresa
                  </FormHelperText>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    fullWidth
                    label="Razón Social"
                    name="name"
                    onChange={handleChange}
                    required
                    value={client.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.owner)}
                    helperText={errors?.owner}
                    fullWidth
                    label="Propietario"
                    name="owner"
                    onChange={handleChange}
                    required
                    value={client.owner}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.nit)}
                    fullWidth
                    label="NIT"
                    name="nit"
                    helperText={errors?.nit}
                    onChange={handleChange}
                    required
                    value={client.nit}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.commercialRegister)}
                    fullWidth
                    label="Nº de Matricula de Comercio"
                    name="commercialRegister"
                    helperText={errors?.commercialRegister}
                    onChange={handleChange}
                    required
                    value={client.commercialRegister}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.phoneNumber)}
                    fullWidth
                    label="Teléfono 1"
                    name="phoneNumber"
                    helperText={errors?.phoneNumber}
                    placeholder="Ingresar codigo de país antes del número"
                    onChange={handleChange}
                    required
                    value={client.phoneNumber}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.phone)}
                    fullWidth
                    label="Teléfono 2"
                    name="phone"
                    helperText={errors?.phone}
                    placeholder="Ej. 59176123456"
                    onChange={handleChange}
                    value={client.phone}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.mainActivity)}
                    fullWidth
                    label="Actividad Comercial Principal"
                    name="mainActivity"
                    helperText={errors?.mainActivity}
                    onChange={handleChange}
                    required
                    value={client.mainActivity}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.secondaryActivity)}
                    fullWidth
                    label="Actividad Comercial Secundaria"
                    name="secondaryActivity"
                    helperText={errors?.secondaryActivity}
                    onChange={handleChange}
                    value={client.secondaryActivity}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.address)}
                    fullWidth
                    label="Dirección"
                    name="address"
                    helperText={errors?.address}
                    onChange={handleChange}
                    required
                    value={client.address}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.department)}
                    helperText={errors?.department}
                    fullWidth
                    name="department"
                    label="Departamento"
                    onChange={handleChangeDepartment}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={client.department}
                    variant="outlined"
                  >
                    {departmentOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.municipality)}
                    helperText={errors?.municipality}
                    fullWidth
                    name="municipality"
                    label="Municipio"
                    onChange={handleChange}
                    required
                    select
                    SelectProps={{ native: true }}
                    value={client.municipality}
                    variant="outlined"
                  >
                    {municipalityOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* ministryOfLabor */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Ministerio de Trabajo
                  </FormHelperText>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.ministryOfLabor?.roe)}
                    fullWidth
                    label="Nº de Registro Obligatorio de Empleador"
                    name="roe"
                    helperText={errors?.ministryOfLabor?.roe}
                    onChange={handleChangeMinistryOfLabor}
                    required
                    value={client.ministryOfLabor.roe}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.ministryOfLabor?.ministryBankAccount)}
                    fullWidth
                    label="Nº de CTA Banco Ministerio"
                    name="ministryBankAccount"
                    helperText={errors?.ministryOfLabor?.ministryBankAccount}
                    onChange={handleChangeMinistryOfLabor}
                    required
                    value={client.ministryOfLabor.ministryBankAccount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.ministryOfLabor?.workers)}
                    fullWidth
                    label="Nº de Trabajadores"
                    name="workers"
                    helperText={errors?.ministryOfLabor?.workers}
                    onChange={handleChangeMinistryOfLabor}
                    required
                    value={client.ministryOfLabor.workers}
                    variant="outlined"
                  />
                </Grid>

                {/* HealthInsurance */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Seguro de Salud
                  </FormHelperText>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.healthInsurance?.healthInsurance)}
                    helperText={errors?.healthInsurance?.healthInsurance}
                    fullWidth
                    name="healthInsurance"
                    label="Seguro"
                    onChange={handleChangeHealthInsurance}
                    select
                    SelectProps={{ native: true }}
                    value={client.healthInsurance.healthInsurance}
                    variant="outlined"
                  >
                    {healthInsuranceOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={client.healthInsurance.healthInsurance === '0'}
                    error={Boolean(errors?.healthInsurance?.code)}
                    helperText={errors?.healthInsurance?.code}
                    fullWidth
                    label="Nº Seguro de Salud"
                    name="code"
                    onChange={handleChangeHealthInsurance}
                    value={client.healthInsurance.code}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={client.healthInsurance.healthInsurance === '0'}
                    error={Boolean(errors?.healthInsurance?.insureds)}
                    helperText={errors?.healthInsurance?.insureds}
                    fullWidth
                    label="Nº de Asegurados"
                    name="insureds"
                    onChange={handleChangeHealthInsurance}
                    value={client.healthInsurance.insureds}
                    variant="outlined"
                  />
                </Grid>

                {/* Afp */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Administradoras de Fondos de Pensiones
                  </FormHelperText>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.afp?.afp)}
                    helperText={errors?.afp?.afp}
                    fullWidth
                    name="afp"
                    label="AFP"
                    onChange={handleChangeAfp}
                    select
                    SelectProps={{ native: true }}
                    value={client.afp.afp}
                    variant="outlined"
                  >
                    {afpOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={client.afp.afp === '0'}
                    error={Boolean(errors?.afp?.cua)}
                    helperText={errors?.afp?.cua}
                    fullWidth
                    label="CUA/NUA/Otro"
                    name="cua"
                    onChange={handleChangeAfp}
                    value={client.afp.cua}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={client.afp.afp === '0'}
                    error={Boolean(errors?.afp?.nInsuredUnder65years)}
                    helperText={errors?.afp?.nInsuredUnder65years}
                    fullWidth
                    label="Nº de Asegurados menores a 65 años"
                    name="nInsuredUnder65years"
                    onChange={handleChangeAfp}
                    value={client.afp.nInsuredUnder65years}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    disabled={client.afp.afp === '0'}
                    error={Boolean(errors?.afp?.nInsuredOver65years)}
                    helperText={errors?.afp?.nInsuredOver65years}
                    fullWidth
                    label="Nº de Asegurados mayores a 65 años"
                    name="nInsuredOver65years"
                    onChange={handleChangeAfp}
                    value={client.afp.nInsuredOver65years}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.observations)}
                    fullWidth
                    helperText={errors?.observations}
                    label="Observaciones"
                    name="observations"
                    onChange={handleChange}
                    rows={4}
                    multiline
                    value={client.observations}
                    variant="outlined"
                  />
                </Grid>

                {/* Auth */}
                <Grid item md={12} xs={12} lg={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Acceso al Sistema
                  </FormHelperText>
                  <FormHelperText>
                    Los siguientes campos son los datos con los que el cliente podrá acceder al
                    sistema
                  </FormHelperText>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.userClient?.name)}
                    fullWidth
                    helperText={errors?.userClient?.name || 'Identificación única del sistema'}
                    label="Nombre"
                    placeholder="Ej: nombre.apellido"
                    name="name"
                    onChange={handleChangeUser}
                    required
                    value={client.userClient.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.userClient?.email)}
                    fullWidth
                    helperText={errors?.userClient?.email}
                    label="Email"
                    name="email"
                    autoComplete="off"
                    onChange={handleChangeUser}
                    required
                    value={client.userClient.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.userClient?.password)}
                    fullWidth
                    helperText={errors?.userClient?.password || 'Contraseña generada por sistema'}
                    label="Contraseña"
                    name="password"
                    onChange={handleChangeUser}
                    required
                    value={client.userClient.password}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen)}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
