import React, { useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';

// context
import DepartmentContext from '../../context/department/departmentContext';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Departamento'
  },
  {
    id: 1,
    name: ''
  }
];

export default function Departments() {
  const departmentContext = useContext(DepartmentContext);
  const { loading, getDepartments, departments, errors } = departmentContext;
  const navigate = useNavigate();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    getDepartments();
    // eslint-disable-next-line
  }, []);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      departments?.map((item) => ({
        ...item,
        id: item._id
      })) || [];

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [departments, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onReload = () => {
    getDepartments();
  };

  return (
    <Page title="Configuración | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Departamentos
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Departamentos
                </Typography>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            navigate('/dashboard/configuration/departments/municipalities', {
                              state: { departmentId: row.id }
                            })
                          }
                          color="secondary"
                          variant="contained"
                        >
                          Ver Municipios
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={5}
                      count={departments?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>
    </Page>
  );
}
