import {
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  SET_LOADING_TRANSACTION,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_ERROR,
  CLEAR_DATA_TRANSACTION
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
        errors: null,
        loading: false
      };
    case GET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        loading: false,
        errors: null
      };
    case SET_LOADING_TRANSACTION:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case TRANSACTION_CREATE_SUCCESS:
    case TRANSACTION_UPDATE_SUCCESS:
    case TRANSACTION_DELETE_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case TRANSACTION_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_DATA_TRANSACTION:
      return {
        ...state,
        transactions: null,
        transaction: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
