import { roleOptionsList, rolesUser } from './roles';
import { yearOptionsList } from './years';

const monthOptionsList = [
  {
    value: '0',
    label: 'Seleccione una opción'
  },
  {
    value: 'enero',
    label: 'enero'
  },
  {
    value: 'febrero',
    label: 'febrero'
  },
  {
    value: 'marzo',
    label: 'marzo'
  },
  {
    value: 'abril',
    label: 'abril'
  },
  {
    value: 'mayo',
    label: 'mayo'
  },
  {
    value: 'junio',
    label: 'junio'
  },
  {
    value: 'julio',
    label: 'julio'
  },
  {
    value: 'agosto',
    label: 'agosto'
  },
  {
    value: 'septiembre',
    label: 'septiembre'
  },
  {
    value: 'octubre',
    label: 'octubre'
  },
  {
    value: 'noviembre',
    label: 'noviembre'
  },
  {
    value: 'diciembre',
    label: 'diciembre'
  }
];

const typeOfTaxPayerOptionsList = [
  {
    value: '0',
    label: 'Seleccione una opción'
  },
  {
    value: 'Newton',
    label: 'Newton'
  },
  {
    value: 'Resto',
    label: 'Resto'
  }
];

export { roleOptionsList, rolesUser, monthOptionsList, yearOptionsList, typeOfTaxPayerOptionsList };
