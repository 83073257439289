import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import InvoiceContext from './invoiceContext';
import invoiceReducer from './invoiceReducer';
import { config } from '../../config/requestConfig';
import SnackbarContext from '../snackbar/snackbarContext';
import {
  GET_INVOICES,
  GET_INVOICE,
  SET_LOADING_INVOICE,
  INVOICE_CREATE_SUCCESS,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_DELETE_SUCCESS,
  INVOICE_ERROR,
  CLEAR_DATA_INVOICE
} from '../types';

function InvoiceState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    invoices: null,
    invoice: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(invoiceReducer, initialState);

  // Fetch all invoices
  const getInvoices = async ({
    keyword = '',
    field = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/invoices?keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_INVOICES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: INVOICE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch all invoices of client
  const getInvoicesByClient = async ({
    client = '',
    keyword = '',
    field = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/invoices?client=${client}&keyword=${keyword}&field=${field}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_INVOICES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: INVOICE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single invoice
  const getInvoice = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/invoices/${id}`);
      dispatch({
        type: GET_INVOICE,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: INVOICE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new invoice
  const createInvoice = async (formData, client = '') => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/invoices?client=${client}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: INVOICE_CREATE_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: INVOICE_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update invoice
  const updateInvoice = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/invoices/${id}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: INVOICE_UPDATE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: INVOICE_ERROR,
        payload: err.response.data.errors
      });
      openErrorSnackbar();
      return false;
    }
  };

  // Delete invoice
  const deleteInvoice = async (id, client = '') => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/invoices/${id}?client=${client}`, config);
      openSuccessSnackbar();
      dispatch({
        type: INVOICE_DELETE_SUCCESS
      });
      return true;
    } catch (err) {
      dispatch({
        type: INVOICE_ERROR,
        payload: err.response?.data?.errors
      });
      openErrorSnackbar();
      return false;
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_INVOICE,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_INVOICE
    });
  };

  return (
    <InvoiceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        invoices: state.invoices,
        invoice: state.invoice,
        errors: state.errors,
        loading: state.loading,
        getInvoices,
        getInvoicesByClient,
        getInvoice,
        createInvoice,
        updateInvoice,
        deleteInvoice,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </InvoiceContext.Provider>
  );
}

export default InvoiceState;
