import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthContext from '../context/auth/authContext';
import ClientContext from '../context/client/clientContext';

function PrivateRoute({ component: RouteComponent, ...rest }) {
  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);
  const { isAuthenticated, loading, user, loadUser, logout } = authContext;
  const { getClientFinancialKardexView } = clientContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      // If auth is disabled
      if (user && user.data && !user.data.state) {
        logout();
      }

      // Load enableFinancialKardexView if user is a client
      if (user && user.data && user.data.role === 'cliente') {
        getClientFinancialKardexView();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated, user]);

  if (isAuthenticated && loading === false) {
    return <RouteComponent {...rest} />;
  }

  if (isAuthenticated === false && loading === false) {
    return <Navigate to="/login" />;
  }

  return null;
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};

export default PrivateRoute;
