import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import ClientContext from '../../context/client/clientContext';
import AuthContext from '../../context/auth/authContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ClientMoreMenu from '../../components/ClientMoreMenu';
import DialogConfirmation from '../../components/DialogConfirmation';
import Search from '../../components/Search';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';
import { rolesUser } from '../../constants';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Razón Social'
  },
  {
    id: 1,
    name: 'NIT'
  },
  {
    id: 2,
    name: 'Representante Legal'
  },
  {
    id: 3,
    name: 'Departamento'
  },
  {
    id: 4,
    name: 'Actividad Comercial'
  },
  {
    id: 5,
    name: ''
  },
  {
    id: 6,
    name: ''
  }
];

export default function ClientLegalEntitiesListing(props) {
  const clientContext = useContext(ClientContext);
  const authContext = useContext(AuthContext);
  const { loading, getClients, clients, clearData, deleteClient, errors, updateClientVisibility } =
    clientContext;
  const { user } = authContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const { isClientLegalEntity, typeClient } = props;
  const isAdminUser = user?.data?.role === rolesUser.administrator;

  useEffect(() => {
    if (isClientLegalEntity) {
      getClients({ type: 'Personas Juridicas SRL' });
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [isClientLegalEntity]);

  // Get data table
  const rows = useMemo(() => {
    const computedItems =
      clients?.clients?.map((item) => ({
        id: item._id,
        name: item.name || '',
        nit: item.nit || '',
        legalRepresentative: item.legalRepresentative
          ? `${item.legalRepresentative.name} ${item.legalRepresentative.lastName}`
          : '',
        department: item.municipality?.department?.name || '-',
        mainActivity: item.mainActivity
      })) || [];

    return computedItems;
  }, [clients]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    getClients({
      keyword: search,
      type: 'Personas Juridicas SRL',
      pageNumber: page,
      rowsPerPage
    });
  };

  const handleChangePage = (event, newPage) => {
    getClients({
      type: 'Personas Juridicas SRL',
      keyword: search,
      pageNumber: newPage + 1,
      rowsPerPage
    });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getClients({
      type: 'Profesionales Independientes',
      keyword: search,
      pageNumber: page,
      rowsPerPage: parseInt(event.target.value, 10)
    });
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const submitHandler = (e) => {
    e.preventDefault();
    if (search.trim()) {
      // Search with keyword
      getClients({
        keyword: search,
        type: 'Personas Juridicas SRL',
        pageNumber: page,
        rowsPerPage
      });
      return;
    }

    // Reset get initial data
    onReload();
  };

  const handleAction = (action, id, data = {}) => {
    if (action === 'documents') {
      navigate(`/dashboard/clients/documents`, {
        state: { id, typeClient }
      });
    }

    if (action === 'update') {
      navigate(`/dashboard/clients/update?typeClient=${typeClient}`, {
        state: { id, typeClient }
      });
    }

    if (action === 'delete') {
      const { name } = data;
      const message = `Esta acción eliminará el cliente, kardex financieros, facturas, talonarios, ingresos, egresos, documentos, usuario de forma permanente. Esta seguro de eliminar a cliente ${name}?`;
      handleOpenDialog(message, id, 'delete');
    }

    if (action === 'details') {
      navigate(`/dashboard/clients/details?typeClient=${typeClient}`, {
        state: { id, typeClient }
      });
    }

    if (action === 'vouchers') {
      navigate(`/dashboard/clients/vouchers/listing`, {
        state: { id, typeClient }
      });
    }

    if (action === 'invoices') {
      navigate(`/dashboard/clients/invoices/listing`, {
        state: { id, typeClient }
      });
    }

    if (action === 'financialKardexVisibility') {
      const message =
        '¿Está seguro de que desea cambiar la visibilidad del kardex financiero? Esto permitirá o restringirá el acceso al menú de kardex financiero.';
      handleOpenDialog(message, id, 'changeVisibility');
    }
  };

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteClient(itemRowSelected.id)) === true) {
        onReload();
      }
    }

    if (dialog.action === 'changeVisibility') {
      if ((await updateClientVisibility(itemRowSelected.id)) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  const onClearSearch = () => {
    setSearch('');
    setPage(0);
    setRowsPerPage(10);
    getClients({ type: 'Personas Juridicas SRL', pageNumber: 0, rowsPerPage: 10 });
  };

  return (
    <Page title="Clientes | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Clientes Personas Jurídicas SRL
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                <Button
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/dashboard/clients/create?typeClient=${typeClient}`, {
                      state: {
                        typeClient
                      }
                    });
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Cliente
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Clientes SRL - S.A. - Fundaciones - Sindicatos
                </Typography>
                <form onSubmit={submitHandler}>
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                  >
                    <Search
                      search={search}
                      setSearch={setSearch}
                      placeholder="Buscar por razón social, nit"
                    />
                    <Button type="submit" variant="contained" color="primary">
                      Buscar
                    </Button>
                    <Button onClick={onClearSearch} variant="outlined" color="secondary">
                      Limpiar
                    </Button>
                  </Box>
                </form>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>{row.nit}</TableCell>
                      <TableCell>{row.legalRepresentative}</TableCell>
                      <TableCell>{row.department}</TableCell>
                      <TableCell>{row.mainActivity}</TableCell>
                      <TableCell>
                        <ClientMoreMenu
                          id={row.id}
                          name={row.name}
                          handleAction={handleAction}
                          isAdminUser={isAdminUser}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={6}
                      count={clients?.total || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}

ClientLegalEntitiesListing.propTypes = {
  isClientLegalEntity: PropTypes.bool.isRequired,
  typeClient: PropTypes.string.isRequired
};
