// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Container, Typography } from '@mui/material';
// layouts
// components
import Page from '../components/Page';
import { ForgotPasswordForm } from '../sections/authentication/forgotpassword';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function ForgotPassword() {
  return (
    <RootStyle title="Forgot Password | G&G Asesor">
      <SectionStyle sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
          Olvido Contraseña
        </Typography>
        <img src="/static/illustrations/illustration_forgot_password.png" alt="forgot password" />
      </SectionStyle>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              G&G Asesor
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Ingrese la dirección de correo electrónico asociada con su cuenta y le enviaremos un
              enlace para restablecer su contraseña.
            </Typography>
          </Stack>

          <ForgotPasswordForm />
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
