import React, { useEffect, useContext, useState } from 'react';
// material
import {
  Card,
  Button,
  Container,
  Grid,
  FormHelperText,
  CardContent,
  Typography,
  Stack,
  Box,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import moment from 'moment';

// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import CircularLoading from '../../components/CircularLoading';
import Iconify from '../../components/Iconify';

const GridStyle = styled(Grid)({
  display: 'inline-flex',
  gap: 10
});

const TableStyled = styled(Table)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '80%'
  }
}));

const TableCellLabelStyled = styled(TableCell)({
  fontWeight: 'bold',
  fontSize: 16,
  border: '1px solid #ddd',
  padding: '10px'
});

const TableCellValueStyled = styled(TableCell)({
  fontSize: 16,
  border: '1px solid #ddd',
  padding: '10px'
});

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f2f2'
  }
}));

const IconifyStyle = styled(Iconify)(() => ({
  marginBottom: -5,
  height: 30,
  width: 30,
  marginRight: 5
}));

export default function BalanceNotificationClient() {
  const controlContext = useContext(ControlContext);
  const {
    loading,
    errors,
    clearData,
    getBalanceNotificationsClient,
    notifications: notificationsData
  } = controlContext;
  const [notifications, setNotifications] = useState([]);

  // Fetch data
  useEffect(() => {
    getBalanceNotificationsClient();
    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && notificationsData) {
      let data =
        notificationsData.map((item) => {
          const singleNotification = item.balanceNotification;
          const bookExpirationDate = singleNotification
            ? moment(singleNotification.bookExpirationDate.slice(0, 10)).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY');
          const isVisibleBookExpirationDate =
            singleNotification?.isVisibleBookExpirationDate || false;
          const monthlyIvaBalance = singleNotification?.monthlyIvaBalance || 0;
          const isVisibleMonthlyInaBalance =
            singleNotification?.isVisibleMonthlyInaBalance || false;
          const annualIueBalance = singleNotification?.annualIueBalance || 0;
          const isVisibleAnnualIueBalance = singleNotification?.isVisibleAnnualIueBalance || false;
          const quarterlyRcIvaBalance = singleNotification?.quarterlyRcIvaBalance || 0;
          const isVisibleQuarterlyRcIvaBalance =
            singleNotification?.isVisibleQuarterlyRcIvaBalance || false;
          const accountantServiceBalance = singleNotification?.accountantServiceBalance || 0;
          const isVisibleAccountantServiceBalance =
            singleNotification?.isVisibleAccountantServiceBalance || false;
          const monthlyDue = singleNotification?.monthlyDue || '';
          const monthlyDueDate = singleNotification
            ? moment(singleNotification.monthlyDueDate.slice(0, 10)).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY');
          const isVisibleMonthlyDueDate = singleNotification?.isVisibleMonthlyDueDate || false;
          const quarterlyDue = singleNotification?.quarterlyDue || '';
          const quarterlyDueDate = singleNotification
            ? moment(singleNotification.quarterlyDueDate.slice(0, 10)).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY');
          const isVisibleQuarterlyDueDate = singleNotification?.isVisibleQuarterlyDueDate || false;
          const annualBalanceDue = singleNotification?.annualBalanceDue || '';
          const annualBalanceDueDate = singleNotification
            ? moment(singleNotification.annualBalanceDueDate.slice(0, 10)).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY');
          const isVisibleAnnualBalanceDueDate =
            singleNotification?.isVisibleAnnualBalanceDueDate || false;
          const seprecDue = singleNotification?.seprecDue || '';
          const seprecDueDate = singleNotification
            ? moment(singleNotification.seprecDueDate.slice(0, 10)).format('DD/MM/YYYY')
            : moment().format('DD/MM/YYYY');
          const isVisibleSeprecDueDate = singleNotification?.isVisibleSeprecDueDate || false;

          const otherFields =
            singleNotification?.otherDues.map((o) => ({
              title: o.description || '',
              value:
                moment(o.dueDate.slice(0, 10)).format('DD/MM/YYYY') ||
                moment().format('DD/MM/YYYY'),
              isVisible: o.isVisible || false
            })) || [];

          // Generate table
          return {
            controlNumber: item.controlNumber || '',
            rows: [
              {
                title: 'Vencimiento Talonario',
                value: bookExpirationDate,
                isVisible: isVisibleBookExpirationDate
              },
              {
                title: 'Saldo IVA - Mensual',
                value: monthlyIvaBalance,
                isVisible: isVisibleMonthlyInaBalance
              },
              {
                title: 'Saldo I.U.E. - Anual',
                value: annualIueBalance,
                isVisible: isVisibleAnnualIueBalance
              },
              {
                title: 'Saldo RC-IVA Trimestral',
                value: quarterlyRcIvaBalance,
                isVisible: isVisibleQuarterlyRcIvaBalance
              },
              {
                title: 'Saldo a Pagar Servicios Contador',
                value: accountantServiceBalance,
                isVisible: isVisibleAccountantServiceBalance
              },
              {
                title: 'Vencimiento - Mensual',
                value: `${monthlyDue}  -  ${monthlyDueDate}`,
                isVisible: isVisibleMonthlyDueDate
              },
              {
                title: 'Vencimiento - Trimestral',
                value: `${quarterlyDue}  -  ${quarterlyDueDate}`,
                isVisible: isVisibleQuarterlyDueDate
              },
              {
                title: 'Vencimiento - Balance Anual',
                value: `${annualBalanceDue}  -  ${annualBalanceDueDate}`,
                isVisible: isVisibleAnnualBalanceDueDate
              },
              {
                title: 'Vencimiento - SEPREC',
                value: `${seprecDue}  -  ${seprecDueDate}`,
                isVisible: isVisibleSeprecDueDate
              },
              ...otherFields
            ],
            isVisibleNotification: singleNotification?.isVisibleNotification || false
          };
        }) || [];

      // Filter isVisible in rows
      data = data.filter((item) => {
        item.rows = item.rows.filter((item2) => item2.isVisible);
        return item.rows.length > 0;
      });

      // Filter notification form active
      data = data.filter((item) => item.isVisibleNotification);

      setNotifications(data);
    }
    // eslint-disable-next-line
  }, [loading, notificationsData]);

  const handleBtnWhatsApp = () => {
    const url = `https://api.whatsapp.com/send?phone=${process.env.REACT_APP_NUMBER_ACCOUNT_ADVISOR}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const verifyExistNotifications = () => {
    let exist = false;
    notifications.forEach((notification) => {
      notification.rows.forEach((item) => {
        if (item.isVisible) {
          exist = true;
        }
      });
    });
    return exist;
  };

  const onReload = () => {
    getBalanceNotificationsClient();
  };

  return (
    <Page title="Notificaciones | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Notificaciones de Saldo
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mt={3}
              display="flex"
              width="100%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
            >
              <Button
                disabled={loading}
                variant="outlined"
                to="#"
                color="secondary"
                onClick={() => onReload()}
                startIcon={<Iconify icon="eva:refresh-fill" />}
              >
                Recargar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Card>
          <CardContent>
            {/* Loading */}
            {loading ? (
              <CircularLoading height={100} />
            ) : (
              // Content
              // Title
              <Grid container spacing={3}>
                {verifyExistNotifications() && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Typography variant="body1">Estimado cliente,</Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Typography variant="body1">
                        Le proporcionamos la siguiente información sobre su cuenta:
                      </Typography>
                    </Grid>
                  </>
                )}

                {/* Notifications  */}
                {notifications.map((notification, i) => (
                  <React.Fragment key={i}>
                    {notification.isVisibleNotification && (
                      <Grid item md={12} xs={12} marginTop={i > 0 ? 2 : 0}>
                        <FormHelperText
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 16
                          }}
                        >
                          {`Kardex Nº Control ${notification.controlNumber}`}
                        </FormHelperText>
                      </Grid>
                    )}

                    {/* Table */}
                    <GridStyle item md={12} xs={12} justifyContent="center" mb={3}>
                      <TableStyled>
                        <TableBody>
                          {notification.isVisibleNotification &&
                            notification.rows.map((item, j) => (
                              <StyledTableRow key={j}>
                                <TableCellLabelStyled component="th" scope="row" align="left">
                                  {item.title}
                                </TableCellLabelStyled>
                                <TableCellValueStyled component="th" scope="row" align="center">
                                  {item.value}
                                </TableCellValueStyled>
                              </StyledTableRow>
                            ))}
                        </TableBody>
                      </TableStyled>
                    </GridStyle>
                  </React.Fragment>
                ))}

                {!verifyExistNotifications() && (
                  <Grid item xs={12} sx={{ textAlign: 'center', mt: 3, mb: 3 }}>
                    <IconifyStyle icon="eva:smiling-face-outline" />
                    <Typography>Enorabuena, no tiene saldos pendientes en su cuenta</Typography>
                  </Grid>
                )}
              </Grid>
            )}

            {/* Button WhatsApp */}
            <Grid container spacing={3} mb={3}>
              {verifyExistNotifications() && (
                <Grid item md={12} xs={12}>
                  {/* prettier-ignore */}
                  <Alert severity="info">
                    Por favor, tenga en cuenta los saldos pendientes de su cuenta. Si necesita más información, no dude en contactarse con el contador pulsando el botón de abajo.
                  </Alert>
                </Grid>
              )}
              <Grid item md={12} xs={12} display="flex" justifyContent="center">
                <Button
                  size="large"
                  variant="contained"
                  type="button"
                  color="success"
                  onClick={handleBtnWhatsApp}
                  endIcon={<WhatsAppIcon />}
                >
                  Contactarse con Contador
                </Button>
              </Grid>
            </Grid>
            {errors?.error && (
              <Grid item md={12} xs={12} lg={12}>
                <FormHelperText error>{errors.error}</FormHelperText>
              </Grid>
            )}
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
