import React, { useState } from 'react';
import { TextField, Box } from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';

function SearchDate({ search, setSearch }) {
  const [displayDate, setDisplayDate] = useState(
    search ? moment(search, 'YYYY-MM-DD').format('YYYY-MM-DD') : ''
  );

  const handleChange = (e) => {
    const date = e.target.value;
    const formattedDate = moment(date).format('YYYY-MM-DD');
    setDisplayDate(date);
    setSearch(formattedDate);
  };

  return (
    <Box width="50%">
      <TextField
        size="small"
        fullWidth
        type="date"
        className="form-control"
        value={displayDate}
        onChange={handleChange}
        InputLabelProps={{
          shrink: true
        }}
      />
    </Box>
  );
}

SearchDate.defaultProps = {
  search: ''
};

SearchDate.propTypes = {
  search: PropTypes.string,
  setSearch: PropTypes.func.isRequired
};

export default SearchDate;
