import {
  GET_HEALTH_INSURANCES,
  GET_HEALTH_INSURANCE,
  SET_LOADING_HEALTH_INSURANCE,
  HEALTH_INSURANCE_ERROR,
  CLEAR_HEALTH_INSURANCE
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_HEALTH_INSURANCES:
      return {
        ...state,
        healthInsurances: action.payload,
        errors: null,
        loading: false
      };
    case GET_HEALTH_INSURANCE:
      return {
        ...state,
        errors: null,
        healthInsurance: action.payload,
        loading: false
      };
    case SET_LOADING_HEALTH_INSURANCE:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case HEALTH_INSURANCE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_HEALTH_INSURANCE:
      return {
        ...state,
        healthInsurances: null,
        healthInsurance: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
