import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Typography,
  Switch
} from '@mui/material';
import PropTypes from 'prop-types';
import moment from 'moment';

// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message:
    'La notificación será visible al cliente con los campos asignados. ¿Esta seguro de Actualizar Saldos?'
};

const previousScreen = '/dashboard/controls/kardex';

function FieldVisibility({ onHandleFieldVisibility, name, state }) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <Switch
        checked={state}
        onChange={() => onHandleFieldVisibility(name, !state)}
        color="primary"
      />
      <Typography variant="body1" color={state ? '#229A16' : '#B72136'}>
        {state ? 'Activado' : 'Desactivado'}
      </Typography>
    </Box>
  );
}

FieldVisibility.propTypes = {
  onHandleFieldVisibility: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  state: PropTypes.bool.isRequired
};

export default function UpdateBalanceNotification() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const {
    loading,
    errors,
    clearData,
    updateBalanceNotification,
    getBalanceNotification,
    notification: notificationData
  } = controlContext;
  const [notification, setNotification] = useState({
    bookExpirationDate: moment().format('YYYY-MM-DD'),
    isVisibleBookExpirationDate: true,
    monthlyIvaBalance: 0,
    isVisibleMonthlyInaBalance: true,
    annualIueBalance: 0,
    isVisibleAnnualIueBalance: true,
    quarterlyRcIvaBalance: 0,
    isVisibleQuarterlyRcIvaBalance: true,
    accountantServiceBalance: 0,
    isVisibleAccountantServiceBalance: true,
    monthlyDue: '',
    monthlyDueDate: moment().format('YYYY-MM-DD'),
    isVisibleMonthlyDueDate: true,
    quarterlyDue: '',
    quarterlyDueDate: moment().format('YYYY-MM-DD'),
    isVisibleQuarterlyDueDate: true,
    annualBalanceDue: '',
    annualBalanceDueDate: moment().format('YYYY-MM-DD'),
    isVisibleAnnualBalanceDueDate: true,
    seprecDue: '',
    seprecDueDate: moment().format('YYYY-MM-DD'),
    isVisibleSeprecDueDate: true,
    otherDues: [],
    isVisibleNotification: true,
    createdAt: '',
    updatedAt: '',
    user: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const controlId = state?.id;
  const notificationId = state?.notificationId;

  // Fetch data
  useEffect(() => {
    if (controlId && notificationId) {
      getBalanceNotification(controlId, notificationId);
    } else {
      navigate(previousScreen, {
        state: {
          id: controlId
        }
      });
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [controlId, previousScreen]);

  // Load notification data
  useEffect(() => {
    if (!loading && notificationData && !errors) {
      // Check if notificationData is not an empty object
      if (Object.keys(notificationData).length === 0 && notificationData.constructor === Object) {
        // Handle the case where notificationData is an empty object
        setNotification({
          bookExpirationDate: moment().format('YYYY-MM-DD'),
          isVisibleBookExpirationDate: false,
          monthlyIvaBalance: 0,
          isVisibleMonthlyInaBalance: false,
          annualIueBalance: 0,
          isVisibleAnnualIueBalance: false,
          quarterlyRcIvaBalance: 0,
          isVisibleQuarterlyRcIvaBalance: false,
          accountantServiceBalance: 0,
          isVisibleAccountantServiceBalance: false,
          monthlyDue: '',
          monthlyDueDate: moment().format('YYYY-MM-DD'),
          isVisibleMonthlyDueDate: false,
          quarterlyDue: '',
          quarterlyDueDate: moment().format('YYYY-MM-DD'),
          isVisibleQuarterlyDueDate: false,
          annualBalanceDue: '',
          annualBalanceDueDate: moment().format('YYYY-MM-DD'),
          isVisibleAnnualBalanceDueDate: false,
          seprecDue: '',
          seprecDueDate: moment().format('YYYY-MM-DD'),
          isVisibleSeprecDueDate: false,
          otherDues: [],
          isVisibleNotification: false,
          createdAt: '',
          updatedAt: '',
          user: ''
        });
      } else {
        // Handle the case where notificationData is not an empty object
        setNotification({
          bookExpirationDate:
            moment(notificationData.bookExpirationDate.slice(0, 10)).format('YYYY-MM-DD') ||
            moment().format('YYYY-MM-DD'),
          isVisibleBookExpirationDate: notificationData.isVisibleBookExpirationDate || false,
          monthlyIvaBalance: notificationData.monthlyIvaBalance || 0,
          isVisibleMonthlyInaBalance: notificationData.isVisibleMonthlyInaBalance || false,
          annualIueBalance: notificationData.annualIueBalance || 0,
          isVisibleAnnualIueBalance: notificationData.isVisibleAnnualIueBalance || false,
          quarterlyRcIvaBalance: notificationData.quarterlyRcIvaBalance || 0,
          isVisibleQuarterlyRcIvaBalance: notificationData.isVisibleQuarterlyRcIvaBalance || false,
          accountantServiceBalance: notificationData.accountantServiceBalance || 0,
          isVisibleAccountantServiceBalance:
            notificationData.isVisibleAccountantServiceBalance || false,
          monthlyDue: notificationData.monthlyDue || '',
          monthlyDueDate:
            moment(notificationData.monthlyDueDate.slice(0, 10)).format('YYYY-MM-DD') ||
            moment().format('YYYY-MM-DD'),
          isVisibleMonthlyDueDate: notificationData.isVisibleMonthlyDueDate || false,
          quarterlyDue: notificationData.quarterlyDue || '',
          quarterlyDueDate:
            moment(notificationData.quarterlyDueDate.slice(0, 10)).format('YYYY-MM-DD') ||
            moment().format('YYYY-MM-DD'),
          isVisibleQuarterlyDueDate: notificationData.isVisibleQuarterlyDueDate || false,
          annualBalanceDue: notificationData.annualBalanceDue || '',
          annualBalanceDueDate:
            moment(notificationData.annualBalanceDueDate.slice(0, 10)).format('YYYY-MM-DD') ||
            moment().format('YYYY-MM-DD'),
          isVisibleAnnualBalanceDueDate: notificationData.isVisibleAnnualBalanceDueDate || false,
          seprecDue: notificationData.seprecDue || '',
          seprecDueDate:
            moment(notificationData.seprecDueDate.slice(0, 10)).format('YYYY-MM-DD') ||
            moment().format('YYYY-MM-DD'),
          isVisibleSeprecDueDate: notificationData.isVisibleSeprecDueDate || false,
          otherDues:
            notificationData.otherDues.map((item) => ({
              description: item.description || '',
              dueDate:
                moment(item.dueDate.slice(0, 10)).format('YYYY-MM-DD') ||
                moment().format('YYYY-MM-DD'),
              isVisible: item.isVisible || false
            })) || [],
          isVisibleNotification: notificationData.isVisibleNotification || false,
          createdAt: moment(notificationData.createdAt).format('DD/MM/YYYY [a las] HH:mm a') || '',
          updatedAt: moment(notificationData.updatedAt).format('DD/MM/YYYY [a las] HH:mm a') || '',
          user: notificationData?.user?.name || ''
        });
      }
    }
    // eslint-disable-next-line
  }, [loading, notificationData, errors]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    return setNotification({
      ...notification,
      [name]: value
    });
  };

  const onHandleVisibilityForm = (state) => {
    setNotification({
      ...notification,
      isVisibleNotification: state
    });
  };

  const onHandleFieldVisibility = (name, state) => {
    setNotification({
      ...notification,
      [name]: state
    });
  };

  const handleChangeNumber = (e) => {
    const { name, value } = e.target;

    if (value === '') {
      // if initial value
      setNotification({
        ...notification,
        [name]: ''
      });
      return;
    }

    // Validate input number and decimals
    if (!Number.isNaN(parseFloat(Number(value))) && Number.isFinite(Number(value))) {
      setNotification({
        ...notification,
        [name]: value
      });
    }
  };

  const onAddOtherField = () => {
    setNotification({
      ...notification,
      otherDues: [
        ...notification.otherDues,
        {
          description: '',
          dueDate: moment().format('YYYY-MM-DD'),
          isVisible: true
        }
      ]
    });
  };

  const handleChangeOtherField = (e, i) => {
    setNotification({
      ...notification,
      otherDues: notification.otherDues.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        }
        return item;
      })
    });
  };

  const onHandleOtherFieldVisibility = (i, name, state) => {
    setNotification({
      ...notification,
      otherDues: notification.otherDues.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [name]: state
          };
        }
        return item;
      })
    });
  };

  const onHandleRemoveOtherField = (i) => {
    setNotification({
      ...notification,
      otherDues: notification.otherDues.filter((item, index) => index !== i)
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      bookExpirationDate: notification.bookExpirationDate,
      isVisibleBookExpirationDate: notification.isVisibleBookExpirationDate,
      monthlyIvaBalance: notification.monthlyIvaBalance,
      isVisibleMonthlyInaBalance: notification.isVisibleMonthlyInaBalance,
      annualIueBalance: notification.annualIueBalance,
      isVisibleAnnualIueBalance: notification.isVisibleAnnualIueBalance,
      quarterlyRcIvaBalance: notification.quarterlyRcIvaBalance,
      isVisibleQuarterlyRcIvaBalance: notification.isVisibleQuarterlyRcIvaBalance,
      accountantServiceBalance: notification.accountantServiceBalance,
      isVisibleAccountantServiceBalance: notification.isVisibleAccountantServiceBalance,
      monthlyDue: notification.monthlyDue,
      monthlyDueDate: notification.monthlyDueDate,
      isVisibleMonthlyDueDate: notification.isVisibleMonthlyDueDate,
      quarterlyDue: notification.quarterlyDue,
      quarterlyDueDate: notification.quarterlyDueDate,
      isVisibleQuarterlyDueDate: notification.isVisibleQuarterlyDueDate,
      annualBalanceDue: notification.annualBalanceDue,
      annualBalanceDueDate: notification.annualBalanceDueDate,
      isVisibleAnnualBalanceDueDate: notification.isVisibleAnnualBalanceDueDate,
      seprecDue: notification.seprecDue,
      seprecDueDate: notification.seprecDueDate,
      isVisibleSeprecDueDate: notification.isVisibleSeprecDueDate,
      otherDues: notification.otherDues,
      isVisibleNotification: notification.isVisibleNotification
    };

    setOpenDialog(false);
    if ((await updateBalanceNotification(controlId, notificationId, formData)) === true) {
      navigate(previousScreen, {
        state: { id: controlId }
      });
    }
  };

  return (
    <Page title="Notificaciones | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: controlId }} />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar saldos del kardex"
            title="Actualizar Saldos"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid
                container
                spacing={3}
                sx={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <Grid item md={8} xs={12}>
                  <TextField
                    error={Boolean(errors?.bookExpirationDate)}
                    helperText={errors?.bookExpirationDate}
                    fullWidth
                    label="Vencimiento Talonario"
                    name="bookExpirationDate"
                    onChange={handleChange}
                    required
                    value={notification.bookExpirationDate}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleBookExpirationDate"
                    state={notification.isVisibleBookExpirationDate}
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    error={Boolean(errors?.monthlyIvaBalance)}
                    helperText={errors?.monthlyIvaBalance}
                    fullWidth
                    label="Saldo IVA - Mensual (Bs.)"
                    name="monthlyIvaBalance"
                    onChange={handleChangeNumber}
                    required
                    value={notification.monthlyIvaBalance}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleMonthlyInaBalance"
                    state={notification.isVisibleMonthlyInaBalance}
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    error={Boolean(errors?.annualIueBalance)}
                    helperText={errors?.annualIueBalance}
                    fullWidth
                    label="Saldo I.U.E. - Anual (Bs.)"
                    name="annualIueBalance"
                    onChange={handleChangeNumber}
                    required
                    value={notification.annualIueBalance}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleAnnualIueBalance"
                    state={notification.isVisibleAnnualIueBalance}
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    error={Boolean(errors?.quarterlyRcIvaBalance)}
                    helperText={errors?.quarterlyRcIvaBalance}
                    fullWidth
                    label="Saldo RC-IVA Trimestral (Bs.)"
                    name="quarterlyRcIvaBalance"
                    onChange={handleChangeNumber}
                    required
                    value={notification.quarterlyRcIvaBalance}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleQuarterlyRcIvaBalance"
                    state={notification.isVisibleQuarterlyRcIvaBalance}
                  />
                </Grid>

                <Grid item md={8} xs={12}>
                  <TextField
                    error={Boolean(errors?.accountantServiceBalance)}
                    helperText={errors?.accountantServiceBalance}
                    fullWidth
                    label="Saldo a Pagar Servicios Contador (Bs.)"
                    name="accountantServiceBalance"
                    onChange={handleChangeNumber}
                    required
                    value={notification.accountantServiceBalance}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleAccountantServiceBalance"
                    state={notification.isVisibleAccountantServiceBalance}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.monthlyDue)}
                    helperText={errors?.monthlyDue}
                    fullWidth
                    label="Vencimiento - Mensual"
                    name="monthlyDue"
                    onChange={handleChange}
                    required
                    value={notification.monthlyDue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.monthlyDueDate)}
                    helperText={errors?.monthlyDueDate}
                    fullWidth
                    label="Fecha"
                    name="monthlyDueDate"
                    onChange={handleChange}
                    required
                    value={notification.monthlyDueDate}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleMonthlyDueDate"
                    state={notification.isVisibleMonthlyDueDate}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.quarterlyDue)}
                    helperText={errors?.quarterlyDue}
                    fullWidth
                    label="Vencimiento - Trimestral"
                    name="quarterlyDue"
                    onChange={handleChange}
                    required
                    value={notification.quarterlyDue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.quarterlyDueDate)}
                    helperText={errors?.quarterlyDueDate}
                    fullWidth
                    label="Fecha"
                    name="quarterlyDueDate"
                    onChange={handleChange}
                    required
                    value={notification.quarterlyDueDate}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleQuarterlyDueDate"
                    state={notification.isVisibleQuarterlyDueDate}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.annualBalanceDue)}
                    helperText={errors?.annualBalanceDue}
                    fullWidth
                    label="Vencimiento - Balance Anual"
                    name="annualBalanceDue"
                    onChange={handleChange}
                    required
                    value={notification.annualBalanceDue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.annualBalanceDueDate)}
                    helperText={errors?.annualBalanceDueDate}
                    fullWidth
                    label="Fecha"
                    name="annualBalanceDueDate"
                    onChange={handleChange}
                    required
                    value={notification.annualBalanceDueDate}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleAnnualBalanceDueDate"
                    state={notification.isVisibleAnnualBalanceDueDate}
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.seprecDue)}
                    helperText={errors?.seprecDue}
                    fullWidth
                    label="Vencimiento - SEPREC"
                    name="seprecDue"
                    onChange={handleChange}
                    required
                    value={notification.seprecDue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    error={Boolean(errors?.seprecDueDate)}
                    helperText={errors?.seprecDueDate}
                    fullWidth
                    label="Fecha"
                    name="seprecDueDate"
                    onChange={handleChange}
                    required
                    value={notification.seprecDueDate}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <FieldVisibility
                    onHandleFieldVisibility={onHandleFieldVisibility}
                    name="isVisibleSeprecDueDate"
                    state={notification.isVisibleSeprecDueDate}
                  />
                </Grid>

                {/* Others fields */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Otros
                  </FormHelperText>
                </Grid>
                <Grid item md={12} xs={12} sx={{ marginBottom: 4 }}>
                  {notification.otherDues.map((item, i) => {
                    const errorPeriod = (errors?.otherDues && errors.otherDues[i]) || null;

                    return (
                      <Grid
                        key={i}
                        container
                        spacing={3}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          marginBottom: 3
                        }}
                      >
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(errorPeriod?.description)}
                            helperText={errorPeriod?.description}
                            fullWidth
                            label="Concepto"
                            name="description"
                            onChange={(e) => handleChangeOtherField(e, i)}
                            required
                            value={item.description}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(errorPeriod?.dueDate)}
                            helperText={errorPeriod?.dueDate}
                            fullWidth
                            label="Fecha"
                            name="dueDate"
                            onChange={(e) => handleChangeOtherField(e, i)}
                            required
                            value={item.dueDate}
                            variant="outlined"
                            type="date"
                          />
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Switch
                            checked={item.isVisible}
                            onChange={() =>
                              onHandleOtherFieldVisibility(i, 'isVisible', !item.isVisible)
                            }
                            color="primary"
                          />
                          <Typography
                            variant="body2"
                            color={item.isVisible ? '#229A16' : '#B72136'}
                          >
                            {item.isVisible ? 'Mostrado' : 'No mostrado'}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => onHandleRemoveOtherField(i)}
                            endIcon={<Iconify icon="eva:trash-2-outline" />}
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container marginTop={2} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAddOtherField}
                      endIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      Añadir Otro Campo
                    </Button>
                  </Grid>
                  {errors?.errorOtherDueDates && (
                    <Grid item md={12} xs={12} lg={12}>
                      <FormHelperText error>{errors.errorOtherDueDates}</FormHelperText>
                    </Grid>
                  )}
                </Grid>

                {/* General info */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    General
                  </FormHelperText>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box
                    gap={3}
                    display="flex"
                    alignItems="center"
                    flexDirection={{
                      xs: 'column',
                      md: 'row'
                    }}
                  >
                    <Typography variant="body1" fontWeight="bold">
                      Visibilidad del Formulario:
                    </Typography>
                    <Box gap={1} display="flex" alignItems="center">
                      <Switch
                        checked={notification.isVisibleNotification}
                        onChange={() => onHandleVisibilityForm(!notification.isVisibleNotification)}
                        color="primary"
                      />
                      <Typography
                        variant="body1"
                        color={notification.isVisibleNotification ? '#229A16' : '#B72136'}
                      >
                        {notification.isVisibleNotification
                          ? 'La notificación de saldos será mostrado al cliente'
                          : 'La notificación de saldos no será mostrado al cliente'}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                {/* Register details */}
                {notification.createdAt && (
                  <Grid item md={12} xs={12}>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      Registro
                    </FormHelperText>
                  </Grid>
                )}
                {notification.updatedAt && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Fecha de Modificación"
                      name="updatedAt"
                      onChange={null}
                      value={notification.updatedAt}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {notification.user && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Registrado por"
                      name="user"
                      onChange={null}
                      value={notification.user}
                      variant="outlined"
                    />
                  </Grid>
                )}
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() =>
                  navigate(previousScreen, {
                    state: {
                      id: controlId
                    }
                  })
                }
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
