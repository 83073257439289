import {
  GET_VOUCHERS,
  GET_VOUCHER,
  SET_LOADING_VOUCHER,
  VOUCHER_CREATE_SUCCESS,
  VOUCHER_UPDATE_SUCCESS,
  VOUCHER_DELETE_SUCCESS,
  VOUCHER_ERROR,
  CLEAR_DATA_VOUCHER
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_VOUCHERS:
      return {
        ...state,
        vouchers: action.payload,
        errors: null,
        loading: false
      };
    case GET_VOUCHER:
      return {
        ...state,
        voucher: action.payload,
        loading: false,
        errors: null
      };
    case SET_LOADING_VOUCHER:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case VOUCHER_CREATE_SUCCESS:
    case VOUCHER_UPDATE_SUCCESS:
    case VOUCHER_DELETE_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case VOUCHER_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_DATA_VOUCHER:
      return {
        ...state,
        vouchers: null,
        voucher: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
