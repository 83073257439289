import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import HealthInsuranceContext from '../../context/healthInsurance/healthInsuranceContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

export default function HealthInsuranceDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const healthInsuranceContext = useContext(HealthInsuranceContext);
  const {
    loading,
    clearData,
    errors,
    healthInsurance: healthInsuranceData,
    getHealthInsurance
  } = healthInsuranceContext;
  const [healthInsurance, setHealthInsurance] = useState({
    name: '',
    address: '',
    createdAt: '',
    user: ''
  });
  // Id received by previous screen
  const healthInsuranceId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (healthInsuranceId) {
      getHealthInsurance(healthInsuranceId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [healthInsuranceId]);

  useEffect(() => {
    if (!loading && healthInsuranceData) {
      setHealthInsurance({
        name: healthInsuranceData.name || '',
        address: healthInsuranceData.address || '',
        createdAt: healthInsuranceData?.createdAt
          ? moment(healthInsuranceData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: healthInsuranceData?.user?.name || ''
      });
    }
  }, [loading, healthInsuranceData]);

  return (
    <Page title="Seguros de Salud | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/configuration/health-insurances" />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del Seguro de Salud"
            title="Detalles del Seguro de Salud"
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={null}
                  required
                  value={healthInsurance.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Dirección"
                  name="address"
                  onChange={null}
                  required
                  value={healthInsurance.address}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={healthInsurance.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  required
                  value={healthInsurance.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => navigate('/dashboard/configuration/health-insurances')}
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
