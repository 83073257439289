import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// components
import Scrollbar from '../../components/Scrollbar';
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CircularLoading from '../../components/CircularLoading';

const previousScreen = '/dashboard/client/controls/listing';

const GridStyle = styled(Grid)({
  display: 'inline-flex',
  gap: 10
});

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f2f2'
  }
}));

const TypographyStyle = styled(Typography)({
  display: 'inline-block',
  fontWeight: 'bold'
});

const TypographyTextStyle = styled(Typography)({
  display: 'inline-block'
});

const styles = {
  textTitleTable: {
    border: '1px solid #ddd',
    padding: '5px',
    fontWeight: 'bold'
  },
  textTable: {
    border: '1px solid #ddd',
    padding: '5px'
  }
};

const IconifyStyle = styled(Iconify)(() => ({
  marginBottom: -5,
  height: 30,
  width: 30,
  marginRight: 5
}));

export default function PaymentListing() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const controlContext = useContext(ControlContext);
  const {
    loading,
    clearData: clearDataControl,
    errors,
    control: controlData,
    getControl
  } = controlContext;
  const [dataTable, setDataTable] = useState([]);
  const [control, setControl] = useState({
    controlNumber: '',
    client: {
      name: '',
      nit: '',
      owner: '',
      phoneNumber: '',
      phone: '',
      mainActivity: '',
      secondaryActivity: ''
    },
    phoneNumber: '',
    observations: '',
    typeOfTaxPayer: '',
    dueTaxDate: '',
    billingModality: '',
    service: {
      concept: ''
    },
    isCompleted: false,
    price: 0,
    discount: 0,
    accountAmount: 0,
    balanceAmount: 0,
    years: [],
    user: ''
  });
  // If received by previous screen
  const controlId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (controlId) {
      getControl(controlId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearDataControl();
    // eslint-disable-next-line
  }, [controlId, previousScreen]);

  // Load control data
  useEffect(() => {
    if (!loading && controlData) {
      setControl({
        controlNumber: controlData.controlNumber || '',
        client: {
          name: controlData.client?.name || '-',
          owner: controlData.client?.owner || '-',
          nit: controlData.client?.nit || '-',
          phoneNumber: controlData.client?.phoneNumber || '-',
          phone: controlData.client?.phone || '',
          mainActivity: controlData.client?.mainActivity || '-',
          secondaryActivity: controlData.client?.secondaryActivity || '-'
        },
        typeOfTaxPayer: controlData.typeOfTaxPayer || '',
        dueTaxDate: controlData.dueTaxDate || '',
        billingModality: controlData.billingModality || '',
        observations: controlData.observations || '',
        service: {
          concept: controlData.service?.concept || '-'
        },
        price: controlData.price || controlData.price === 0 ? controlData.price.toFixed(2) : 0,
        discount:
          controlData.discount || controlData.discount === 0 ? controlData.discount.toFixed(2) : 0,
        accountAmount:
          controlData.accountAmount || controlData.accountAmount === 0
            ? controlData.accountAmount.toFixed(2)
            : 0,
        balanceAmount:
          controlData.balanceAmount || controlData.balanceAmount === 0
            ? controlData.balanceAmount.toFixed(2)
            : 0,
        isCompleted: controlData.isCompleted,
        years: controlData.years || [],
        user: controlData?.user?.name || '-'
      });

      // Get payments
      const itemsTable =
        controlData.payments?.map((item) => ({
          id: item._id,
          date: moment(item.createdAt).format('DD/MM/YYYY'),
          paymentNumber: item.paymentNumber,
          concept: item.concept,
          accountAmount: item.accountAmount.toFixed(2),
          balanceAmount: item.balanceAmount.toFixed(2),
          type: item.type,
          observations: item.observations || '-'
        })) || [];
      setDataTable(itemsTable);
    }
  }, [loading, controlData]);

  const onReload = async () => {
    getControl(controlId);
  };

  return (
    <Page title="Kardex Financiero | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Button
                sx={{
                  marginBottom: 2
                }}
                variant="outlined"
                onClick={() => navigate(previousScreen)}
                startIcon={<Iconify icon="eva:arrow-circle-left-outline" />}
              >
                Regresar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              mt={3}
              display="flex"
              width="100%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
            >
              <Button
                disabled={loading}
                variant="outlined"
                to="#"
                color="secondary"
                onClick={() => onReload()}
                startIcon={<Iconify icon="eva:refresh-fill" />}
              >
                Recargar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Card>
          {/* Content */}
          <CardHeader
            title="Tarjeta de Control de Pagos"
            subheader={`Nº Control ${control.controlNumber}`}
            sx={{
              mb: 1,
              textAlign: 'center'
            }}
          />
          <Divider />
          <CardContent>
            {/* Loading */}
            {loading && <CircularLoading height={100} />}

            <Grid container spacing={3}>
              {control.client.name !== '-' && (
                <GridStyle item md={12} xs={12}>
                  <TypographyStyle>Nombre o Razón Social de la Empresa:</TypographyStyle>
                  <Typography style={{ display: 'inline-block' }}>{control.client.name}</Typography>
                </GridStyle>
              )}
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Responsable Legal y/o Propietario:</TypographyStyle>
                <TypographyTextStyle>{control.client.owner}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>NIT:</TypographyStyle>
                <TypographyTextStyle>{control.client.nit}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Tipo de Contribuyente:</TypographyStyle>
                <TypographyTextStyle>{control.typeOfTaxPayer}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Fecha de cierre de E.E.F.F.:</TypographyStyle>
                <TypographyTextStyle>{control.dueTaxDate}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Teléfonos de Contacto:</TypographyStyle>
                <WhatsAppIcon />
                <a
                  href={`https://api.whatsapp.com/send?phone=${control.client.phoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {control.client.phoneNumber}
                </a>
                {' - '}
                {control.client.phone && (
                  <a
                    href={`https://api.whatsapp.com/send?phone=${control.client.phone}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {control.client.phone}
                  </a>
                )}
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Modalidad de Facturación:</TypographyStyle>
                <TypographyTextStyle>{control.billingModality}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Gestiones:</TypographyStyle>
                <TypographyTextStyle>
                  {control.years.map((item) => item.year).join(' - ')}
                </TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Actividad Comercial Principal:</TypographyStyle>
                <TypographyTextStyle>{control.client.mainActivity}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Actividad Comercial Secundaria:</TypographyStyle>
                <TypographyTextStyle>{control.client.secondaryActivity}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Servicio:</TypographyStyle>
                <TypographyTextStyle>{control.service.concept}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Precio Total:</TypographyStyle>
                <TypographyTextStyle>{control.price} Bs.</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Descuento:</TypographyStyle>
                <TypographyTextStyle>{control.discount} Bs.</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>A Cuenta:</TypographyStyle>
                <TypographyTextStyle>{control.accountAmount} Bs.</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Saldo Actualizado:</TypographyStyle>
                <TypographyTextStyle>{control.balanceAmount} Bs.</TypographyTextStyle>
              </GridStyle>
            </Grid>
            {errors?.error && (
              <Grid item md={12} xs={12} lg={12}>
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.error}</FormHelperText>
                </Box>
              </Grid>
            )}
            {/* Payments */}
            <Scrollbar>
              <Table
                sx={{ width: '100%', marginTop: 3, marginBottom: 3 }}
                aria-label="simple table"
              >
                <TableHead>
                  <StyledTableRow>
                    <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                      Fecha de Pago
                    </TableCell>
                    <TableCell colSpan={3} align="center" style={styles.textTitleTable}>
                      Pagos Parciales por Servicios - Profesionales
                    </TableCell>
                    <TableCell colSpan={1} align="center" style={styles.textTitleTable}>
                      Costo - Servicios
                    </TableCell>
                    <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                      Observaciones
                    </TableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Nº Pago
                    </TableCell>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Concepto y/o Detalle de Pago
                    </TableCell>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Pago Parcial
                    </TableCell>
                    <TableCell align="center" style={styles.textTitleTable}>
                      Saldo
                    </TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {dataTable.map((item, i) => (
                    <StyledTableRow key={i}>
                      <TableCell component="th" scope="row" align="center" style={styles.textTable}>
                        {item.date}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        {item.paymentNumber}
                      </TableCell>
                      <TableCell align="left" style={styles.textTable}>
                        {item.concept}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        {item.accountAmount}
                      </TableCell>
                      <TableCell align="center" style={styles.textTable}>
                        {item.balanceAmount}
                      </TableCell>
                      <TableCell align="left" style={styles.textTable}>
                        {item.observations}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
              {dataTable.length === 0 && (
                <Grid item sx={{ p: 5, textAlign: 'center' }}>
                  <IconifyStyle icon="eva:inbox-outline" />
                  <Typography>Lo sentimos no existen pagos realizados</Typography>
                </Grid>
              )}
            </Scrollbar>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
