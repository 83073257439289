import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Alert,
  FormControlLabel,
  Checkbox
} from '@mui/material';
// components
import ConfigurationContext from '../../context/configuration/configurationContext';
import FileContext from '../../context/file/fileContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de actualizar la configuración?'
};

export default function QRConfiguration() {
  const navigate = useNavigate();
  const configurationContext = useContext(ConfigurationContext);
  const fileContext = useContext(FileContext);
  const {
    loadingQRConfiguration,
    clearData,
    createQRConfiguration,
    updateQRConfiguration,
    getQRConfiguration,
    qrConfiguration: qrConfigurationData,
    errors,
    getQRConfigurationId
  } = configurationContext;
  const { loading: loadingFile, getFiles, files, clearData: clearFileData } = fileContext;
  const [configuration, setConfiguration] = useState({
    file: '0',
    isInUse: false
  });
  const [fileOptionsList, setFileOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [configurationId, setConfigurationId] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    getFiles('Imagen');
    loadEnv();

    return () => {
      clearData();
      clearFileData();
    };
    // eslint-disable-next-line
  }, []);

  const loadEnv = async () => {
    const qrId = await getQRConfigurationId();
    setConfigurationId(await getQRConfigurationId());
    // Get qr configuration
    getQRConfiguration(qrId);
  };

  // Load files
  useEffect(() => {
    if (!loadingFile && files) {
      const itemsData = files.map((item) => ({
        _id: item._id,
        value: item._id,
        label: item.name
      }));
      setFileOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingFile, files]);

  useEffect(() => {
    if (!loadingQRConfiguration && qrConfigurationData && !errors) {
      setConfiguration({
        file: qrConfigurationData.file || '0',
        isInUse: qrConfigurationData.isInUse || false
      });
    }
  }, [loadingQRConfiguration, qrConfigurationData, errors]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setConfiguration({
      ...configuration,
      [name]: value
    });
  };

  const handleChangeCheckbox = (e) => {
    setConfiguration({
      ...configuration,
      [e.target.name]: e.target.checked
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      file: configuration.file !== '0' ? configuration.file : '',
      isInUse: configuration.isInUse
    };

    setOpenDialog(false);
    if (configurationId) {
      await updateQRConfiguration(configurationId, formData);
    } else {
      await createQRConfiguration(formData);
    }
  };

  return (
    <Page title="Configuración | G&G Asesor">
      <Container>
        <Card>
          <CardHeader
            subheader="Ingrese los datos actualizar la configuración de QR en los recibos de pagos"
            title="Configuración de QR"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.file)}
                    fullWidth
                    name="file"
                    onChange={handleChange}
                    required
                    select
                    label="Imagen"
                    SelectProps={{ native: true }}
                    value={configuration.file}
                    variant="outlined"
                  >
                    {fileOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="isInUse"
                        checked={configuration.isInUse}
                        onChange={handleChangeCheckbox}
                      />
                    }
                    label="Utilizar imagen por defecto"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  {/* prettier-ignore */}
                  <Alert severity="warning">
                    {`${configuration.isInUse
                      ? 'Ahora los recibos de pago se mostrará utilizando la imagen seleccionada.'
                      : 'No se mostrará la imagen seleccionada en los recibos de pago.'}`
                    }
                  </Alert>
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate('/')}
              >
                Regresar A Inicio
              </Button>
              <Button
                disabled={loadingQRConfiguration}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loadingQRConfiguration}
      />
    </Page>
  );
}
