import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Autocomplete
} from '@mui/material';
// components
import ControlContext from '../../context/control/controlContext';
import ClientContext from '../../context/client/clientContext';
import ServiceContext from '../../context/service/serviceContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';
import { yearOptionsList, typeOfTaxPayerOptionsList } from '../../constants';

const dialog = {
  message: '¿Estas seguro de crear una nuevo kardex financiero?'
};

const previousScreen = '/dashboard/controls/listing';

export default function CreateControl() {
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const clientContext = useContext(ClientContext);
  const serviceContext = useContext(ServiceContext);
  const {
    loading,
    clearData: clearDataControl,
    createControl,
    errors,
    setLoading
  } = controlContext;
  const { loading: loadingClient, getClients, clients, clearData: clearDataClient } = clientContext;
  const { loading: loadingService, getServices, services } = serviceContext;
  const [control, setControl] = useState({
    client: {
      value: '0',
      label: 'Seleccione una opción'
    },
    service: {
      value: '0',
      label: 'Seleccione una opción'
    },
    years: [
      {
        year: '0'
      }
    ],
    price: 0,
    dueTaxDate: '',
    billingModality: '',
    typeOfTaxPayer: '0',
    observations: ''
  });
  const [clientOptionsList, setClientOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [serviceOptionsList, setServiceOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    getClients({ type: 'all' });
    getServices();
    setLoading(false);

    return () => {
      clearDataControl();
      clearDataClient();
    };
    // eslint-disable-next-line
  }, []);

  // Load client
  useEffect(() => {
    if (!loadingClient && clients) {
      const itemsData = clients?.clients.map((item) => {
        const clientName = item.name || item.owner;
        return {
          _id: item._id,
          value: item._id,
          label: `${clientName} | ${item.nit}`
        };
      });
      setClientOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingClient, clients]);

  // Load services
  useEffect(() => {
    if (!loadingService && services) {
      const itemsData = services.map((item) => ({
        _id: item._id,
        value: item._id,
        label: item.concept
      }));
      setServiceOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingService, services]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    setControl({
      ...control,
      [name]: value
    });
  };

  const handleChangeYear = (e, i) => {
    setControl({
      ...control,
      years: control.years.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        }
        return item;
      })
    });
  };

  const handleChangePrice = (e) => {
    const { value, name } = e.target;

    // Validate input number and decimals
    if (!Number.isNaN(parseFloat(Number(value))) && Number.isFinite(Number(value))) {
      setControl({
        ...control,
        [name]: value
      });
    } else if (value === '') {
      // Reset default and price
      setControl({
        ...control,
        price: 0,
        [name]: ''
      });
    }
  };

  const handleChangeClient = async (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';

    setControl({
      ...control,
      client: { value, label }
    });
  };

  const handleChangeService = async (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';

    // Load price service
    const serviceFounded = services.find((s) => s._id === value);
    setControl({
      ...control,
      service: { value, label },
      price: serviceFounded?.price || 0
    });
  };

  const onAddYear = () => {
    setControl({
      ...control,
      years: [
        ...control.years,
        {
          year: '0'
        }
      ]
    });
  };

  const onHandleRemove = (i) => {
    setControl({
      ...control,
      years: control.years.filter((item, index) => index !== i)
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      client: control.client?.value !== '0' ? control.client.value : '',
      service: control.service?.value !== '0' ? control.service.value : '',
      years: control.years,
      price: control.price,
      dueTaxDate: control.dueTaxDate,
      billingModality: control.billingModality,
      typeOfTaxPayer: control.typeOfTaxPayer !== '0' ? control.typeOfTaxPayer : '',
      observations: control.observations
    };

    setOpenDialog(false);
    if ((await createControl(formData)) === true) {
      navigate(previousScreen);
    }
  };

  return (
    <Page title="Kardex Financiero | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          <CardHeader
            subheader="Ingrese los datos en el formulario para crear un nuevo kardex financiero"
            title="Crear Kardex Financiero"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="client"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeClient(e, data)}
                    value={control.client}
                    options={clientOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente | NIT"
                        variant="outlined"
                        error={Boolean(errors?.client)}
                        helperText={errors?.client}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12} marginTop={3}>
                  <Box>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      Gestiones
                    </FormHelperText>
                  </Box>
                </Grid>
                {/* Years */}
                <Grid container spacing={3} sx={{ marginTop: 1 }}>
                  <Grid item md={12} xs={12} sx={{ marginBottom: 4 }}>
                    {control.years.map((item, i) => {
                      const errorYear = (errors?.years && errors.years[i]) || null;

                      return (
                        <Grid
                          key={i}
                          container
                          spacing={3}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                            marginBottom: 3
                          }}
                          paddingLeft={{
                            sm: 0,
                            md: 10
                          }}
                          paddingRight={{
                            sm: 0,
                            md: 10
                          }}
                        >
                          <Grid item md={4} xs={12}>
                            <TextField
                              error={Boolean(errorYear?.year)}
                              helperText={errorYear?.year}
                              fullWidth
                              label="Gestión"
                              name="year"
                              onChange={(e) => handleChangeYear(e, i)}
                              required
                              select
                              SelectProps={{ native: true }}
                              value={item.year}
                              variant="outlined"
                            >
                              {yearOptionsList.map((option, i) => (
                                <option key={i} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </TextField>
                          </Grid>
                          <Grid
                            item
                            md={2}
                            xs={12}
                            sx={{
                              display: 'flex',
                              justifyContent: 'center'
                            }}
                          >
                            <Button
                              disabled={control.years.length === 1}
                              variant="contained"
                              color="error"
                              onClick={() => onHandleRemove(i)}
                              endIcon={<Iconify icon="eva:trash-2-outline" />}
                            >
                              Remover
                            </Button>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid container marginTop={2} display="flex" justifyContent="center">
                      <Button
                        disabled={control.years.length === 5}
                        variant="contained"
                        color="primary"
                        onClick={onAddYear}
                        endIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        Añadir Año
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.typeOfTaxPayer)}
                    fullWidth
                    helperText={errors?.typeOfTaxPayer}
                    label="Tipo de Contribuyente"
                    name="typeOfTaxPayer"
                    onChange={handleChange}
                    value={control.typeOfTaxPayer}
                    variant="outlined"
                    select
                    SelectProps={{ native: true }}
                  >
                    {typeOfTaxPayerOptionsList.map((option, i) => (
                      <option key={i} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </TextField>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.dueTaxDate)}
                    fullWidth
                    helperText={errors?.dueTaxDate}
                    label="Fecha de cierre de E.E.F.F."
                    placeholder="Ingrese en formato DD/MM"
                    name="dueTaxDate"
                    onChange={handleChange}
                    value={control.dueTaxDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.billingModality)}
                    fullWidth
                    helperText={errors?.billingModality}
                    label="Modalidad de Facturación"
                    placeholder="Ej. Facturación manual, valorada, computarizada, en línea"
                    name="billingModality"
                    onChange={handleChange}
                    value={control.billingModality}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="service"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeService(e, data)}
                    value={control.service}
                    options={serviceOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Servicio"
                        required
                        variant="outlined"
                        error={Boolean(errors?.service)}
                        helperText={errors?.service}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.price)}
                    fullWidth
                    helperText={errors?.price}
                    label="Precio Total Bs."
                    placeholder="Precio en Bolivianos"
                    name="price"
                    onChange={handleChangePrice}
                    value={control.price}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.observations)}
                    fullWidth
                    helperText={errors?.observations}
                    label="Observaciones"
                    placeholder="Información adicional"
                    name="observations"
                    multiline
                    onChange={handleChange}
                    value={control.observations}
                    rows={4}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen)}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
