import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import UserContext from '../../context/user/userContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

export default function UserDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userContext = useContext(UserContext);
  const { loading, clearData, errors, user: userData, getUser } = userContext;
  const [user, setUser] = useState({
    name: '',
    email: '',
    role: '',
    createdAt: '',
    state: ''
  });
  // Id received by previous screen
  const userId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (userId) {
      getUser(userId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (!loading && userData) {
      setUser({
        name: userData.name || '',
        email: userData.email || '',
        role: userData.role || '',
        createdAt: userData.createdAt
          ? moment(userData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        state: userData?.state ? 'Activo' : 'Inactivo'
      });
    }
  }, [loading, userData]);

  return (
    <Page title="Usuarios | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/users" />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del usuario"
            title="Detalles de Usuario"
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Identificación única del usuario"
                  label="Nombre"
                  name="name"
                  onChange={null}
                  required
                  value={user.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  onChange={null}
                  required
                  value={user.email}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Rol"
                  name="role"
                  onChange={null}
                  required
                  value={user.role}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Estado"
                  name="state"
                  helperText="Usuario con acceso al sistema"
                  onChange={null}
                  required
                  value={user.state}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={user.createdAt}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button variant="outlined" type="button" onClick={() => navigate('/dashboard/users')}>
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
