import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Button,
  Container,
  Box,
  Grid,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableFooter,
  Stack,
  Paper
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import moment from 'moment';
// components
import ClientContext from '../../context/client/clientContext';
import SnackbarContext from '../../context/snackbar/snackbarContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';
import Search from '../../components/Search';
import { getFileFromServer, getFileExtension } from '../../utils/functionUtils';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const IconifyStyle = styled(Iconify)(() => ({
  marginBottom: -5,
  height: 30,
  width: 30,
  marginRight: 5
}));

const columnsTable = [
  {
    id: 0,
    name: 'Título'
  },
  {
    id: 1,
    name: 'Fecha de Modificación'
  },
  {
    id: 3,
    name: 'Observaciones'
  },
  {
    id: 4,
    name: 'Usuario'
  },
  {
    id: 5,
    name: ''
  },
  {
    id: 6,
    name: ''
  }
];

export default function DocumentsListing() {
  const navigate = useNavigate();
  const clientContext = useContext(ClientContext);
  const { openErrorSnackbar } = useContext(SnackbarContext);
  const {
    loading,
    clearData: clearDataClient,
    errors,
    client: clientData,
    getMyProfileClient
  } = clientContext;
  const [client, setClient] = useState({
    id: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    getMyProfileClient();

    return () => clearDataClient();
    // eslint-disable-next-line
  }, []);

  // Load client data
  useEffect(() => {
    if (!loading && clientData) {
      setClient({
        ...client,
        id: clientData._id || ''
      });
    }
    // eslint-disable-next-line
  }, [loading, clientData]);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      clientData?.documents?.map((item) => ({
        id: item._id,
        title: item.title || '',
        file: item.file || '',
        observations: item.observations || '-',
        updatedAt: item.createdAt
          ? `${moment(item.updatedAt).format('DD/MM/YYYY [a las] HH:mm')}`
          : '',
        user: item.user?.name || ''
      })) || [];

    if (search) {
      computedItems = computedItems.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.updatedAt.toLowerCase().includes(search.toLowerCase()) ||
          item.user.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [clientData, page, search, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onReload = () => {
    getMyProfileClient();
  };

  const handleAction = async (action, id, data = {}) => {
    if (action === 'download') {
      const { name, path } = data;
      const titleFile = `${name}-${Date.now()}`;
      const ext = getFileExtension(path);
      try {
        const resFile = await getFileFromServer(path);
        const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `${titleFile}.${ext}`);
      } catch (error) {
        openErrorSnackbar();
      }
    }
  };

  return (
    <Page title="Mi Perfil Cliente | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Documentos
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                <Button
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard/client/profile/documents/add', {
                      state: { id: client.id }
                    });
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Documento
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Documents table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Documentos del Cliente
                </Typography>
                {rows.length !== 0 && (
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                  >
                    <Search
                      search={search}
                      setSearch={setSearch}
                      placeholder="Buscar por título, fecha de modificación, usuario"
                    />
                    <Button type="submit" variant="outlined" color="secondary">
                      Buscar
                    </Button>
                  </Box>
                )}
              </Box>
              {/* Empty documents */}
              {rows.length === 0 ? (
                <Grid item sx={{ p: 5, textAlign: 'center' }}>
                  <Typography>
                    <IconifyStyle icon="eva:inbox-outline" />
                    Lo sentimos no existen documentos cargados
                  </Typography>
                </Grid>
              ) : (
                <TableStyle aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {columnsTable.map((item) => (
                        <TableCell key={item.id}>{item.name}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component="th" scope="row">
                          {row.title}
                        </TableCell>
                        <TableCell>{row.updatedAt}</TableCell>
                        <TableCell>{row.observations}</TableCell>
                        <TableCell>{row.user}</TableCell>
                        <TableCell>
                          <Button
                            variant="contained"
                            color="primary"
                            size="medium"
                            onClick={() =>
                              handleAction('download', row.id, { name: row.title, path: row.file })
                            }
                            startIcon={<Iconify icon="eva:cloud-download-outline" />}
                          >
                            Descargar Archivo
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                        colSpan={6}
                        count={clientData?.documents?.length || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        labelRowsPerPage="Filas por página"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        labelDisplayedRows={({ from, to, count }) =>
                          `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                        }
                      />
                    </TableRow>
                  </TableFooter>
                </TableStyle>
              )}
            </TableContainer>
          </Paper>
        )}
      </Container>
    </Page>
  );
}
