import {
  GET_TRANSACTIONS_REPORT,
  GET_INVOICES_REPORT,
  SET_LOADING_REPORT,
  REPORT_ERROR,
  CLEAR_ERRORS_REPORT,
  CLEAR_DATA_REPORT
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_TRANSACTIONS_REPORT:
      return {
        ...state,
        transactions: action.payload,
        errors: null,
        loading: false
      };
    case GET_INVOICES_REPORT:
      return {
        ...state,
        invoices: action.payload,
        errors: null,
        loading: false
      };
    case SET_LOADING_REPORT:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case REPORT_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_ERRORS_REPORT:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case CLEAR_DATA_REPORT:
      return {
        transactions: null,
        invoices: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
