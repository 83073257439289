import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Autocomplete
} from '@mui/material';
import moment from 'moment';
// components
import InvoiceContext from '../../context/invoice/invoiceContext';
import VoucherContext from '../../context/voucher/voucherContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const previousScreen = `/dashboard/clients/invoices/listing`;

const dialog = {
  message: `¿Estas seguro de crear una nueva factura?`
};

export default function CreateInvoice() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const invoiceContext = useContext(InvoiceContext);
  const voucherContext = useContext(VoucherContext);
  const {
    loading: loadingInvoice,
    clearData: clearInvoiceData,
    createInvoice,
    errors,
    setLoading
  } = invoiceContext;
  const {
    loading: loadingVoucher,
    vouchers,
    clearData: clearVoucherData,
    getVouchersByClient
  } = voucherContext;
  const [invoice, setInvoice] = useState({
    voucher: {
      value: '0',
      label: 'Seleccione una opción'
    },
    invoiceDate: moment().format('YYYY-MM-DD'),
    clientIdentification: '',
    clientName: '',
    amount: 0,
    observations: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [voucherOptionsList, setVoucherOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  // Id received by previous screen
  const clientId = state?.clientId;
  const typeClient = state?.typeClient;

  useEffect(() => {
    if (clientId && typeClient) {
      setLoading(false);
      getVouchersByClient({ client: clientId });
    } else {
      navigate('/');
    }

    return () => {
      clearInvoiceData();
      clearVoucherData();
    };
    // eslint-disable-next-line
  }, []);

  // Load vouchers
  useEffect(() => {
    if (!loadingVoucher && vouchers) {
      const itemsData = vouchers?.vouchers.map((item) => ({
        _id: item._id,
        value: item._id,
        // eslint-disable-next-line
        label: `${moment(item.voucherExpiration.slice(0, 10)).format('DD/MM/YYYY')} | ${item.authorizationCode}`
      }));
      setVoucherOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingVoucher, vouchers]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setInvoice({
      ...invoice,
      [name]: value
    });
  };

  // Validate input number and decimals
  const handleChangeAmount = async (e) => {
    const { value } = e.target;
    let valueField = invoice.amount;

    // eslint-disable-next-line
    if (!isNaN(parseFloat(value)) && isFinite(value)) {
      valueField = value;
    }

    if (e.target.value === '') {
      valueField = '';
    }

    setInvoice({
      ...invoice,
      amount: valueField
    });
  };

  // Validate input solo number
  const handleChangeNumber = async (e) => {
    const { value } = e.target;
    let valueField = invoice.clientIdentification;

    // Check if the value is an integer number
    if (/^\d+$/.test(value)) {
      valueField = value;
    }

    if (e.target.value === '') {
      valueField = '';
    }

    setInvoice({
      ...invoice,
      clientIdentification: valueField
    });
  };

  const handleChangeVoucher = (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';

    setInvoice({
      ...invoice,
      voucher: { value, label }
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      voucher: invoice.voucher.value !== '0' ? invoice.voucher.value : '',
      invoiceDate: invoice.invoiceDate,
      clientIdentification: invoice.clientIdentification,
      clientName: invoice.clientName,
      amount: invoice.amount,
      observations: invoice.observations
    };

    setOpenDialog(false);
    if ((await createInvoice(formData, clientId)) === true) {
      navigate(previousScreen, {
        state: {
          id: clientId,
          typeClient
        }
      });
    }
  };

  return (
    <Page title="Facturas | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: clientId, typeClient }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para crear un nuevo registro"
            title="Crear Registro"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="voucher"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeVoucher(e, data)}
                    value={invoice.voucher}
                    options={voucherOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fecha de Vencimiento Talonario | Código de autorización"
                        variant="outlined"
                        required
                        error={Boolean(errors?.voucher)}
                        helperText={errors?.voucher}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.invoiceDate)}
                    helperText={errors?.invoiceDate}
                    fullWidth
                    label="Fecha de Factura"
                    name="invoiceDate"
                    onChange={handleChange}
                    required
                    type="date"
                    value={invoice.invoiceDate}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.clientIdentification)}
                    helperText={errors?.clientIdentification}
                    fullWidth
                    label="NIT/CI/CEX"
                    name="clientIdentification"
                    onChange={handleChangeNumber}
                    required
                    value={invoice.clientIdentification}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.clientName)}
                    helperText={errors?.clientName}
                    fullWidth
                    label="Nombre o Razón Social del Cliente"
                    name="clientName"
                    onChange={handleChange}
                    required
                    value={invoice.clientName}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.amount)}
                    helperText={errors?.amount}
                    fullWidth
                    label="Importe (Bs.)"
                    placeholder="Ingrese monto en bolivianos"
                    name="amount"
                    onChange={handleChangeAmount}
                    required
                    value={invoice.amount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.observations)}
                    helperText={errors?.observations}
                    fullWidth
                    label="Observaciones"
                    name="observations"
                    onChange={handleChange}
                    multiline
                    rows={4}
                    value={invoice.observations}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen, { state: { id: clientId, typeClient } })}
              >
                Cancelar
              </Button>
              <Button
                disabled={loadingInvoice}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loadingInvoice}
      />
    </Page>
  );
}
