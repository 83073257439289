import * as Yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { styled } from '@mui/material/styles';
// material
import { Link, Stack, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import AuthContext from '../../../context/auth/authContext';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  color: theme.palette.error.main
}));

export default function ResetPasswordForm() {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { resetPassword, info, errors: authErrors, clearAuthErrors } = authContext;
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatedPassword, setShowRepeatedPassword] = useState(false);
  const [token, setToken] = useState(null);

  // Check if exist token in url browser
  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');
    if (token) setToken(token);
    else navigate('/', { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Unmount component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearAuthErrors(), []);

  const LoginSchema = Yup.object().shape({
    password: Yup.string().required('Contraseña es requerido'),
    repeatedPassword: Yup.string().required('Contraseña de confirmación es requerido')
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      repeatedPassword: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      await resetPassword(formik.values, token);
    }
  });

  const {
    errors,
    touched,
    // values,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleShowRepeatedPassword = () => {
    setShowRepeatedPassword((show) => !show);
  };

  return (
    <RootStyle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Nueva contraseña"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(
                (touched.password && errors.password) || (authErrors && authErrors.password)
              )}
              helperText={
                (touched.password && errors.password) || (authErrors && authErrors.password)
              }
            />

            <TextField
              fullWidth
              autoComplete="repeat-password"
              type={showRepeatedPassword ? 'text' : 'password'}
              label="Confirmar nueva contraseña"
              {...getFieldProps('repeatedPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowRepeatedPassword} edge="end">
                      <Iconify icon={showRepeatedPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.repeatedPassword && errors.repeatedPassword)}
              helperText={touched.repeatedPassword && errors.repeatedPassword}
            />
          </Stack>

          {/* Info */}
          {info && (
            <Typography
              variant="body2"
              component="div"
              color="info.main"
              align="center"
              sx={{
                mt: 3
              }}
            >
              {info}
            </Typography>
          )}

          {/* General error */}
          {authErrors && authErrors.error && (
            <Typography
              variant="body2"
              component="div"
              align="center"
              sx={{
                mt: 3
              }}
            >
              {authErrors.error}
            </Typography>
          )}

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Typography variant="body2" align="center" color="primary">
              Ya tienes una cuenta?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/login" underline="hover">
                Inicia Sesión
              </Link>
            </Typography>
          </Stack>

          <LoadingButton
            disabled={Boolean(info)}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Restablecer mi contraseña
          </LoadingButton>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
