import { GET_USERS, GET_USER, SET_LOADING_USER, USER_ERROR, CLEAR_USER } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_USERS:
      return {
        ...state,
        users: action.payload,
        errors: null,
        loading: false
      };
    case GET_USER:
      return {
        ...state,
        errors: null,
        user: action.payload,
        loading: false
      };
    case SET_LOADING_USER:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case USER_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_USER:
      return {
        ...state,
        users: null,
        user: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
