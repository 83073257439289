// Snackbar
export const OPEN_MESSAGE_SUCCESS_SNACKBAR = 'OPEN_MESSAGE_SUCCESS_SNACKBAR';
export const OPEN_MESSAGE_ERROR_SNACKBAR = 'OPEN_MESSAGE_ERROR_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const RESET_SNACKBAR = 'RESET_SNACKBAR';

// Auth
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGOUT = 'LOGOUT';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS';

// Users
export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const SET_LOADING_USER = 'SET_LOADING_USER';
export const USER_ERROR = 'USER_ERROR';
export const CLEAR_USER = 'CLEAR_USER';

// Afps
export const GET_AFPS = 'GET_AFPS';
export const SET_LOADING_AFP = 'SET_LOADING_AFP';
export const AFP_ERROR = 'AFP_ERROR';
export const CLEAR_AFP = 'CLEAR_AFP';

// HealthInsurance
export const GET_HEALTH_INSURANCES = 'GET_HEALTH_INSURANCES';
export const GET_HEALTH_INSURANCE = 'GET_HEALTH_INSURANCE';
export const CREATE_HEALTH_INSURANCE_SUCCESS = 'CREATE_HEALTH_INSURANCE_SUCCESS';
export const UPDATE_HEALTH_INSURANCE_SUCCESS = 'UPDATE_HEALTH_INSURANCE_SUCCESS';
export const DELETE_HEALTH_INSURANCE_SUCCESS = 'DELETE_HEALTH_INSURANCE_SUCCESS';
export const SET_LOADING_HEALTH_INSURANCE = 'SET_LOADING_HEALTH_INSURANCE';
export const HEALTH_INSURANCE_ERROR = 'HEALTH_INSURANCE_ERROR';
export const CLEAR_HEALTH_INSURANCE = 'CLEAR_HEALTH_INSURANCE';

// Departments
export const GET_DEPARTMENTS = 'GET_DEPARTMENTS';
export const SET_LOADING_DEPARTMENT = 'SET_LOADING_DEPARTMENT';
export const DEPARTMENT_ERROR = 'DEPARTMENT_ERROR';
export const CLEAR_DEPARTMENT = 'CLEAR_DEPARTMENT';

// Municipalities
export const GET_MUNICIPALITIES = 'GET_MUNICIPALITIES';
export const GET_MUNICIPALITY = 'GET_MUNICIPALITY';
export const SET_LOADING_MUNICIPALITY = 'SET_LOADING_MUNICIPALITY';
export const CREATE_MUNICIPALITY_SUCCESS = 'CREATE_MUNICIPALITY_SUCCESS';
export const UPDATE_MUNICIPALITY_SUCCESS = 'UPDATE_MUNICIPALITY_SUCCESS';
export const DELETE_MUNICIPALITY_SUCCESS = 'DELETE_MUNICIPALITY_SUCCESS';
export const MUNICIPALITY_ERROR = 'MUNICIPALITY_ERROR';
export const CLEAR_DATA_MUNICIPALITY = 'CLEAR_DATA_MUNICIPALITY';

// client
export const GET_CLIENTS = 'GET_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const SET_LOADING_CLIENT = 'SET_LOADING_CLIENT';
export const GET_DOCUMENTS_CLIENT = 'GET_DOCUMENTS_CLIENT';
export const GET_DOCUMENT_CLIENT = 'GET_DOCUMENT_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const CREATE_RECORD_FILE_CLIENT_SUCCESS = 'CREATE_RECORD_FILE_CLIENT_SUCCESS';
export const ADD_DOCUMENT_CLIENT_SUCCESS = 'ADD_DOCUMENT_CLIENT_SUCCESS';
export const UPDATE_DOCUMENT_CLIENT_SUCCESS = 'UPDATE_DOCUMENT_CLIENT_SUCCESS';
export const DELETE_DOCUMENT_CLIENT_SUCCESS = 'DELETE_DOCUMENT_CLIENT_SUCCESS';
export const UPLOAD_FILE_CLIENT_SUCCESS = 'UPLOAD_FILE_CLIENT_SUCCESS';
export const GET_MY_PROFILE_CLIENT = 'GET_MY_PROFILE_CLIENT';
export const GET_CLIENT_FINANCIAL_KARDEX_VIEW = 'GET_CLIENT_FINANCIAL_KARDEX_VIEW';
export const UPDATE_CLIENT_FINANCIAL_KARDEX_VIEW = 'UPDATE_CLIENT_FINANCIAL_KARDEX_VIEW';
export const CLIENT_ERROR = 'CLIENT_ERROR';
export const CLEAR_CLIENT = 'CLEAR_CLIENT';

// Categories
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORY = 'GET_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const SET_LOADING_CATEGORY = 'SET_LOADING_CATEGORY';
export const CATEGORY_ERROR = 'CATEGORY_ERROR';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

// Services
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICE = 'GET_SERVICE';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const DELETE_SERVICE_SUCCESS = 'DELETE_SERVICE_SUCCESS';
export const SET_LOADING_SERVICE = 'SET_LOADING_SERVICE';
export const SERVICE_ERROR = 'SERVICE_ERROR';
export const CLEAR_SERVICE = 'CLEAR_SERVICE';

// Controls
export const GET_CONTROLS = 'GET_CONTROLS';
export const GET_CONTROL = 'GET_CONTROL';
export const CREATE_CONTROL = 'CREATE_CONTROL';
export const UPDATE_CONTROL = 'UPDATE_CONTROL';
export const UPDATE_IS_COMPLETED_CONTROL = 'UPDATE_IS_COMPLETED_CONTROL';
export const DELETE_CONTROL = 'DELETE_CONTROL';
export const FUSION_CONTROL_SUCCESS = 'FUSION_CONTROL_SUCCESS';
export const GET_PAYMENTS_CONTROL = 'GET_PAYMENTS_CONTROL';
export const GET_PAYMENT_CONTROL = 'GET_PAYMENT_CONTROL';
export const ADD_PAYMENT_CONTROL = 'ADD_PAYMENT_CONTROL';
export const UPDATE_PAYMENT_CONTROL = 'UPDATE_PAYMENT_CONTROL';
export const DELETE_PAYMENT_CONTROL = 'DELETE_PAYMENT_CONTROL';
export const SET_LOADING_CONTROL = 'SET_LOADING_CONTROL';
export const GET_DISCOUNT_CONTROL = 'GET_DISCOUNT_CONTROL';
export const ADD_DISCOUNT_CONTROL = 'ADD_DISCOUNT_CONTROL';
export const UPDATE_DISCOUNT_CONTROL = 'UPDATE_DISCOUNT_CONTROL';
export const DELETE_DISCOUNT_CONTROL = 'DELETE_DISCOUNT_CONTROL';
export const ADD_SERVICE_CONTROL = 'ADD_SERVICE_CONTROL';
export const UPDATE_SERVICE_CONTROL = 'UPDATE_SERVICE_CONTROL';
export const GET_SERVICE_CONTROL = 'GET_SERVICE_CONTROL';
export const DELETE_SERVICE_CONTROL = 'DELETE_SERVICE_CONTROL';
export const GET_BALANCE_NOTIFICATION_CONTROL = 'GET_BALANCE_NOTIFICATION_CONTROL';
export const UPDATE_BALANCE_NOTIFICATION_CONTROL = 'UPDATE_BALANCE_NOTIFICATION_CONTROL';
export const GET_BALANCES_NOTIFICATION_CLIENT = 'GET_BALANCES_NOTIFICATION_CLIENT';
export const SUCCESS_CONTROL_FILE = 'SUCCESS_CONTROL_FILE';
export const SUCCESS_INVOICE_PAYMENT_FILE = 'SUCCESS_INVOICE_PAYMENT_FILE';
export const CONTROL_ERROR = 'CONTROL_ERROR';
export const CLEAR_PAYMENTS = 'CLEAR_PAYMENTS';
export const CLEAR_CONTROL = 'CLEAR_CONTROL';

// Transactions
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const SET_LOADING_TRANSACTION = 'SET_LOADING_TRANSACTION';
export const TRANSACTION_CREATE_SUCCESS = 'TRANSACTION_CREATE_SUCCESS';
export const TRANSACTION_UPDATE_SUCCESS = 'TRANSACTION_UPDATE_SUCCESS';
export const TRANSACTION_DELETE_SUCCESS = 'TRANSACTION_DELETE_SUCCESS';
export const TRANSACTION_ERROR = 'TRANSACTION_ERROR';
export const CLEAR_DATA_TRANSACTION = 'CLEAR_DATA_TRANSACTION';

// Reports
export const GET_TRANSACTIONS_REPORT = 'GET_TRANSACTIONS_REPORT';
export const GET_INVOICES_REPORT = 'GET_INVOICES_REPORT';
export const SET_LOADING_REPORT = 'SET_LOADING_REPORT';
export const REPORT_ERROR = 'REPORT_ERROR';
export const CLEAR_ERRORS_REPORT = 'CLEAR_ERRORS_REPORT';
export const CLEAR_DATA_REPORT = 'CLEAR_DATA_REPORT';

// Files
export const GET_FILES = 'GET_FILES';
export const GET_FILE = 'GET_FILE';
export const CREATE_FILE_SUCCESS = 'CREATE_FILE_SUCCESS';
export const UPDATE_FILE_SUCCESS = 'UPDATE_FILE_SUCCESS';
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const SET_LOADING_FILE = 'SET_LOADING_FILE';
export const FILE_ERROR = 'FILE_ERROR';
export const CLEAR_FILE = 'CLEAR_FILE';

// Configurations
export const GET_QR_CONFIGURATION = 'GET_QR_CONFIGURATION';
export const GET_QR_ID_CONFIGURATION = 'GET_QR_ID_CONFIGURATION';
export const CREATE_QR_CONFIGURATION_SUCCESS = 'CREATE_QR_CONFIGURATION_SUCCESS';
export const UPDATE_QR_CONFIGURATION_SUCCESS = 'UPDATE_QR_CONFIGURATION_SUCCESS';
export const SET_LOADING_QR_CONFIGURATION = 'SET_LOADING_QR_CONFIGURATION';
export const CONFIGURATION_ERROR = 'CONFIGURATION_ERROR';
export const CLEAR_CONFIGURATION = 'CLEAR_CONFIGURATION';

// Vouchers
export const GET_VOUCHERS = 'GET_VOUCHERS';
export const GET_VOUCHER = 'GET_VOUCHER';
export const SET_LOADING_VOUCHER = 'SET_LOADING_VOUCHER';
export const VOUCHER_CREATE_SUCCESS = 'VOUCHER_CREATE_SUCCESS';
export const VOUCHER_UPDATE_SUCCESS = 'VOUCHER_UPDATE_SUCCESS';
export const VOUCHER_DELETE_SUCCESS = 'VOUCHER_DELETE_SUCCESS';
export const VOUCHER_ERROR = 'VOUCHER_ERROR';
export const CLEAR_DATA_VOUCHER = 'CLEAR_DATA_VOUCHER';

// Invoices
export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICE = 'GET_INVOICE';
export const SET_LOADING_INVOICE = 'SET_LOADING_INVOICE';
export const INVOICE_CREATE_SUCCESS = 'INVOICE_CREATE_SUCCESS';
export const INVOICE_UPDATE_SUCCESS = 'INVOICE_UPDATE_SUCCESS';
export const INVOICE_DELETE_SUCCESS = 'INVOICE_DELETE_SUCCESS';
export const INVOICE_ERROR = 'INVOICE_ERROR';
export const CLEAR_DATA_INVOICE = 'CLEAR_DATA_INVOICE';
