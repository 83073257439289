import React from 'react';
import { useLocation } from 'react-router-dom';
import ClientsIPListing from './ClientsIPListing';
import ClientsLEListing from './ClientsLEListing';
import ClientsSRListing from './ClientsSRListing';
import ClientsUEListing from './ClientsUEListing';

export default function IndexListingClient() {
  const { search: searchURL } = useLocation();
  const query = new URLSearchParams(searchURL);
  const paramTypeClient = query.get('typeClient');

  const isClientIndependentProfessional =
    paramTypeClient === 'Profesionales Independientes' || false;
  const isClientLegalEntity = paramTypeClient === 'Personas Juridicas SRL' || false;
  const isClientSpecialRegime = paramTypeClient === 'Regimenes Especiales' || false;
  const isClientUnipersonalEnterprise = paramTypeClient === 'Empresas Unipersonales' || false;

  let typeClient = '';
  if (isClientIndependentProfessional) {
    typeClient = 'Profesionales%20Independientes';
  }
  if (isClientLegalEntity) {
    typeClient = 'Personas%20Juridicas%20SRL';
  }
  if (isClientSpecialRegime) {
    typeClient = 'Regimenes%20Especiales';
  }
  if (isClientUnipersonalEnterprise) {
    typeClient = 'Empresas%20Unipersonales';
  }

  if (isClientIndependentProfessional) {
    return (
      <ClientsIPListing
        isClientIndependentProfessional={isClientIndependentProfessional}
        typeClient={typeClient}
      />
    );
  }

  if (isClientLegalEntity) {
    return <ClientsLEListing isClientLegalEntity={isClientLegalEntity} typeClient={typeClient} />;
  }

  if (isClientSpecialRegime) {
    return (
      <ClientsSRListing isClientSpecialRegime={isClientSpecialRegime} typeClient={typeClient} />
    );
  }

  if (isClientUnipersonalEnterprise) {
    return (
      <ClientsUEListing
        isClientUnipersonalEnterprise={isClientUnipersonalEnterprise}
        typeClient={typeClient}
      />
    );
  }

  return <div></div>;
}
