import React, { useReducer } from 'react';
import axios from 'axios';
import AfpContext from './afpContext';
import afpReducer from './afpReducer';

import { GET_AFPS, SET_LOADING_AFP, AFP_ERROR, CLEAR_AFP } from '../types';

function AfpState(props) {
  const initialState = {
    afps: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(afpReducer, initialState);

  // Fetch all afps
  const getAfps = async () => {
    try {
      setLoading();
      const res = await axios.get(`/api/v1/afps`);
      dispatch({
        type: GET_AFPS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: AFP_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Set Loading
  const setLoading = async () => {
    dispatch({
      type: SET_LOADING_AFP
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_AFP
    });
  };

  return (
    <AfpContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        afps: state.afps,
        errors: state.errors,
        loading: state.loading,
        getAfps,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </AfpContext.Provider>
  );
}

export default AfpState;
