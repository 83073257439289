import React, { useEffect, useContext, useState, useMemo } from 'react';
import moment from 'moment';
// material
import {
  Card,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableFooter,
  Autocomplete
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import { write, utils } from 'xlsx';
// components
import ReportContext from '../../context/report/reportContext';
import VoucherContext from '../../context/voucher/voucherContext';
import ClientContext from '../../context/client/clientContext';
// import ClientContext from '../../context/client/clientContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import TablePaginationActions from '../../components/TablePaginationActions';
import CircularLoading from '../../components/CircularLoading';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

// const GridStyle = styled(Grid)({
//   display: 'inline-flex',
//   gap: 10
// });

// const TypographyTitleStyle = styled(Typography)({
//   display: 'inline-block',
//   fontWeight: 'bold'
// });

// const TypographyStyle = styled(Typography)({
//   display: 'inline-block'
// });

const columnsTable = [
  { id: 0, name: 'Nº Factura' },
  { id: 1, name: 'Fecha de la Factura' },
  { id: 2, name: 'NIT/CI/CEX' },
  { id: 3, name: 'Nombre o Razón Social del Cliente' },
  { id: 4, name: 'Importe (Bs.)' },
  { id: 6, name: 'Observaciones' },
  { id: 7, name: '' }
];

export default function InvoicesReport() {
  const reportContext = useContext(ReportContext);
  const voucherContext = useContext(VoucherContext);
  const clientContext = useContext(ClientContext);
  // const clientContext = useContext(ClientContext);
  const {
    loading: loadingReport,
    errors,
    getInvoicesReport,
    clearData: clearReportData,
    setLoading,
    invoices
  } = reportContext;
  const {
    loading: loadingVoucher,
    vouchers,
    clearData: clearVoucherData,
    getVouchersByClient
  } = voucherContext;
  // eslint-disable-next-line
  const {
    loading: loadingClient,
    clients,
    clearData: clearClientData,
    getClients
  } = clientContext
  // const {
  //   loading,
  //   clearData: clearDataClient,
  //   client: clientData,
  //   getMyProfileClient
  // } = clientContext;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [report, setReport] = useState({
    client: {
      value: '0',
      label: 'Seleccione una opción'
    },
    voucher: {
      value: '0',
      label: 'Seleccione una opción'
    }
  });
  // const [client, setClient] = useState({
  //   name: '',
  //   owner: '',
  //   nit: ''
  // });
  // const [invoiceTitle, setInvoiceTitle] = useState({
  //   voucherExpiration: '',
  //   authorizationCode: ''
  // });
  const [clientOptionsList, setClientOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [voucherOptionsList, setVoucherOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    setLoading(false);
    getClients({ type: 'all' });

    return () => {
      clearReportData();
      clearVoucherData();
      clearClientData();
      // clearDataClient();
    };
    // eslint-disable-next-line
  }, []);

  // Load vouchers
  useEffect(() => {
    if (!loadingVoucher && vouchers) {
      const itemsData = vouchers?.vouchers.map((item) => ({
        _id: item._id,
        value: item._id,
        // eslint-disable-next-line
        label: `${moment(item.voucherExpiration.slice(0, 10)).format('DD/MM/YYYY')} | ${item.authorizationCode}`
      }));
      setVoucherOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingVoucher, vouchers]);

  // Load clients
  useEffect(() => {
    if (!loadingClient && clients) {
      const itemsData = clients?.clients.map((item) => {
        // eslint-disable-next-line
        const clientName = item.name || item.owner
        return {
          _id: item._id,
          value: item._id,
          label: `${clientName} | ${item.nit}`
        };
      });
      setClientOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
  }, [loadingClient, clients]);

  // // Load client data
  // useEffect(() => {
  //   if (!loading && clientData) {
  //     setClient({
  //       name: clientData.name || '',
  //       owner: clientData.owner || '',
  //       nit: clientData.nit || ''
  //     });
  //   }
  //   // eslint-disable-next-line
  // }, [loading, clientData]);

  // Get data table
  // prettier-ignore
  const rows = useMemo(() => {
    let computedItems = invoices?.map((item) => ({
      id: item._id,
      invoiceIdentifier: item.invoiceIdentifier,
      invoiceDate: moment(item.invoiceDate.slice(0, 10)).format('DD/MM/YYYY'),
      authorizationCode: item.authorizationCode,
      clientIdentification: item.clientIdentification,
      clientName: item.clientName,
      amount: item.amount,
      observations: item.observations || '-',
      voucher: {
        voucherExpiration: item.voucher ? moment(item.voucher.voucherExpiration.slice(0, 10)).format('DD/MM/YYYY') : '',
        authorizationCode: item.voucher?.authorizationCode || '-'
      },
      createdAt: moment(item.createdAt).format('DD/MM/YYYY [a las] HH:mm'),
      user: item.user?.name || '-'
    })) || [];

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    // Sum total incomes
    const totalAmount = computedItems.reduce((prev, cur) => prev + cur.amount, 0);
    setTotalAmount(totalAmount)

    return computedItems;
  }, [invoices, page, rowsPerPage]);

  const handleChangeClient = async (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';

    // Get vouchers of client
    if (value !== '0') {
      getVouchersByClient({ client: value });
    }

    setReport({
      ...report,
      client: { value, label },
      voucher: {
        value: '0',
        label: 'Seleccione una opción'
      }
    });
  };

  const handleChangeVoucher = (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';
    // let voucherExpiration = '';
    // let authorizationCode = '';

    // // Get value from voices data
    // if (value !== '0') {
    //   const voucher = vouchers?.vouchers.find((v) => v._id === value);
    //   voucherExpiration = moment(voucher?.voucherExpiration.slice(0, 10)).format('DD/MM/YYYY');
    //   authorizationCode = voucher.authorizationCode;
    // }

    setReport({
      ...report,
      voucher: { value, label }
    });
    // setInvoiceTitle({
    //   voucherExpiration,
    //   authorizationCode
    // });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      voucher: report.voucher.value !== '0' ? report.voucher.value : '',
      client: report.client.value !== '0' ? report.client.value : ''
    };

    getInvoicesReport({
      voucher: formData.voucher,
      client: formData.client
    });
  };

  const onDownloadButton = () => {
    // Download file xlsx
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Generate name
    const fileName = `reporte-facturas-${moment().format('DD/MM/YYYY')}`;

    // Generate items
    // prettier-ignore
    const items = rows.map((item) => ({
      'Nº Factura': item.invoiceIdentifier,
      'Fecha de la Factura': item.invoiceDate,
      'NIT/CI/CEX': item.clientIdentification,
      "Nombre o Razón Social del Cliente": item.clientName,
      'Importe (Bs.)': item.amount,
      'Observaciones': item.observations,
      "Fecha de Vencimiento del Talonario": item.voucher.voucherExpiration,
      "Código de Autorización": item.voucher.authorizationCode,
      "Fecha de Registro": item.createdAt,
      "Registrado por": item.user
    }));

    // Convert to xlsx
    const ws = utils.json_to_sheet(items);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, {
      bookType: 'xlsx',
      type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };

  const handleClearInputForm = () => {
    // clear data and store
    setReport({
      client: {
        value: '0',
        label: 'Seleccione una opción'
      },
      voucher: {
        value: '0',
        label: 'Seleccione una opción'
      }
    });
    // reset store
    clearReportData();
    clearVoucherData();
    setLoading(false);
  };

  return (
    <Page title="Facturas  | G&G Asesor">
      <Container>
        <Card>
          <CardHeader
            subheader="Ingrese los datos en el formulario para realizar una consulta"
            title="Reporte de Facturas"
          />
          <Divider />
          <form onSubmit={onSubmit}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="client"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeClient(e, data)}
                    value={report.client}
                    options={clientOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Cliente | NIT"
                        variant="outlined"
                        required
                        error={Boolean(errors?.client)}
                        helperText={errors?.client}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="voucher"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeVoucher(e, data)}
                    value={report.voucher}
                    options={voucherOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Fecha de Vencimiento Talonario | Código de autorización"
                        variant="outlined"
                        required
                        error={Boolean(errors?.voucher)}
                        helperText={errors?.voucher}
                      />
                    )}
                  />
                </Grid>
                {/* Error */}
                {errors?.error && (
                  <Grid item md={12} xs={12}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              width="100%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
              p={3}
            >
              <Button
                variant="outlined"
                type="button"
                onClick={handleClearInputForm}
                startIcon={<Iconify icon="eva:trash-2-outline" />}
              >
                Limpiar
              </Button>
              <Button
                disabled={loadingReport}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Consultar
              </Button>
              <Button
                color="secondary"
                variant="contained"
                type="button"
                startIcon={<Iconify icon="eva:download-fill" />}
                onClick={onDownloadButton}
              >
                Descargar Datos
              </Button>
            </Box>
          </form>
          <Divider />

          {/* Loading */}
          {loadingReport && <CircularLoading height={100} />}

          {/* <CardContent>
            <Grid container spacing={3}>
              <GridStyle item md={12} xs={12}>
                <TypographyTitleStyle>Cliente:</TypographyTitleStyle>
                <TypographyStyle>{client.name || client.owner}</TypographyStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyTitleStyle>NIT:</TypographyTitleStyle>
                <TypographyStyle>{client.nit}</TypographyStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyTitleStyle>Fecha de Vencimiento de Talonario:</TypographyTitleStyle>
                <TypographyStyle>{invoiceTitle.voucherExpiration}</TypographyStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyTitleStyle>Código de Autorización Talonario:</TypographyTitleStyle>
                <TypographyStyle>{invoiceTitle.authorizationCode}</TypographyStyle>
              </GridStyle>
            </Grid>
          </CardContent> */}

          {/* Table */}
          <TableContainer>
            <Box padding={3}>
              <Typography variant="h6" gutterBottom>
                Lista de Registros
              </Typography>
            </Box>
            <TableStyle aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columnsTable.map((item) => (
                    <TableCell key={item.id}>{item.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.invoiceIdentifier}
                    </TableCell>
                    <TableCell>{row.invoiceDate}</TableCell>
                    <TableCell>{row.clientIdentification}</TableCell>
                    <TableCell>{row.clientName}</TableCell>
                    <TableCell>{row.amount.toFixed(2)}</TableCell>
                    <TableCell>{row.observations}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                    colSpan={6}
                    count={invoices?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Filas por página"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                    }
                  />
                </TableRow>
              </TableFooter>
            </TableStyle>
          </TableContainer>

          {/* Sum total */}
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Box>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16,
                      textAlign: 'right'
                    }}
                  >
                    {`Total Importe: ${totalAmount.toFixed(2)} Bs.`}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
