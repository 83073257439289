import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Autocomplete
} from '@mui/material';
import moment from 'moment';

// components
import ControlContext from '../../context/control/controlContext';
import ServiceContext from '../../context/service/serviceContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';
import { monthOptionsList, yearOptionsList } from '../../constants';

const dialog = {
  message: '¿Esta seguro de añadir un nuevo recibo de pago?'
};
const previousScreen = '/dashboard/controls/kardex';

export default function AddPaymentControl() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const serviceContext = useContext(ServiceContext);
  const {
    loading,
    errors,
    clearData,
    addPayment,
    getControl,
    control: controlData
  } = controlContext;
  const { loading: loadingService, getServices, services } = serviceContext;
  const [payment, setPayment] = useState({
    service: {
      value: '0',
      label: 'Seleccione una opción'
    },
    concept: '',
    observations: '',
    it: 0,
    iva: 0,
    iue: 0,
    other: 0,
    other2: 0,
    srv: 0,
    additionalAmount: 0,
    periods: [
      {
        month: moment().format('MMMM'),
        year: moment().format('YYYY')
      }
    ],
    otherObservations: []
  });
  const [control, setControl] = useState({
    years: [],
    accountAmount: 0,
    balanceAmount: 0
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [serviceOptionsList, setServiceOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  // Id received by previous screen
  const controlId = state?.id;

  // Fetch data
  useEffect(() => {
    if (controlId) {
      getServices();
      getControl(controlId);
    } else {
      navigate(previousScreen, {
        state: {
          id: controlId
        }
      });
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [controlId, previousScreen]);

  // Fetch data
  useEffect(() => {
    if (!loadingService && services) {
      const itemsData = services.map((item) => ({
        _id: item._id,
        value: item._id,
        label: item.concept
      }));
      setServiceOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);
    }
    // eslint-disable-next-line
  }, [loadingService, services]);

  useEffect(() => {
    if (!loading && controlData) {
      setControl({
        ...control,
        years: controlData.years || [],
        accountAmount:
          controlData.accountAmount || controlData.accountAmount === 0
            ? controlData.accountAmount
            : 0,
        balanceAmount:
          controlData.balanceAmount || controlData.balanceAmount === 0
            ? controlData.balanceAmount
            : 0
      });
    }
    // eslint-disable-next-line
  }, [loading, controlData]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    return setPayment({
      ...payment,
      [name]: value
    });
  };

  const handleChangeNumber = (e) => {
    const { name, value } = e.target;

    if (value === '') {
      // if initial value
      setPayment({
        ...payment,
        [name]: ''
      });
      return;
    }

    // Validate input number and decimals
    if (!Number.isNaN(parseFloat(Number(value))) && Number.isFinite(Number(value))) {
      setPayment({
        ...payment,
        [name]: value
      });
    }
  };

  const handleChangeSrv = (e) => {
    let { value } = e.target;
    const { name } = e.target;

    if (value === '') {
      // Get balance amount from store
      const balanceAmountData =
        controlData.balanceAmount || controlData.balanceAmount === 0
          ? controlData.balanceAmount
          : 0;

      // if initial value
      setPayment({
        ...payment,
        [name]: ''
      });
      setControl({
        ...control,
        accountAmount: 0,
        balanceAmount: balanceAmountData
      });
      return;
    }

    // Sum total accountAmount
    // Validate input number and decimals
    if (!Number.isNaN(parseFloat(Number(value))) && Number.isFinite(Number(value))) {
      // Get balance amount from store
      const balanceAmountData =
        controlData.balanceAmount || controlData.balanceAmount === 0
          ? controlData.balanceAmount
          : 0;
      // Check if value is not higher than balanceAmount else set max amount balance amount
      value = value <= balanceAmountData ? value : balanceAmountData;
      setPayment({
        ...payment,
        [name]: value
      });
      setControl({
        ...control,
        accountAmount: value,
        balanceAmount: balanceAmountData - value > 0 ? balanceAmountData - value : 0
      });
    }
  };

  const onAddPeriod = () => {
    setPayment({
      ...payment,
      periods: [
        ...payment.periods,
        {
          month: '0',
          year: moment().format('YYYY')
        }
      ]
    });
  };

  const onHandleRemovePeriod = (i) => {
    setPayment({
      ...payment,
      periods: payment.periods.filter((item, index) => index !== i)
    });
  };

  const handleChangeService = (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';

    setPayment({
      ...payment,
      service: { value, label }
    });
  };

  const handleChangePeriod = (e, i) => {
    setPayment({
      ...payment,
      periods: payment.periods.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        }
        return item;
      })
    });
  };

  const handleChangeOtherObservation = (e, i) => {
    setPayment({
      ...payment,
      otherObservations: payment.otherObservations.map((item, index) => {
        if (index === i) {
          return {
            ...item,
            [e.target.name]: e.target.value
          };
        }
        return item;
      })
    });
  };

  const onHandleRemoveOtherObservation = (i) => {
    setPayment({
      ...payment,
      otherObservations: payment.otherObservations.filter((item, index) => index !== i)
    });
  };

  const onAddOtherObservation = () => {
    setPayment({
      ...payment,
      otherObservations: [
        ...payment.otherObservations,
        {
          observation: ''
        }
      ]
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      service: payment.service.value !== '0' ? payment.service.value : '',
      concept: payment.concept,
      observations: payment.observations,
      it: payment.it,
      iva: payment.iva,
      iue: payment.iue,
      other: payment.other,
      other2: payment.other2,
      srv: payment.srv,
      additionalAmount: payment.additionalAmount,
      periods: payment.periods,
      otherObservations: payment.otherObservations
    };

    setOpenDialog(false);
    if ((await addPayment(controlId, formData)) === true) {
      navigate(previousScreen, {
        state: { id: controlId }
      });
    }
  };

  // prettier-ignore
  const totalAmountReceived = (
    Number(payment.it) +
    Number(payment.iva) +
    Number(payment.iue) +
    Number(payment.other) +
    Number(payment.other2) +
    Number(payment.srv) +
    Number(payment.additionalAmount) || 0
  ).toFixed(2);

  return (
    <Page title="Pagos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: controlId }} />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para añadir un nuevo recibo"
            title="Añadir Recibo"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="service"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeService(e, data)}
                    value={payment.service}
                    options={serviceOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Servicio"
                        variant="outlined"
                        required
                        error={Boolean(errors?.service)}
                        helperText={errors?.service}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.concept)}
                    helperText={errors?.concept}
                    fullWidth
                    required
                    label="Concepto"
                    name="concept"
                    onChange={handleChange}
                    value={payment.concept}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      Pagos de Impuestos
                    </FormHelperText>
                    <FormHelperText
                      sx={{
                        fontSize: 14
                      }}
                    >
                      Periodo
                    </FormHelperText>
                  </Box>
                </Grid>

                {/* Months and Years */}
                <Grid item md={12} xs={12} sx={{ marginBottom: 4 }}>
                  {payment.periods.map((period, i) => {
                    const errorPeriod = (errors?.periods && errors.periods[i]) || null;

                    return (
                      <Grid
                        key={i}
                        container
                        spacing={3}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          marginBottom: 3
                        }}
                        paddingLeft={{
                          sm: 0,
                          md: 10
                        }}
                        paddingRight={{
                          sm: 0,
                          md: 10
                        }}
                      >
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(errorPeriod?.month)}
                            helperText={errorPeriod?.month}
                            fullWidth
                            label="Mes"
                            name="month"
                            onChange={(e) => handleChangePeriod(e, i)}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={period.month}
                            variant="outlined"
                          >
                            {monthOptionsList.map((option, i) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            error={Boolean(errorPeriod?.year)}
                            helperText={errorPeriod?.year}
                            fullWidth
                            label="Año"
                            name="year"
                            onChange={(e) => handleChangePeriod(e, i)}
                            required
                            select
                            SelectProps={{ native: true }}
                            value={period.year}
                            variant="outlined"
                          >
                            {yearOptionsList.map((option, i) => (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => onHandleRemovePeriod(i)}
                            endIcon={<Iconify icon="eva:trash-2-outline" />}
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container marginTop={2} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAddPeriod}
                      endIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      Añadir Periodo
                    </Button>
                  </Grid>
                  {errors?.errorMonths && (
                    <Grid item md={12} xs={12} lg={12}>
                      <FormHelperText error>{errors.errorMonths}</FormHelperText>
                    </Grid>
                  )}
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.it)}
                    helperText={errors?.it}
                    fullWidth
                    label="IT Bs."
                    name="it"
                    onChange={handleChangeNumber}
                    value={payment.it}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.iva)}
                    helperText={errors?.iva}
                    fullWidth
                    label="IVA Bs."
                    name="iva"
                    onChange={handleChangeNumber}
                    value={payment.iva}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.iue)}
                    helperText={errors?.iue}
                    fullWidth
                    label="IUE Bs."
                    name="iue"
                    onChange={handleChangeNumber}
                    value={payment.iue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.other)}
                    helperText={errors?.other}
                    fullWidth
                    label="Otro Bs."
                    name="other"
                    onChange={handleChangeNumber}
                    value={payment.other}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.other2)}
                    helperText={errors?.other2}
                    fullWidth
                    label="Otro Bs."
                    name="other2"
                    onChange={handleChangeNumber}
                    value={payment.other2}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.srv)}
                    helperText={errors?.srv}
                    fullWidth
                    label="Servicio Bs."
                    name="srv"
                    onChange={handleChangeSrv}
                    value={payment.srv}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.additionalAmount)}
                    helperText={errors?.additionalAmount}
                    fullWidth
                    label="Monto Adicional Bs."
                    name="additionalAmount"
                    onChange={handleChangeNumber}
                    value={payment.additionalAmount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.observations)}
                    fullWidth
                    helperText={errors?.observations}
                    label="Observaciones"
                    name="observations"
                    onChange={handleChange}
                    value={payment.observations}
                    variant="outlined"
                  />
                </Grid>

                {/* Other observations */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Otras Observaciones
                  </FormHelperText>
                </Grid>
                <Grid item md={12} xs={12} sx={{ marginBottom: 4 }}>
                  {payment.otherObservations.map((item, i) => {
                    const errorPeriod =
                      (errors?.otherObservations && errors.otherObservations[i]) || null;
                    return (
                      <Grid
                        key={i}
                        container
                        spacing={3}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                          marginBottom: 3
                        }}
                        paddingLeft={{
                          sm: 0,
                          md: 10
                        }}
                        paddingRight={{
                          sm: 0,
                          md: 10
                        }}
                      >
                        <Grid item md={10} xs={12}>
                          <TextField
                            error={Boolean(errorPeriod?.observation)}
                            fullWidth
                            helperText={errorPeriod?.observation}
                            label="Observación"
                            name="observation"
                            required
                            onChange={(e) => handleChangeOtherObservation(e, i)}
                            value={item.observation}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid
                          item
                          md={2}
                          xs={12}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center'
                          }}
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => onHandleRemoveOtherObservation(i)}
                            endIcon={<Iconify icon="eva:trash-2-outline" />}
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid container marginTop={2} display="flex" justifyContent="center">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onAddOtherObservation}
                      endIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      Añadir Observación
                    </Button>
                  </Grid>
                  {errors?.errorMonths && (
                    <Grid item md={12} xs={12} lg={12}>
                      <FormHelperText error>{errors.errorMonths}</FormHelperText>
                    </Grid>
                  )}
                </Grid>

                {/* Summary */}
                <Grid item md={12} xs={12}>
                  <Box>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      Resumen
                    </FormHelperText>
                  </Box>
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="A Cuenta Bs."
                    name="accountAmount"
                    onChange={null}
                    value={control.accountAmount}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Saldo Actualizado Bs."
                    name="balanceAmount"
                    onChange={null}
                    value={control.balanceAmount.toFixed(2)}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Gestiones"
                    name="years"
                    onChange={null}
                    value={control.years.map((item) => item.year).join(' - ')}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Box>
                    <FormHelperText
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 16
                      }}
                    >
                      {`Total recibido por cliente ${totalAmountReceived} Bs.`}
                    </FormHelperText>
                  </Box>
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() =>
                  navigate(previousScreen, {
                    state: {
                      id: controlId
                    }
                  })
                }
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
