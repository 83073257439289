import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';
// components
import VoucherContext from '../../context/voucher/voucherContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const previousScreen = `/dashboard/clients/vouchers/listing`;

const dialog = {
  message: `¿Estas seguro de crear un nuevo talonario?`
};

export default function CreateVoucher() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const voucherContext = useContext(VoucherContext);
  const { loading, clearData, createVoucher, errors, setLoading } = voucherContext;
  const [voucher, setVoucher] = useState({
    voucherExpiration: moment().format('YYYY-MM-DD'),
    authorizationCode: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const clientId = state?.clientId;
  const typeClient = state?.typeClient;

  useEffect(() => {
    if (clientId && typeClient) {
      setLoading(false);
    } else {
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setVoucher({
      ...voucher,
      [name]: value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      voucherExpiration: voucher.voucherExpiration,
      authorizationCode: voucher.authorizationCode
    };

    setOpenDialog(false);
    if ((await createVoucher(formData, clientId)) === true) {
      navigate(previousScreen, {
        state: {
          id: clientId,
          typeClient
        }
      });
    }
  };

  return (
    <Page title="Facturas | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: clientId, typeClient }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para crear una nuevo talonario"
            title="Crear Talonario"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.voucherExpiration)}
                    helperText={errors?.voucherExpiration}
                    fullWidth
                    label="Fecha de Vencimiento del Talonario"
                    name="voucherExpiration"
                    onChange={handleChange}
                    required
                    type="date"
                    value={voucher.voucherExpiration}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.authorizationCode)}
                    helperText={errors?.authorizationCode}
                    fullWidth
                    label="Código de Autorización del Talonario"
                    name="authorizationCode"
                    onChange={handleChange}
                    required
                    value={voucher.authorizationCode}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen, { state: { id: clientId, typeClient } })}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
