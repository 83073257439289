import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';

// components
import MunicipalityContext from '../../context/municipality/municipalityContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de actualizar el municipio?'
};

const pathPrevious = '/dashboard/configuration/departments/municipalities';

export default function UpdateMunicipality() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const municipalityContext = useContext(MunicipalityContext);
  const {
    loading,
    getMunicipality,
    updateMunicipality,
    errors,
    municipality: municipalityData,
    clearData
  } = municipalityContext;
  const [municipality, setMunicipality] = useState({
    name: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  const departmentId = state?.departmentId;
  const municipalityId = state?.id;

  useEffect(() => {
    if (departmentId && municipalityId) {
      getMunicipality(municipalityId);
    } else {
      // Go back screen
      navigate(pathPrevious);
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [departmentId, municipalityId]);

  // load municipality
  useEffect(() => {
    if (!loading && municipalityData && !errors) {
      setMunicipality({
        name: municipalityData.name || ''
      });
    }
  }, [loading, municipalityData, errors]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setMunicipality({
      ...municipality,
      [name]: value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      name: municipality.name
    };

    setOpenDialog(false);
    if ((await updateMunicipality(municipalityId, formData)) === true) {
      navigate(pathPrevious, {
        state: { departmentId }
      });
    }
  };

  return (
    <Page title="Municipios | G&G Asesor">
      <Container>
        <GoBackButton path={pathPrevious} params={{ departmentId }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar el municipio"
            title="Actualizar Municipio"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    fullWidth
                    label="Municipio"
                    name="name"
                    onChange={handleChange}
                    required
                    value={municipality.name}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() =>
                  navigate(pathPrevious, {
                    state: { departmentId }
                  })
                }
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
