import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';
// components
import VoucherContext from '../../context/voucher/voucherContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const previousScreen = `/dashboard/clients/vouchers/listing`;

export default function VoucherDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const voucherContext = useContext(VoucherContext);
  const { loading, clearData, errors, getVoucher, voucher: voucherData } = voucherContext;
  const [voucher, setVoucher] = useState({
    voucherExpiration: '',
    authorizationCode: '',
    createdAt: '',
    updatedAt: '',
    user: ''
  });
  // Id received by previous screen
  const clientId = state?.clientId;
  const typeClient = state?.typeClient;
  const voucherId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (clientId && typeClient && voucherId) {
      getVoucher(voucherId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && voucherData) {
      setVoucher({
        voucherExpiration: moment(voucherData.voucherExpiration.slice(0, 10)).format('DD/MM/YYYY'),
        authorizationCode: voucherData.authorizationCode,
        createdAt: voucherData?.createdAt
          ? moment(voucherData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        updatedAt: voucherData?.createdAt
          ? moment(voucherData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: voucherData?.user?.name || ''
      });
    }
  }, [loading, voucherData]);

  return (
    <Page title="Facturas | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: clientId, typeClient }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del talonario"
            title="Detalles del Talonario"
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Vencimiento del Talonario"
                  name="voucherExpiration"
                  onChange={null}
                  required
                  value={voucher.voucherExpiration}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Código de Autorización del Talonario"
                  name="authorizationCode"
                  onChange={null}
                  required
                  value={voucher.authorizationCode}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={voucher.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Modificación"
                  name="createdAt"
                  onChange={null}
                  required
                  value={voucher.updatedAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  required
                  value={voucher.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => navigate(previousScreen, { state: { id: clientId, typeClient } })}
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
