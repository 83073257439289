import Cookies from 'universal-cookie';

import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_AUTH_ERRORS
} from '../types';

const cookie = new Cookies();

export default (state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      cookie.set('token', action.payload.token);
      return {
        ...state,
        token: action.payload.token,
        isAuthenticated: true,
        loading: false,
        error: null
      };
    case USER_LOADED: {
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
        errors: null
      };
    }
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false,
        info: action.payload
      };
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        info: null
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false,
        info: action.payload
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errors: action.payload,
        info: null
      };
    case CLEAR_AUTH_ERRORS:
      return {
        ...state,
        errors: null,
        info: null
      };
    case AUTH_ERROR:
    case LOGOUT:
    case LOGIN_FAIL:
      cookie.remove('token');
      return {
        ...state,
        token: null,
        isAuthenticated: false,
        loading: false,
        user: null,
        errors: action.payload,
        info: null
      };
    default:
      return state;
  }
};
