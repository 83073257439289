import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthContext from '../context/auth/authContext';
import { rolesUser } from '../constants';

function PrivateClientRoute({ component: RouteComponent, ...rest }) {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user } = authContext;

  if (isAuthenticated && loading === false && user?.data?.role === rolesUser.client) {
    return <RouteComponent {...rest} />;
  }

  return <Navigate to="/404" />;
}

PrivateClientRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};

export default PrivateClientRoute;
