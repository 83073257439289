import {
  GET_FILES,
  GET_FILE,
  SET_LOADING_FILE,
  UPLOAD_FILE_SUCCESS,
  FILE_ERROR,
  CLEAR_FILE
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_FILES:
      return {
        ...state,
        files: action.payload,
        errors: null,
        loading: false
      };
    case GET_FILE:
      return {
        ...state,
        errors: null,
        file: action.payload,
        loading: false
      };
    case SET_LOADING_FILE:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case FILE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_FILE:
      return {
        ...state,
        files: null,
        file: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
