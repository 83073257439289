import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
// context
import ControlContext from '../../context/control/controlContext';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import Search from '../../components/Search';
import TablePaginationActions from '../../components/TablePaginationActions';
import CircularLoading from '../../components/CircularLoading';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Nº Control'
  },
  {
    id: 1,
    name: 'NIT'
  },
  {
    id: 2,
    name: 'Cliente'
  },
  {
    id: 3,
    name: 'Gestiones'
  },
  {
    id: 4,
    name: 'Concluido'
  },
  {
    id: 5,
    name: 'Observaciones'
  },
  {
    id: 6,
    name: ''
  },
  {
    id: 7,
    name: ''
  }
];

export default function ControlsListingClient() {
  const controlContext = useContext(ControlContext);
  const { loading, getMyControls, controls, clearData, errors } = controlContext;
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);

  useEffect(() => {
    getMyControls();

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, []);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      controls?.map((item) => ({
        id: item._id,
        controlNumber: item.controlNumber,
        years: item.years.map((y) => y.year).join(' - '),
        nit: item.client?.nit || '-',
        name: item.client?.name || item.client?.owner || '-',
        observations: item.observations || '-',
        isCompleted: item.isCompleted
      })) || [];

    if (search) {
      computedItems = computedItems.filter((comment) =>
        comment.controlNumber.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [controls, page, search, rowsPerPage]);

  const onReload = () => {
    getMyControls();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (search.trim()) {
      // Search with keyword
      getMyControls();
      return;
    }

    // Reset get initial data
    onReload();
  };

  const handleOperation = (action, id) => {
    if (action === 'kardex') {
      navigate('/dashboard/client/controls/kardex', {
        state: { id }
      });
      return;
    }

    if (action === 'details') {
      navigate('/dashboard/client/controls/details', {
        state: { id }
      });
    }
  };

  const onClearSearch = () => {
    setSearch('');
    setPage(0);
    getMyControls();
  };

  return (
    <Page title="Kardex Financiero | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Kardex Financiero
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={onReload}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Kardexs del Cliente
                </Typography>
                <form onSubmit={submitHandler}>
                  <Box
                    display="flex"
                    flexWrap="no-wrap"
                    gap={2}
                    width={{ xs: 650, md: '100%' }}
                    justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                  >
                    <Search
                      search={search}
                      setSearch={setSearch}
                      placeholder="Buscar por nº control"
                    />
                    <Button onClick={onClearSearch} variant="outlined" color="secondary">
                      Limpiar
                    </Button>
                  </Box>
                </form>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.controlNumber}
                      </TableCell>
                      <TableCell>{row.nit}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.years}</TableCell>
                      <TableCell>
                        <Label variant="ghost" color={row.isCompleted ? 'success' : 'error'}>
                          {row.isCompleted ? 'Concluido' : 'No Concluido'}
                        </Label>
                      </TableCell>
                      <TableCell>{row.observations}</TableCell>
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleOperation('kardex', row.id);
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          Ver Kardex
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={8}
                      count={controls?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>
    </Page>
  );
}
