import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';

// context
import MunicipalityContext from '../../context/municipality/municipalityContext';

// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ButtonMoreMenu from '../../components/ButtonMoreMenu';
import DialogConfirmation from '../../components/DialogConfirmation';
import GoBackButton from '../../components/GoBackButton';
import Search from '../../components/Search';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Municipio'
  },
  {
    id: 1,
    name: ''
  }
];

export default function Municipalities() {
  const { state } = useLocation();
  const municipalityContext = useContext(MunicipalityContext);
  const { loading, getMunicipalities, municipalities, deleteMunicipality, clearData, errors } =
    municipalityContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const departmentId = state?.departmentId;

  useEffect(() => {
    // Verify if id was passed
    if (departmentId) {
      getMunicipalities(departmentId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [departmentId]);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      municipalities?.map((item) => ({
        ...item,
        id: item._id,
        name: item.name
      })) || [];

    if (search) {
      computedItems = computedItems.filter((comment) =>
        comment.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [municipalities, page, rowsPerPage, search]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    getMunicipalities(departmentId);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    // Reset get initial data
    onReload();
  };

  const handleAction = (action, id, data = {}) => {
    if (action === 'update') {
      navigate(`/dashboard/configuration/departments/municipalities/update`, {
        state: { departmentId, id }
      });
    }

    if (action === 'delete') {
      const { name } = data;
      const message = `Esta seguro de forma permanente el Seguro de salud ${name}?`;
      handleOpenDialog(message, id, 'delete');
    }

    if (action === 'details') {
      navigate(`/dashboard/configuration/departments/municipalities/details`, {
        state: { departmentId, id }
      });
    }
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteMunicipality(itemRowSelected.id)) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  const onClearSearch = () => {
    setSearch('');
    getMunicipalities(departmentId);
  };

  return (
    <Page title="Configuración | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/configuration/departments/listing" />

        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Municipios
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                <Button
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate('/dashboard/configuration/departments/municipalities/create', {
                      state: { departmentId }
                    });
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Municipio
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Municipios
                </Typography>
                <form onSubmit={submitHandler}>
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                  >
                    <Search
                      search={search}
                      setSearch={setSearch}
                      placeholder="Buscar por municipio"
                    />
                    <Button onClick={onClearSearch} variant="outlined" color="secondary">
                      Limpiar
                    </Button>
                  </Box>
                </form>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell>
                        <ButtonMoreMenu id={row.id} name={row.name} handleAction={handleAction} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={5}
                      count={municipalities?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
