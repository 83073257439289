import PropTypes from 'prop-types';
import { useEffect, useContext, useState } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mocks_
import account from '../../_mocks_/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import sidebarAdminConfig from './SidebarAdminConfig';
import sidebarClientConfig from './SidebarClientConfig';
import sidebarAssistantConfig from './SidebarAssistantConfig';
import AuthContext from '../../context/auth/authContext';
import ClientContext from '../../context/client/clientContext';
import { rolesUser } from '../../constants';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const authContext = useContext(AuthContext);
  const clientContext = useContext(ClientContext);
  const { user } = authContext;
  const { enableFinancialKardexView, loading: clientLoading } = clientContext;
  const [loadingSidebar, setLoadingSidebar] = useState(false);
  const [sidebarConfig, setSidebarConfig] = useState([]); // Inicializar con un valor predeterminado
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // Load role
  useEffect(() => {
    if (user?.data && !loadingSidebar) {
      if (user?.data?.role === rolesUser.administrator) {
        setSidebarConfig(sidebarAdminConfig);
        setLoadingSidebar(true);
      } else if (user?.data?.role === rolesUser.client) {
        if (!clientLoading && enableFinancialKardexView !== undefined) {
          const clientConfig = sidebarClientConfig.filter((item) => {
            if (item.title === 'Kardex Financiero') {
              return enableFinancialKardexView;
            }
            return true;
          });
          setSidebarConfig(clientConfig);
          setLoadingSidebar(true);
        }
      } else if (user?.data?.role === rolesUser.assistant) {
        setSidebarConfig(sidebarAssistantConfig);
        setLoadingSidebar(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, enableFinancialKardexView, clientLoading]);

  const displayName = user?.data?.name || account.displayName;
  const role = user?.data?.role || account.role;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
      }}
    >
      {/* Update logo for white colors */}
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.white' }}>
                {displayName}
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.light' }}>
                {role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: 'relative' }}
        >
          <Button
            color="secondary"
            href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_NUMBER_ACCOUNT_ADVISOR}`}
            target="_blank"
            variant="contained"
          >
            WhatsApp Contador
          </Button>
        </Stack>
      </Box>
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.darker' }
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.darker',
              borderRightStyle: 'dashed'
            }
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
