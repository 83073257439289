import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, Button, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function ButtonMoreMenu({ id, name, handleAction }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<Iconify icon="eva:arrow-ios-downward-outline" width={20} height={20} />}
      >
        Opciones
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* Edit */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleAction('update', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Details */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleAction('details', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:eye-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Ver Detalles" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Delete */}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={async () => {
            handleClose();
            handleAction('delete', id, { name });
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Eliminar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

ButtonMoreMenu.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired
};
