import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import UserContext from '../../context/user/userContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import { roleOptionsList } from '../../constants';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de actualizar los datos del usuario?'
};

export default function UpdateUser() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userContext = useContext(UserContext);
  const { loading, clearData, updateUser, errors, user: userData, getUser } = userContext;
  const [user, setUser] = useState({
    name: '',
    email: '',
    role: 'auxiliar'
  });
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const userId = state?.id;
  const isClientRole = user.role === 'cliente';

  useEffect(() => {
    // Verify if id was passed
    if (userId) {
      getUser(userId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    if (!loading && userData && !errors) {
      setUser({
        name: userData.name || '',
        email: userData.email || '',
        role: userData.role || 'auxiliar'
      });
    }
  }, [loading, userData, errors]);

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      name: user.name,
      email: user.email,
      role: isClientRole ? userData?.role : user.role
    };

    setOpenDialog(false);
    if ((await updateUser(userId, formData)) === true) {
      navigate('/dashboard/users');
    }
  };

  return (
    <Page title="Usuarios | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/users" />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar el usuario"
            title="Actualizar Usuario"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    fullWidth
                    helperText={errors?.name || 'Identificación única del sistema'}
                    label="Nombre"
                    placeholder="Ej: nombre.apellido"
                    name="name"
                    onChange={handleChange}
                    required
                    value={user.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    error={Boolean(errors?.email)}
                    fullWidth
                    helperText={errors?.email}
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    value={user.email}
                    variant="outlined"
                  />
                </Grid>
                {isClientRole ? (
                  <Grid item md={6} xs={12}>
                    <TextField
                      disabled
                      fullWidth
                      helperText="Este campo no puede modificado"
                      label="Rol"
                      name="role"
                      onChange={null}
                      required
                      value={user.role}
                      variant="outlined"
                    />
                  </Grid>
                ) : (
                  <Grid item md={6} xs={12}>
                    <TextField
                      error={Boolean(errors?.role)}
                      helperText={errors?.role}
                      fullWidth
                      label="Rol"
                      name="role"
                      onChange={handleChange}
                      required
                      select
                      SelectProps={{ native: true }}
                      value={user.role}
                      variant="outlined"
                    >
                      {roleOptionsList.map((option, i) => (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                )}
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate('/dashboard/users')}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
