import axios from 'axios';

/* eslint-disable */
function getFileExtension(filePath) {
  return filePath.slice((filePath.lastIndexOf(".") - 1 >>> 0) + 2);
}
/* eslint-disable */

function getFileFromServer(url) {
  return axios.get(url, {
    responseType: 'blob'
  })
    .then((res) => res)
    .catch((err) => { throw (err) });
}

export { getFileExtension, getFileFromServer }
