import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import Cookies from 'universal-cookie';
import AuthContext from './authContext';
import authReducer from './authReducer';
import { config } from '../../config/requestConfig';
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGOUT,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CLEAR_AUTH_ERRORS
} from '../types';
import AfpContext from '../afp/afpContext';
import HealthInsuranceContext from '../healthInsurance/healthInsuranceContext';
import MunicipalityContext from '../municipality/municipalityContext';
import DepartmentContext from '../department/departmentContext';
import ClientContext from '../client/clientContext';
import CategoryContext from '../category/categoryContext';
import ServiceContext from '../service/serviceContext';
import ControlContext from '../control/controlContext';
import UserContext from '../user/userContext';
import TransactionContext from '../transaction/transactionContext';
import ReportContext from '../report/reportContext';
import VoucherContext from '../voucher/voucherContext';
import InvoiceContext from '../invoice/invoiceContext';
import FileContext from '../file/fileContext';
import ConfigurationContext from '../configuration/configurationContext';

const cookie = new Cookies();

function AuthState(props) {
  const { clearData: clearDataAfp } = useContext(AfpContext);
  const { clearData: clearDataHealthInsurance } = useContext(HealthInsuranceContext);
  const { clearData: clearDataMunicipality } = useContext(MunicipalityContext);
  const { clearData: clearDataDepartment } = useContext(DepartmentContext);
  const { clearData: clearDataClient } = useContext(ClientContext);
  const { clearData: clearDataService } = useContext(ServiceContext);
  const { clearData: clearDataCategory } = useContext(CategoryContext);
  const { clearData: clearDataControl } = useContext(ControlContext);
  const { clearData: clearDataUser } = useContext(UserContext);
  const { clearData: clearDataTransaction } = useContext(TransactionContext);
  const { clearData: clearDataReport } = useContext(ReportContext);
  const { clearData: clearDataVoucher } = useContext(VoucherContext);
  const { clearData: clearDataInvoice } = useContext(InvoiceContext);
  const { clearData: clearDataFile } = useContext(FileContext);
  const { clearData: clearDataConfiguration } = useContext(ConfigurationContext);

  const initialState = {
    token: cookie.get('token'),
    isAuthenticated: null,
    loading: true,
    user: null,
    errors: null,
    info: null
  };

  const [state, dispatch] = useReducer(authReducer, initialState);

  // Login user
  const login = async (formData) => {
    try {
      const res = await axios.post(`/api/v1/auth/login`, formData, config);

      await dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data
      });
      document.location.href = '/';
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
        payload: error?.response?.data?.errors
      });
    }
  };

  // Load User
  const loadUser = async () => {
    try {
      const res = await axios.get(`/api/v1/auth/me`);

      dispatch({
        type: USER_LOADED,
        payload: res.data
      });
    } catch (error) {
      dispatch({ type: AUTH_ERROR });
    }
  };

  // Logout
  const logout = async () => {
    try {
      await axios.get(`/api/v1/auth/logout`);

      // Clear store
      clearDataAfp();
      clearDataHealthInsurance();
      clearDataMunicipality();
      clearDataDepartment();
      clearDataClient();
      clearDataService();
      clearDataCategory();
      clearDataControl();
      clearDataUser();
      clearDataTransaction();
      clearDataReport();
      clearDataVoucher();
      clearDataInvoice();
      clearDataFile();
      clearDataConfiguration();

      dispatch({
        type: LOGOUT
      });
    } catch (error) {
      dispatch({
        type: LOGOUT,
        payload: error?.response?.data?.errors
      });
    }
  };

  // Forgot password
  const forgotPassword = async (formData) => {
    try {
      const res = await axios.post('/api/v1/auth/forgotpassword', formData, config);

      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: res.data?.data
      });
    } catch (error) {
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        payload: error?.response?.data?.errors
      });
    }
  };

  // Reset password
  const resetPassword = async (formData, token) => {
    try {
      const res = await axios.put(`/api/v1/auth/resetpassword/${token}`, formData, config);

      dispatch({
        type: RESET_PASSWORD_SUCCESS,
        payload: res.data?.data
      });
    } catch (error) {
      dispatch({
        type: RESET_PASSWORD_FAILURE,
        payload: error?.response?.data?.errors
      });
    }
  };

  // Clear errors
  const clearAuthErrors = () => {
    dispatch({
      type: CLEAR_AUTH_ERRORS
    });
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        user: state.user,
        errors: state.errors,
        info: state.info,
        login,
        loadUser,
        logout,
        forgotPassword,
        resetPassword,
        clearAuthErrors
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthState;
