import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import CategoryContext from '../../context/category/categoryContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de actualizar la categoría?'
};

const previousScreen = '/dashboard/configuration/categories';

export default function UpdateCategory() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const categoryContext = useContext(CategoryContext);
  const {
    loading,
    getCategory,
    category: categoryData,
    clearData,
    updateCategory,
    errors
  } = categoryContext;
  const [category, setCategory] = useState({
    name: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const categoryId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (categoryId) {
      getCategory(categoryId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [categoryId]);

  useEffect(() => {
    if (!loading && categoryData && !errors) {
      setCategory({
        name: categoryData.name || ''
      });
    }
  }, [loading, categoryData, errors]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setCategory({
      ...category,
      [name]: value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      name: category.name
    };

    setOpenDialog(false);
    if ((await updateCategory(categoryId, formData)) === true) {
      navigate(previousScreen);
    }
  };

  return (
    <Page title="Categorías | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar la categoría"
            title="Actualizar Categoría"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                {/* Category */}
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    fullWidth
                    placeholder="Ingrese nombre"
                    label="Nombre"
                    name="name"
                    onChange={handleChange}
                    required
                    value={category.name}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen)}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
