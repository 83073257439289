// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarClientConfig = [
  {
    title: 'Notificaciones de Saldo',
    path: '/dashboard/client/notifications/balances',
    icon: getIcon('eva:archive-fill')
  },
  {
    title: 'Kardex Financiero',
    path: '/dashboard/client/controls',
    icon: getIcon('eva:briefcase-fill'),
    children: [
      {
        title: 'Listar Kardex',
        path: '/dashboard/client/controls/listing',
        icon: getIcon('eva:briefcase-fill')
      }
    ]
  },
  {
    title: 'Mi Flujo de Caja',
    path: '/dashboard/finances',
    icon: getIcon('eva:credit-card-fill'),
    children: [
      {
        title: 'Listar Ingresos',
        path: '/dashboard/finances/transactions/listing?transactionType=Ingreso'
      },
      {
        title: 'Listar Egresos',
        path: '/dashboard/finances/transactions/listing?transactionType=Egreso'
      }
    ]
  },
  {
    title: 'Mi Reporte de Facturas',
    path: '/dashboard/client/invoices',
    icon: getIcon('eva:file-text-fill'),
    children: [
      {
        title: 'Listar Facturas',
        path: '/dashboard/client/invoices/listing'
      },
      {
        title: 'Listar Talonarios',
        path: '/dashboard/client/vouchers/listing'
      }
    ]
  },
  {
    title: 'Mis Reportes',
    path: '/dashboard/reports',
    icon: getIcon('eva:folder-fill'),
    children: [
      {
        title: 'Reporte de Facturas',
        path: '/dashboard/reports/client/invoices'
      },
      {
        title: 'Reporte Ingresos',
        path: '/dashboard/reports/transactions/incomes'
      },
      {
        title: 'Reporte Egresos',
        path: '/dashboard/reports/transactions/expenses'
      }
    ]
  },
  {
    title: 'Cliente',
    path: '/dashboard/client/profile',
    icon: getIcon('eva:person-fill'),
    children: [
      {
        title: 'Mi Perfil',
        path: '/dashboard/client/profile/details'
      },
      {
        title: 'Listar Documentos',
        path: '/dashboard/client/profile/documents/listing'
      }
    ]
  }
];

export default sidebarClientConfig;
