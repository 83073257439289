import React, { useReducer } from 'react';
import SnackbarContext from './snackbarContext';
import snackbarReducer from './snackbarReducer';

import {
  OPEN_MESSAGE_SUCCESS_SNACKBAR,
  OPEN_MESSAGE_ERROR_SNACKBAR,
  CLOSE_SNACKBAR
} from '../types';

function SnackbarState(props) {
  const initialState = {
    open: false,
    message: '',
    severity: 'info'
  };

  const [state, dispatch] = useReducer(snackbarReducer, initialState);

  const openSuccessSnackbar = () => {
    dispatch({ type: OPEN_MESSAGE_SUCCESS_SNACKBAR });
  };

  const openErrorSnackbar = () => {
    dispatch({ type: OPEN_MESSAGE_ERROR_SNACKBAR });
  };

  const closeSnackbar = () => {
    dispatch({ type: CLOSE_SNACKBAR });
  };

  const resetSnackbar = () => {
    dispatch({ type: CLOSE_SNACKBAR });
  };

  return (
    <SnackbarContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        open: state.open,
        message: state.message,
        severity: state.severity,
        openSuccessSnackbar,
        openErrorSnackbar,
        closeSnackbar,
        resetSnackbar
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </SnackbarContext.Provider>
  );
}

export default SnackbarState;
