import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';
// components
import TransactionContext from '../../context/transaction/transactionContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

export default function TransactionDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const transactionContext = useContext(TransactionContext);
  const {
    loading,
    clearData,
    getTransaction,
    transaction: transactionData,
    errors
  } = transactionContext;

  // Params send another screen
  const transactionType = state?.transactionType;
  const transactionId = state?.id;
  const [transaction, setTransaction] = useState({
    date: moment().format('YYYY-MM-DD'),
    concept: '',
    amount: 0,
    transactionType,
    createdAt: '',
    user: ''
  });
  const previousScreen = `/dashboard/finances/transactions/listing?transactionType=${transactionType}`;
  // eslint-disable-next-line
  const titleLowercase = transactionType === 'Ingreso' ? 'ingreso' : transactionType === 'Egreso' ? 'egreso' : '';

  useEffect(() => {
    if (transactionType && transactionId) {
      getTransaction(transactionId);
    } else {
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [transactionType, transactionId]);

  useEffect(() => {
    if (!loading && transactionData && !errors) {
      setTransaction({
        date: moment(transactionData.date.slice(0, 10)).format('DD/MM/YYYY'),
        concept: transactionData.concept,
        amount:
          transactionData.amount || transactionData.amount === 0
            ? transactionData.amount.toFixed(2)
            : 0,
        transactionType: transactionData.transactionType,
        createdAt: transactionData?.createdAt
          ? moment(transactionData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: transactionData?.user?.name || ''
      });
    }
  }, [loading, transactionData, errors]);

  return (
    <Page title="Mi Flujo de Caja | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader={`Información del registro del ${titleLowercase}`}
            title={`Detalles del ${transactionType}`}
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label={`Fecha de ${transactionType}`}
                  name="date"
                  onChange={null}
                  required
                  value={transaction.date}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Importe (Bs.)"
                  name="amount"
                  onChange={null}
                  required
                  value={transaction.amount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Concepto"
                  name="concept"
                  onChange={null}
                  required
                  multiline
                  rows={4}
                  value={transaction.concept}
                  variant="outlined"
                  inputProps={{
                    className: {
                      resize: 'both'
                    }
                  }}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={transaction.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  required
                  value={transaction.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button variant="outlined" type="button" onClick={() => navigate(previousScreen)}>
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
