/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  InfoOutlined as InfoOutlinedIcon,
  WarningAmberOutlined as WarningAmberOutlinedIcon
} from '@mui/icons-material/';
import { styled, useTheme } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import SnackbarContext from '../context/snackbar/snackbarContext';

const RootStyle = styled('div')(({ theme }) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}));

function CustomizedSnackbars() {
  const theme = useTheme();
  const snackbarContext = useContext(SnackbarContext);
  const { closeSnackbar, resetSnackbar, open, severity, message } = snackbarContext;

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeSnackbar();
  };

  const onCloseSnackBar = () => {
    closeSnackbar();
  };

  useEffect(() => {
    if (!open) {
      resetSnackbar()
    }
  }, [open])

  const backgroundAlert =
    severity === 'success'
      ? theme.palette.success.lighter
      : severity === 'info'
        ? theme.palette.info.lighter
        : severity === 'warning'
          ? theme.palette.warning.lighter
          : theme.palette.error.lighter;

  return (
    <RootStyle>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        // message={message}
        open={open}
        autoHideDuration={3000}
        onClose={onCloseSnackBar}
      >
        <Alert
          onClose={handleClose}
          style={{
            background: backgroundAlert,
            color: theme.palette.grey[800]
          }}
          severity={severity}
          iconMapping={{
            success: (
              <CheckCircleOutlineIcon
                fontSize="inherit"
                style={{
                  color: theme.palette.grey[800]
                }}
              />
            ),
            info: (
              <InfoOutlinedIcon
                fontSize="inherit"
                style={{
                  color: theme.palette.grey[800]
                }}
              />
            ),
            warning: (
              <InfoOutlinedIcon
                fontSize="inherit"
                style={{
                  color: theme.palette.grey[800]
                }}
              />
            ),
            error: (
              <WarningAmberOutlinedIcon
                fontSize="inherit"
                style={{
                  color: theme.palette.grey[800]
                }}
              />
            )
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </RootStyle>
  );
}

export default CustomizedSnackbars;
