import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Autocomplete,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';
import CircularLoading from '../../components/CircularLoading';

const dialog = {
  message:
    'Se fusionarán los pagos del Kardex Actual al Kardex de Destino. El registro de kardex Actual pasará a actualizar con estado de archivado (solo podrá realizar consultas de saldos). ¿Estas seguro de continuar?'
};

const previousScreen = '/dashboard/controls/listing';

const GridStyle = styled(Grid)({
  display: 'inline-flex',
  gap: 10
});

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'white'
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#f2f2f2'
  }
}));

const TypographyStyle = styled(Typography)({
  display: 'inline-block',
  fontWeight: 'bold'
});

const styles = {
  textTitleTable: {
    border: '1px solid #ddd',
    padding: '5px',
    fontWeight: 'bold'
  },
  textTable: {
    border: '1px solid #ddd',
    padding: '5px'
  }
};

export default function FusionControl() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const { loading, getControlsWithCurrentControl, controls, clearData, fusionControl, errors } =
    controlContext;
  const [fusion, setFusion] = useState({
    control: {
      controlNumber: '',
      nit: '',
      client: '',
      payments: [],
      price: 0,
      discount: 0,
      accountAmount: 0,
      balanceAmount: 0
    },
    newControl: {
      value: '0',
      label: 'Seleccione una opción',
      price: 0,
      discount: 0,
      accountAmount: 0,
      balanceAmount: 0,
      payments: []
    },
    summary: {
      price: 0,
      discount: 0,
      accountAmount: 0,
      balanceAmount: 0,
      payments: []
    }
  });
  const [controlOptionsList, setControlOptionsList] = useState([
    {
      _id: '0',
      value: '0',
      label: 'Seleccione una opción'
    }
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  // If received by previous screen
  const controlId = state?.id;

  useEffect(() => {
    if (controlId) {
      getControlsWithCurrentControl(controlId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, []);

  // Load client
  useEffect(() => {
    if (!loading && controls && controlId) {
      // Remove control current to merge
      const controlsFormatted = controls.controls?.filter((c) => c._id !== controlId);
      const itemsData = controlsFormatted.map((item) => {
        const clientName = item.client?.name || item.client?.owner;
        return {
          _id: item._id,
          value: item._id,
          label: `Nº Control ${item.controlNumber} | NIT ${item.client?.nit} | Cliente ${clientName}`
        };
      });
      setControlOptionsList([
        {
          _id: '0',
          value: '0',
          label: 'Seleccione una opción'
        },
        ...itemsData
      ]);

      // load current control option list
      if (controlId) {
        const currentControl = controls.controls?.find((item) => item._id === controlId);
        if (currentControl) {
          setFusion({
            control: {
              controlNumber: `${currentControl.controlNumber}` || '',
              nit: `${currentControl.client?.nit}` || '',
              client: `${currentControl.client?.name || currentControl.client?.owner}` || '',
              payments:
                currentControl.payments?.map((item) => ({
                  id: item._id,
                  date: moment(item.createdAt).format('DD/MM/YYYY'),
                  paymentNumber: item.paymentNumber,
                  concept: item.concept,
                  accountAmount: item.accountAmount || 0,
                  balanceAmount: item.balanceAmount || 0,
                  diffBalanceAmount: item.diffBalanceAmount || 0,
                  type: item.type,
                  observations: item.observations || ''
                })) || [],
              price: currentControl.price || 0,
              discount: currentControl.discount || 0,
              accountAmount: currentControl.accountAmount || 0,
              balanceAmount: currentControl.balanceAmount || 0
            },
            newControl: {
              value: '0',
              label: 'Seleccione una opción',
              payments: []
            },
            summary: {
              price: 0,
              discount: 0,
              accountAmount: 0,
              balanceAmount: 0,
              payments: []
            }
          });
        }
      }
    }
  }, [loading, controls, controlId]);

  // Handle a new control and sum prices balance account totals
  const handleChangeControl = async (e, data) => {
    const value = data?.value || '0';
    const label = data?.label || 'Seleccione una opción';
    const controlFounded = controls.controls?.find((c) => c._id === value);
    const paymentsControlFounded =
      controlFounded?.payments.map((item) => ({
        id: item._id,
        date: moment(item.createdAt).format('DD/MM/YYYY'),
        paymentNumber: item.paymentNumber,
        concept: item.concept,
        accountAmount: item.accountAmount || 0,
        balanceAmount: item.balanceAmount || 0,
        diffBalanceAmount: item.diffBalanceAmount || 0,
        type: item.type,
        observations: item.observations || ''
      })) || [];

    // Initial vars
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalAccountAmount = 0;
    let totalBalanceAmount = 0;
    let paymentsBuilded = [];

    // Re calculate payments
    if (controlFounded) {
      totalPrice = fusion.control.price + controlFounded.price;
      totalDiscount = fusion.control.discount + controlFounded.discount;
      totalAccountAmount = fusion.control.accountAmount + controlFounded.accountAmount;
      totalBalanceAmount = fusion.control.balanceAmount + controlFounded.balanceAmount;

      // Diff price total for each payment
      let totalBalanceAmountForPayments = totalPrice;

      // calculate diff payments control
      // payments control
      const payments1 = fusion.control.payments.map((item) => {
        if (item.type === 'Pago') {
          totalBalanceAmountForPayments -= item.accountAmount;
        }
        if (item.type === 'Descuento') {
          totalBalanceAmountForPayments -= item.accountAmount;
        }

        return {
          ...item,
          balanceAmount: totalBalanceAmountForPayments
        };
      });

      // payments new control
      const payments2 = paymentsControlFounded.map((item) => {
        if (item.type === 'Pago') {
          totalBalanceAmountForPayments -= item.accountAmount;
        }
        if (item.type === 'Descuento') {
          totalBalanceAmountForPayments -= item.accountAmount;
        }

        return {
          ...item,
          balanceAmount: totalBalanceAmountForPayments
        };
      });

      paymentsBuilded.push(...payments1);
      paymentsBuilded.push(...payments2);
      // Re orders paymentNumber and added F in observations
      paymentsBuilded = paymentsBuilded.map((item, index) => ({
        ...item,
        observations: `(F) ${item.observations}`,
        paymentNumber: index + 1
      }));
    }

    setFusion({
      ...fusion,
      newControl: {
        value,
        label,
        price: controlFounded?.price || 0,
        discount: controlFounded?.discount || 0,
        accountAmount: controlFounded?.accountAmount || 0,
        balanceAmount: controlFounded?.balanceAmount || 0,
        payments: paymentsControlFounded
      },
      summary: {
        price: totalPrice,
        discount: totalDiscount,
        accountAmount: totalAccountAmount,
        balanceAmount: totalBalanceAmount,
        payments: paymentsBuilded
      }
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      newControl: fusion.newControl.value !== '0' ? fusion.newControl.value : ''
    };

    setOpenDialog(false);
    if ((await fusionControl(controlId, formData)) === true) {
      navigate(previousScreen);
    }
  };

  return (
    <Page title="Fusionar Kardex | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para fusionar kardex"
            title="Fusionar Kardex Financiero"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                {/* Loading */}
                {loading && (
                  <Grid item md={12} xs={12}>
                    <CircularLoading height={100} />
                  </Grid>
                )}

                {/* Control to merge */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    kardex Actual
                  </FormHelperText>
                </Grid>
                <GridStyle item md={6} xs={12}>
                  <TypographyStyle>Nº Control:</TypographyStyle>
                  <Typography>{fusion.control.controlNumber}</Typography>
                </GridStyle>
                <GridStyle item md={6} xs={12}>
                  <TypographyStyle>NIT:</TypographyStyle>
                  <Typography>{fusion.control.nit}</Typography>
                </GridStyle>
                <GridStyle item md={12} xs={12}>
                  <TypographyStyle>Cliente:</TypographyStyle>
                  <Typography>{fusion.control.client}</Typography>
                </GridStyle>
                <GridStyle item md={6} xs={12}>
                  <TypographyStyle>Precio Total:</TypographyStyle>
                  <Typography>{fusion.control.price.toFixed(2)} Bs.</Typography>
                </GridStyle>
                <GridStyle item md={6} xs={12}>
                  <TypographyStyle>Descuento:</TypographyStyle>
                  <Typography>{fusion.control.discount.toFixed(2)} Bs.</Typography>
                </GridStyle>
                <GridStyle item md={6} xs={12}>
                  <TypographyStyle>A Cuenta:</TypographyStyle>
                  <Typography>{fusion.control.accountAmount.toFixed(2)} Bs.</Typography>
                </GridStyle>
                <GridStyle item md={6} xs={12}>
                  <TypographyStyle>Saldo Actualizado:</TypographyStyle>
                  <Typography>{fusion.control.balanceAmount.toFixed(2)} Bs.</Typography>
                </GridStyle>
                {/* Load table payments control to merge */}
                <Grid item md={12} xs={12}>
                  <Typography textAlign="center" fontSize={16} fontWeight="bold">
                    Lista de Pagos
                  </Typography>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                      <StyledTableRow>
                        <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                          Fecha de Pago
                        </TableCell>
                        <TableCell colSpan={3} align="center" style={styles.textTitleTable}>
                          Pagos Parciales por Servicios - Profesionales
                        </TableCell>
                        <TableCell colSpan={1} align="center" style={styles.textTitleTable}>
                          Costo - Servicios
                        </TableCell>
                        <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                          Observaciones
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell align="center" style={styles.textTitleTable}>
                          Nº Pago
                        </TableCell>
                        <TableCell align="center" style={styles.textTitleTable}>
                          Concepto y/o Detalle de Pago
                        </TableCell>
                        <TableCell align="center" style={styles.textTitleTable}>
                          Pago Parcial
                        </TableCell>
                        <TableCell align="center" style={styles.textTitleTable}>
                          Saldo
                        </TableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      {fusion.control.payments.map((item, i) => (
                        <StyledTableRow key={i}>
                          <TableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={styles.textTable}
                          >
                            {item.date}
                          </TableCell>
                          <TableCell align="center" style={styles.textTable}>
                            {item.paymentNumber}
                          </TableCell>
                          <TableCell align="left" style={styles.textTable}>
                            {item.concept}
                          </TableCell>
                          <TableCell align="center" style={styles.textTable}>
                            {item.accountAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="center" style={styles.textTable}>
                            {item.balanceAmount.toFixed(2)}
                          </TableCell>
                          <TableCell align="left" style={styles.textTable}>
                            {item.observations}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>

                {/* Control destination */}
                <Grid item md={12} xs={12}>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    kardex de Destino
                  </FormHelperText>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText="Sin opciones"
                    getOptionLabel={(option) => option.label}
                    name="newControl"
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                    onChange={(e, data) => handleChangeControl(e, data)}
                    value={fusion.newControl}
                    options={controlOptionsList}
                    fullWidth
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Kardex"
                        variant="outlined"
                        required
                        error={Boolean(errors?.newControl)}
                        helperText={errors?.newControl}
                      />
                    )}
                  />
                </Grid>
                {fusion.newControl.value !== '0' && (
                  <>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>Precio Total:</TypographyStyle>
                      <Typography>{fusion.newControl.price.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>Descuento:</TypographyStyle>
                      <Typography>{fusion.newControl.discount.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>A Cuenta:</TypographyStyle>
                      <Typography>{fusion.newControl.accountAmount.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>Saldo Actualizado:</TypographyStyle>
                      <Typography>{fusion.newControl.balanceAmount.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                  </>
                )}

                {/* Load table payments control destination */}
                {fusion.newControl.value !== '0' && (
                  <>
                    <Grid item md={12} xs={12}>
                      <Typography textAlign="center" fontSize={16} fontWeight="bold">
                        Lista de Pagos
                      </Typography>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      <Table sx={{ width: '100%' }} aria-label="simple table">
                        <TableHead>
                          <StyledTableRow>
                            <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                              Fecha de Pago
                            </TableCell>
                            <TableCell colSpan={3} align="center" style={styles.textTitleTable}>
                              Pagos Parciales por Servicios - Profesionales
                            </TableCell>
                            <TableCell colSpan={1} align="center" style={styles.textTitleTable}>
                              Costo - Servicios
                            </TableCell>
                            <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                              Observaciones
                            </TableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <TableCell align="center" style={styles.textTitleTable}>
                              Nº Pago
                            </TableCell>
                            <TableCell align="center" style={styles.textTitleTable}>
                              Concepto y/o Detalle de Pago
                            </TableCell>
                            <TableCell align="center" style={styles.textTitleTable}>
                              Pago Parcial
                            </TableCell>
                            <TableCell align="center" style={styles.textTitleTable}>
                              Saldo
                            </TableCell>
                          </StyledTableRow>
                        </TableHead>
                        <TableBody>
                          {fusion.newControl.payments.map((item, i) => (
                            <StyledTableRow key={i}>
                              <TableCell
                                component="th"
                                scope="row"
                                align="center"
                                style={styles.textTable}
                              >
                                {item.date}
                              </TableCell>
                              <TableCell align="center" style={styles.textTable}>
                                {item.paymentNumber}
                              </TableCell>
                              <TableCell align="left" style={styles.textTable}>
                                {item.concept}
                              </TableCell>
                              <TableCell align="center" style={styles.textTable}>
                                {item.accountAmount.toFixed(2)}
                              </TableCell>
                              <TableCell align="center" style={styles.textTable}>
                                {item.balanceAmount.toFixed(2)}
                              </TableCell>
                              <TableCell align="left" style={styles.textTable}>
                                {item.observations}
                              </TableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </>
                )}

                {/* Summary */}
                {fusion.newControl.value !== '0' && (
                  <>
                    <Grid item md={12} xs={12}>
                      <FormHelperText
                        sx={{
                          fontWeight: 'bold',
                          fontSize: 16
                        }}
                      >
                        Resumen
                      </FormHelperText>
                    </Grid>
                    <Grid item md={12} xs={12}>
                      {/* prettier-ignore */}
                      <Alert severity="info">A continuación se muestra la fusión de los dos kardex. Se realiza la suma de Precios, Descuentos, A Cuenta, Saldo Actualizado, por último se realiza un Re Cálculo de la lista de pagos con los nuevos datos.</Alert>
                    </Grid>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>Precio Total:</TypographyStyle>
                      <Typography>{fusion.summary.price.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>Descuento:</TypographyStyle>
                      <Typography>{fusion.summary.discount.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>A Cuenta:</TypographyStyle>
                      <Typography>{fusion.summary.accountAmount.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    <GridStyle item md={6} xs={12}>
                      <TypographyStyle>Saldo Actualizado:</TypographyStyle>
                      <Typography>{fusion.summary.balanceAmount.toFixed(2)} Bs.</Typography>
                    </GridStyle>
                    {fusion.summary.value !== '0' && (
                      <>
                        <Grid item md={12} xs={12}>
                          <Typography textAlign="center" fontSize={16} fontWeight="bold">
                            Lista de Pagos
                          </Typography>
                        </Grid>
                        <Grid item md={12} xs={12}>
                          <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                              <StyledTableRow>
                                <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                                  Fecha de Pago
                                </TableCell>
                                <TableCell colSpan={3} align="center" style={styles.textTitleTable}>
                                  Pagos Parciales por Servicios - Profesionales
                                </TableCell>
                                <TableCell colSpan={1} align="center" style={styles.textTitleTable}>
                                  Costo - Servicios
                                </TableCell>
                                <TableCell rowSpan={2} align="center" style={styles.textTitleTable}>
                                  Observaciones
                                </TableCell>
                              </StyledTableRow>
                              <StyledTableRow>
                                <TableCell align="center" style={styles.textTitleTable}>
                                  Nº Pago
                                </TableCell>
                                <TableCell align="center" style={styles.textTitleTable}>
                                  Concepto y/o Detalle de Pago
                                </TableCell>
                                <TableCell align="center" style={styles.textTitleTable}>
                                  Pago Parcial
                                </TableCell>
                                <TableCell align="center" style={styles.textTitleTable}>
                                  Saldo
                                </TableCell>
                              </StyledTableRow>
                            </TableHead>
                            <TableBody>
                              {fusion.summary.payments.map((item, i) => (
                                <StyledTableRow key={i}>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    align="center"
                                    style={styles.textTable}
                                  >
                                    {item.date}
                                  </TableCell>
                                  <TableCell align="center" style={styles.textTable}>
                                    {item.paymentNumber}
                                  </TableCell>
                                  <TableCell align="left" style={styles.textTable}>
                                    {item.concept}
                                  </TableCell>
                                  <TableCell align="center" style={styles.textTable}>
                                    {item.accountAmount.toFixed(2)}
                                  </TableCell>
                                  <TableCell align="center" style={styles.textTable}>
                                    {item.balanceAmount.toFixed(2)}
                                  </TableCell>
                                  <TableCell align="left" style={styles.textTable}>
                                    {item.observations}
                                  </TableCell>
                                </StyledTableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen)}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
