import {
  OPEN_MESSAGE_SUCCESS_SNACKBAR,
  OPEN_MESSAGE_ERROR_SNACKBAR,
  CLOSE_SNACKBAR,
  RESET_SNACKBAR
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case OPEN_MESSAGE_SUCCESS_SNACKBAR: {
      return {
        open: true,
        message: 'Operación realizada exitosamente',
        severity: 'success'
      };
    }
    case OPEN_MESSAGE_ERROR_SNACKBAR: {
      return {
        open: true,
        message: 'Ocurrio un problema al realizar la operación',
        severity: 'error'
      };
    }
    case CLOSE_SNACKBAR:
      return {
        open: false,
        message: state.message,
        severity: state.severity
      };
    case RESET_SNACKBAR:
      return {
        open: false,
        message: '',
        severity: 'info'
      };
    default:
      return state;
  }
};
