import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import CategoryContext from '../../context/category/categoryContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const previousScreen = '/dashboard/configuration/categories';

export default function CategoryDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const categoryContext = useContext(CategoryContext);
  const { loading, clearData, errors, category: categoryData, getCategory } = categoryContext;
  const [category, setCategory] = useState({
    name: '',
    createdAt: '',
    user: ''
  });
  // Id received by previous screen
  const categoryId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (categoryId) {
      getCategory(categoryId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [categoryId]);

  useEffect(() => {
    if (!loading && categoryData) {
      setCategory({
        name: categoryData.name || '',
        createdAt: categoryData?.createdAt
          ? moment(categoryData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: categoryData?.user?.name || ''
      });
    }
  }, [loading, categoryData]);

  return (
    <Page title="Categorías | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro de la categoría"
            title="Detalles de la Categoría"
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              {/* Category */}
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={null}
                  required
                  value={category.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={category.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  required
                  value={category.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button variant="outlined" type="button" onClick={() => navigate(previousScreen)}>
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
