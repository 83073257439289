import {
  GET_CATEGORIES,
  GET_CATEGORY,
  SET_LOADING_CATEGORY,
  CATEGORY_ERROR,
  CLEAR_CATEGORY
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        errors: null,
        loading: false
      };
    case GET_CATEGORY:
      return {
        ...state,
        errors: null,
        category: action.payload,
        loading: false
      };
    case SET_LOADING_CATEGORY:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case CATEGORY_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_CATEGORY:
      return {
        ...state,
        categories: null,
        category: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
