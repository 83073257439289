import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

// context
import ControlContext from '../../context/control/controlContext';
import AuthContext from '../../context/auth/authContext';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Iconify from '../../components/Iconify';
import DialogConfirmation from '../../components/DialogConfirmation';
import ControlMoreMenu from '../../components/ControlMoreMenu';
import Search from '../../components/Search';
import TablePaginationActions from '../../components/TablePaginationActions';
import CircularLoading from '../../components/CircularLoading';
import ButtonSearchFilter from '../../components/ButtonSearchFilter';
import { rolesUser } from '../../constants';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const optionBtnFilter = ['Nº Control', 'NIT'];

const columnsTable = [
  {
    id: 0,
    name: 'Nº Control'
  },
  {
    id: 1,
    name: 'NIT'
  },
  {
    id: 2,
    name: 'Cliente'
  },
  {
    id: 3,
    name: 'Gestiones'
  },
  {
    id: 4,
    name: 'Concluido'
  },
  {
    id: 5,
    name: 'Tipo de Cliente'
  },
  {
    id: 6,
    name: 'Observaciones'
  },
  {
    id: 7,
    name: ''
  },
  {
    id: 8,
    name: ''
  }
];

function TruncatedTextCell({ text, maxLength = 100 }) {
  if (text.length <= maxLength) {
    return <TableCell>{text}</TableCell>;
  }

  const truncatedText = `${text.substring(0, maxLength)}...`;
  return <TableCell>{truncatedText}</TableCell>;
}

export default function ControlsListing() {
  const controlContext = useContext(ControlContext);
  const authContext = useContext(AuthContext);
  const { loading, getControls, controls, updateIsCompleted, clearData, deleteControl, errors } =
    controlContext;
  const { user } = authContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [field, setField] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const isAdminUser = user?.data?.role === rolesUser.administrator;

  useEffect(() => {
    getControls();

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, []);

  // Get data table
  const rows = useMemo(() => {
    const computedItems =
      controls?.controls?.map((item) => ({
        id: item._id,
        controlNumber: item.controlNumber,
        years: item.years.map((y) => y.year).join(' - '),
        nit: item.client?.nit || '-',
        name: item.client?.name || item.client?.owner || '-',
        typeClient: item.client?.type,
        observations: item.observations || '-',
        isCompleted: item.isCompleted
      })) || [];

    return computedItems;
  }, [controls]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    getControls({ keyword: search, field, pageNumber: page, rowsPerPage });
  };

  const handleChangePage = (event, newPage) => {
    getControls({ keyword: search, field, pageNumber: newPage + 1, rowsPerPage });
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    getControls({
      keyword: search,
      field,
      pageNumber: page,
      rowsPerPage: parseInt(event.target.value, 10)
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (search.toString().trim()) {
      // Search with keyword
      getControls({ keyword: search, field, pageNumber: page, rowsPerPage });
      return;
    }

    // Reset get initial data
    onReload();
  };

  const handleOperation = (action, id) => {
    const control = controls?.controls?.find((c) => id === c._id);
    if (!control) {
      return;
    }

    const { controlNumber, isCompleted } = control;

    if (action === 'complete') {
      const messageDisable = `Esta acción cerrará el registro de recibos y facturación del kardex por lo que no puede agregar mas datos. Esta seguro de cerrar el kardex financiero con Nº Control "${controlNumber}"?`;

      const messageEnable = `Esta acción apertura el registro de recibos y facturación del kardex por lo que puede agregar mas datos. Esta seguro de habilitar el kardex financiero con Nº Control "${controlNumber}?"`;

      handleOpenDialog(isCompleted ? messageEnable : messageDisable, id, 'updateIsCompleted');
      return;
    }

    if (action === 'update') {
      navigate('/dashboard/controls/update', {
        state: { id }
      });
      return;
    }

    if (action === 'kardex') {
      navigate('/dashboard/controls/kardex', {
        state: { id }
      });
      return;
    }

    if (action === 'fusion') {
      navigate('/dashboard/controls/fusion', {
        state: { id }
      });
      return;
    }

    if (action === 'details') {
      navigate('/dashboard/controls/details', {
        state: { id }
      });
      return;
    }

    if (action === 'delete') {
      const message = `Esta seguro de eliminar de forma permanente el kardex financiero ${controlNumber}?`;
      handleOpenDialog(message, id, 'delete');
    }
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteControl(itemRowSelected.id)) === true) {
        onReload();
      }
    }

    if (dialog.action === 'updateIsCompleted') {
      const item = controls?.controls?.find((c) => itemRowSelected.id === c._id);
      if ((await updateIsCompleted(item._id, { isCompleted: !item.isCompleted })) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  const onClearSearch = () => {
    setSearch('');
    setField('');
    setSelectedIndex(0);
    setRowsPerPage(10);
    getControls({ pageNumber: 0, rowsPerPage: 10 });
  };

  const handleBtnSearch = (option) => {
    setPage(0);
    setRowsPerPage(10);

    if (option === 'Nº Control') {
      setField('');
      setSearch('');
      return;
    }

    if (option === 'NIT') {
      setField('nit');
      setSearch('');
    }
  };

  const handleChangeSearch = (value) => {
    // If the field is 'nit', validate that the value is an integer number
    if (field === 'nit') {
      const numberValue = parseInt(value, 10);
      if (!Number.isNaN(numberValue)) {
        // If the value is an integer number, update the state with that number
        setSearch(numberValue);
      } else if (value === '') {
        // If the value is an empty string, update the state with an empty string
        setSearch('');
      }
    } else {
      // For other fields, allow any value
      setSearch(value);
    }
  };

  return (
    <Page title="Kardex Financiero | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Kardex Financiero
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={onReload}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                {isAdminUser && (
                  <Button
                    variant="contained"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/dashboard/controls/create');
                    }}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Nuevo Kardex
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Kardex de los Clientes
                </Typography>
                <form onSubmit={submitHandler}>
                  <Box
                    display="flex"
                    gap={2}
                    justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                    alignItems="center"
                    width={{
                      sm: '100%',
                      xs: 650
                    }}
                  >
                    <Search
                      search={search}
                      setSearch={(value) => {
                        handleChangeSearch(value);
                      }}
                      placeholder="Buscar"
                    />
                    <Button type="submit" variant="contained" color="primary">
                      Buscar
                    </Button>
                    <Button onClick={onClearSearch} variant="outlined" color="secondary">
                      Limpiar
                    </Button>
                    <Typography variant="body1" paddingLeft={2}>
                      Buscar por:
                    </Typography>
                    <ButtonSearchFilter
                      options={optionBtnFilter}
                      handleBtnSearch={handleBtnSearch}
                      selectedIndex={selectedIndex}
                      setSelectedIndex={setSelectedIndex}
                    />
                  </Box>
                </form>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.controlNumber}
                      </TableCell>
                      <TableCell>{row.nit}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.years}</TableCell>
                      <TableCell>
                        <Label variant="ghost" color={row.isCompleted ? 'success' : 'error'}>
                          {row.isCompleted ? 'Concluido' : 'No Concluido'}
                        </Label>
                      </TableCell>
                      <TableCell>{row.typeClient}</TableCell>
                      <TruncatedTextCell text={row.observations} />
                      <TableCell>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            handleOperation('kardex', row.id);
                          }}
                          variant="contained"
                          color="secondary"
                        >
                          Ver Kardex
                        </Button>
                      </TableCell>
                      {isAdminUser && (
                        <TableCell>
                          <ControlMoreMenu
                            id={row.id}
                            isCompleted={row.isCompleted}
                            handleOperation={handleOperation}
                            isAdminUser={isAdminUser}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={9}
                      count={controls?.total || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}

TruncatedTextCell.propTypes = {
  text: PropTypes.string.isRequired,
  maxLength: PropTypes.number
};
