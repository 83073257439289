import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config } from '../../config/requestConfig';
import HealthInsuranceContext from './healthInsuranceContext';
import healthInsuranceReducer from './healthInsuranceReducer';
import SnackbarContext from '../snackbar/snackbarContext';

import {
  GET_HEALTH_INSURANCES,
  GET_HEALTH_INSURANCE,
  CREATE_HEALTH_INSURANCE_SUCCESS,
  UPDATE_HEALTH_INSURANCE_SUCCESS,
  DELETE_HEALTH_INSURANCE_SUCCESS,
  SET_LOADING_HEALTH_INSURANCE,
  HEALTH_INSURANCE_ERROR,
  CLEAR_HEALTH_INSURANCE
} from '../types';

function HealthInsuranceState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    healthInsurances: null,
    healthInsurance: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(healthInsuranceReducer, initialState);

  // Fetch all healthInsurances
  const getHealthInsurances = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/health-insurances`);
      dispatch({
        type: GET_HEALTH_INSURANCES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: HEALTH_INSURANCE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single healthInsurance
  const getHealthInsurance = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/health-insurances/${id}`);
      dispatch({
        type: GET_HEALTH_INSURANCE,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: HEALTH_INSURANCE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new healthInsurance
  const createHealthInsurance = async (formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/health-insurances`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: CREATE_HEALTH_INSURANCE_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: HEALTH_INSURANCE_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update healthInsurance
  const updateHealthInsurance = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/health-insurances/${id}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: UPDATE_HEALTH_INSURANCE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: HEALTH_INSURANCE_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete healthInsurance
  const deleteHealthInsurance = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/health-insurances/${id}`, config);
      openSuccessSnackbar();
      dispatch({
        type: DELETE_HEALTH_INSURANCE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: HEALTH_INSURANCE_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_HEALTH_INSURANCE,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_HEALTH_INSURANCE
    });
  };

  return (
    <HealthInsuranceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        healthInsurances: state.healthInsurances,
        healthInsurance: state.healthInsurance,
        errors: state.errors,
        loading: state.loading,
        getHealthInsurances,
        getHealthInsurance,
        createHealthInsurance,
        updateHealthInsurance,
        deleteHealthInsurance,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </HealthInsuranceContext.Provider>
  );
}

export default HealthInsuranceState;
