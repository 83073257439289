// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarAdminConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill')
  },
  {
    title: 'usuarios',
    path: '/dashboard/users',
    icon: getIcon('eva:people-fill')
  },
  {
    title: 'clientes',
    path: '/dashboard/clients',
    icon: getIcon('eva:archive-fill'),
    children: [
      {
        title: 'Personas Jurídicas SRL',
        path: '/dashboard/clients/listing?typeClient=Personas%20Juridicas%20SRL',
        icon: getIcon('eva:briefcase-fill')
      },
      {
        title: 'Empresas Unipersonales',
        path: '/dashboard/clients/listing?typeClient=Empresas%20Unipersonales',
        icon: getIcon('eva:briefcase-fill')
      },
      {
        title: 'Prof. Independientes',
        path: '/dashboard/clients/listing?typeClient=Profesionales%20Independientes',
        icon: getIcon('eva:briefcase-fill')
      },
      {
        title: 'Regímenes Especiales',
        path: '/dashboard/clients/listing?typeClient=Regimenes%20Especiales',
        icon: getIcon('eva:briefcase-fill')
      }
    ]
  },
  {
    title: 'Kardex Financiero',
    path: '/dashboard/controls',
    icon: getIcon('eva:briefcase-fill'),
    children: [
      {
        title: 'Listar Kardex',
        path: '/dashboard/controls/listing',
        icon: getIcon('eva:briefcase-fill')
      }
    ]
  },
  {
    title: 'Mi Flujo de Caja',
    path: '/dashboard/finances',
    icon: getIcon('eva:credit-card-fill'),
    children: [
      {
        title: 'Listar Ingresos',
        path: '/dashboard/finances/transactions/listing?transactionType=Ingreso'
      },
      {
        title: 'Listar Egresos',
        path: '/dashboard/finances/transactions/listing?transactionType=Egreso'
      }
    ]
  },
  {
    title: 'Reportes',
    path: '/dashboard/reports',
    icon: getIcon('eva:folder-fill'),
    children: [
      {
        title: 'Reporte de Facturas',
        path: '/dashboard/reports/invoices'
      },
      {
        title: 'Reporte Ingresos',
        path: '/dashboard/reports/transactions/incomes'
      },
      {
        title: 'Reporte Egresos',
        path: '/dashboard/reports/transactions/expenses'
      }
    ]
  },
  {
    title: 'Configuración',
    path: '/dashboard/configuration',
    icon: getIcon('eva:shield-fill'),
    children: [
      {
        title: 'Servicios-Tarifas',
        path: '/dashboard/configuration/services'
      },
      {
        title: 'Categorias',
        path: '/dashboard/configuration/categories'
      },
      {
        title: 'Seguros de Salud',
        path: '/dashboard/configuration/health-insurances'
      },
      {
        title: 'Departamentos',
        path: '/dashboard/configuration/departments/listing'
      },
      {
        title: 'Archivos del Sistema',
        path: '/dashboard/configuration/files'
      },
      {
        title: 'Configurar QR en Recibos',
        path: '/dashboard/configuration/qr-payment'
      }
    ]
  }
];

export default sidebarAdminConfig;
