export const printFile = (blob) => {
  const blobURL = URL.createObjectURL(blob);
  const iframe = document.createElement('iframe');
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = () => {
    setTimeout(() => {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
};
