import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const previousScreen = '/dashboard/controls/listing';

export default function ControlDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const controlContext = useContext(ControlContext);
  const { loading, clearData, errors, control: controlData, getControl } = controlContext;
  const [control, setControl] = useState({
    controlNumber: '',
    createdAt: '',
    client: {
      name: '',
      nit: ''
    },
    typeOfTaxPayer: '',
    dueTaxDate: '',
    billingModality: '',
    service: '',
    years: '',
    price: '',
    discount: '',
    accountAmount: '',
    balanceAmount: '',
    observations: '',
    isCompleted: '',
    user: ''
  });
  // If received by previous screen
  const controlId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (controlId) {
      getControl(controlId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [controlId, previousScreen]);

  useEffect(() => {
    if (!loading && controlData) {
      const dateFormatted = controlData.createdAt
        ? moment(controlData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
        : '';

      setControl({
        controlNumber: controlData.controlNumber || '',
        createdAt: dateFormatted,
        client: {
          name: controlData.client?.name || controlData.client?.owner || '-',
          nit: controlData.client?.nit || '-'
        },
        service: controlData.service?.concept || '-',
        years: controlData.years ? controlData.years.map((item) => item.year).join(' - ') : '',
        price: controlData.price || controlData.price === 0 ? controlData.price.toFixed(2) : 0,
        discount:
          controlData.discount || controlData.discount === 0 ? controlData.discount.toFixed(2) : 0,
        accountAmount:
          controlData.accountAmount || controlData.accountAmount === 0
            ? controlData.accountAmount.toFixed(2)
            : 0,
        balanceAmount:
          controlData.balanceAmount || controlData.balanceAmount === 0
            ? controlData.balanceAmount.toFixed(2)
            : 0,
        dueTaxDate: controlData.dueTaxDate || '',
        owner: controlData.client?.name || '-',
        billingModality: controlData.billingModality || '',
        typeOfTaxPayer: controlData.typeOfTaxPayer || '',
        observations: controlData.observations || '',
        isCompleted: controlData?.isCompleted ? 'Concluido' : 'No Concluido',
        user: controlData?.user?.name || '-'
      });
    }
  }, [loading, controlData]);

  return (
    <Page title="Kardex Financiero | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />

        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del kardex financiero"
            title="Detalles del Kardex Financiero"
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  helperText="Identificador único"
                  label="Nº Control"
                  name="controlNumber"
                  onChange={null}
                  value={control.controlNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Cliente"
                  name="client"
                  onChange={null}
                  value={control.client.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="NIT"
                  name="nit"
                  onChange={null}
                  value={control.client.nit}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Gestiones"
                  name="years"
                  onChange={null}
                  value={control.years}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Tipo de Contribuyente"
                  name="typeOfTaxPayer"
                  onChange={null}
                  value={control.typeOfTaxPayer}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de cierre de E.E.F.F."
                  name="dueTaxDate"
                  onChange={null}
                  value={control.dueTaxDate}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Modalidad de Facturación"
                  name="billingModality"
                  onChange={null}
                  value={control.billingModality}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Servicio"
                  name="service"
                  onChange={null}
                  value={control.service}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Precio Total (Bs)"
                  name="price"
                  onChange={null}
                  value={control.price}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Descuento (Bs.)"
                  name="discount"
                  onChange={null}
                  value={control.discount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Cuenta (Bs.)"
                  name="accountAmount"
                  onChange={null}
                  value={control.accountAmount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Saldo Actualizado (Bs.)"
                  name="balanceAmount"
                  onChange={null}
                  value={control.balanceAmount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observations"
                  multiline
                  onChange={null}
                  value={control.observations}
                  rows={4}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Concluido"
                  name="isCompleted"
                  onChange={null}
                  value={control.isCompleted}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  value={control.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  value={control.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button variant="outlined" type="button" onClick={() => navigate(previousScreen)}>
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
