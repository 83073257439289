import {
  GET_CONTROLS,
  GET_CONTROL,
  GET_PAYMENTS_CONTROL,
  GET_PAYMENT_CONTROL,
  SET_LOADING_CONTROL,
  GET_DISCOUNT_CONTROL,
  GET_SERVICE_CONTROL,
  GET_BALANCE_NOTIFICATION_CONTROL,
  GET_BALANCES_NOTIFICATION_CLIENT,
  SUCCESS_CONTROL_FILE,
  SUCCESS_INVOICE_PAYMENT_FILE,
  CONTROL_ERROR,
  CLEAR_PAYMENTS,
  CLEAR_CONTROL
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CONTROLS:
      return {
        ...state,
        controls: action.payload,
        errors: null,
        loading: false
      };
    case GET_CONTROL:
      return {
        ...state,
        errors: null,
        control: action.payload,
        loading: false
      };
    case GET_PAYMENTS_CONTROL:
      return {
        ...state,
        payments: action.payload,
        errors: null,
        loading: false
      };
    case GET_PAYMENT_CONTROL:
    case GET_DISCOUNT_CONTROL:
    case GET_SERVICE_CONTROL:
      return {
        ...state,
        payment: action.payload,
        errors: null,
        loading: false
      };
    case GET_BALANCE_NOTIFICATION_CONTROL:
      return {
        ...state,
        notification: action.payload,
        errors: null,
        loading: false
      };
    case GET_BALANCES_NOTIFICATION_CLIENT:
      return {
        ...state,
        notifications: action.payload,
        errors: null,
        loading: false
      };
    case SET_LOADING_CONTROL:
      return {
        ...state,
        loading: action.payload
      };
    case SUCCESS_CONTROL_FILE:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case SUCCESS_INVOICE_PAYMENT_FILE:
      return {
        ...state,
        loading: false,
        errors: null
      };
    case CONTROL_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_PAYMENTS:
      return {
        ...state,
        payments: null,
        payment: null,
        loading: false,
        errors: null
      };
    case CLEAR_CONTROL:
      return {
        controls: null,
        control: null,
        payments: null,
        payment: null,
        notifications: null,
        notification: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
