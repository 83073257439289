import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button
} from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Iconify from './Iconify';

DialogConfirmation.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  dialog: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
};

export default function DialogConfirmation({
  openDialog,
  handleCloseDialog,
  dialog,
  onSubmit,
  loading
}) {
  useEffect(() => {
    const keyEnter = (event) => {
      if (event.key === 'Enter' && openDialog) {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
    // eslint-disable-next-line
  }, [openDialog]);

  return (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        Confirmar Acción
        <IconButton onClick={handleCloseDialog}>
          <Iconify icon="eva:close-circle-outline" width={24} height={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{dialog.message}</DialogContentText>
        <DialogActions>
          <Button
            sx={{
              mr: 2
            }}
            variant="outlined"
            type="button"
            onClick={handleCloseDialog}
          >
            Cancelar
          </Button>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            type="button"
            onClick={onSubmit}
          >
            Aceptar
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
