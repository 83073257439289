import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import MunicipalityContext from '../../context/municipality/municipalityContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const pathPrevious = '/dashboard/configuration/departments/municipalities';

export default function MunicipalityDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const municipalityContext = useContext(MunicipalityContext);
  const {
    loading,
    clearData,
    errors,
    municipality: municipalityData,
    getMunicipality
  } = municipalityContext;
  const [municipality, setMunicipality] = useState({
    name: '',
    createdAt: '',
    user: ''
  });
  // Id received by previous screen
  const departmentId = state?.departmentId;
  const municipalityId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (departmentId && municipalityId) {
      getMunicipality(municipalityId);
    } else {
      // Go back screen
      navigate(pathPrevious);
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [departmentId, municipalityId]);

  useEffect(() => {
    if (!loading && municipalityData) {
      setMunicipality({
        name: municipalityData.name || '',
        createdAt: municipalityData?.createdAt
          ? moment(municipalityData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: municipalityData?.user?.name || ''
      });
    }
  }, [loading, municipalityData]);

  return (
    <Page title="Municipios | G&G Asesor">
      <Container>
        <GoBackButton path={pathPrevious} params={{ departmentId }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del municipio"
            title="Detalles del Municipio"
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre"
                  name="name"
                  onChange={null}
                  required
                  value={municipality.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={municipality.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  required
                  value={municipality.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="outlined"
              type="button"
              onClick={() =>
                navigate(pathPrevious, {
                  state: { departmentId }
                })
              }
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
