import { GET_AFPS, SET_LOADING_AFP, AFP_ERROR, CLEAR_AFP } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_AFPS:
      return {
        ...state,
        afps: action.payload,
        errors: null,
        loading: false
      };
    case SET_LOADING_AFP:
      return {
        ...state,
        loading: true,
        errors: null
      };
    case AFP_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_AFP:
      return {
        ...state,
        afps: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
