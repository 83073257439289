import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import AuthContext from '../context/auth/authContext';

function PublicRoute({ component: RouteComponent, ...rest }) {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, loadUser } = authContext;

  useEffect(() => {
    if (!isAuthenticated) {
      loadUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated && loading === false) {
    return <Navigate to="/dashboard" />;
  }

  if (!isAuthenticated && !loading) {
    return <RouteComponent {...rest} />;
  }

  return null;
}

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};

export default PublicRoute;
