// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const sidebarAssistantConfig = [
  {
    title: 'Inicio',
    path: '/dashboard/home',
    icon: getIcon('eva:home-fill')
  },
  {
    title: 'Kardex Financiero',
    path: '/dashboard/controls',
    icon: getIcon('eva:briefcase-fill'),
    children: [
      {
        title: 'Listar Kardex',
        path: '/dashboard/controls/listing',
        icon: getIcon('eva:briefcase-fill')
      }
    ]
  },
  {
    title: 'Mi Flujo de Caja',
    path: '/dashboard/finances',
    icon: getIcon('eva:credit-card-fill'),
    children: [
      {
        title: 'Listar Ingresos',
        path: '/dashboard/finances/transactions/listing?transactionType=Ingreso'
      },
      {
        title: 'Listar Egresos',
        path: '/dashboard/finances/transactions/listing?transactionType=Egreso'
      }
    ]
  },
  {
    title: 'Mis Reportes',
    path: '/dashboard/reports',
    icon: getIcon('eva:folder-fill'),
    children: [
      {
        title: 'Reporte Ingresos',
        path: '/dashboard/reports/transactions/incomes'
      },
      {
        title: 'Reporte Egresos',
        path: '/dashboard/reports/transactions/expenses'
      }
    ]
  }
];

export default sidebarAssistantConfig;
