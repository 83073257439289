export const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const configMultiPart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
};
