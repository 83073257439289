import {
  GET_SERVICES,
  GET_SERVICE,
  SET_LOADING_SERVICE,
  SERVICE_ERROR,
  CLEAR_SERVICE
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
        errors: null,
        loading: false
      };
    case GET_SERVICE:
      return {
        ...state,
        errors: null,
        service: action.payload,
        loading: false
      };
    case SET_LOADING_SERVICE:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case SERVICE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_SERVICE:
      return {
        services: null,
        service: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
