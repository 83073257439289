import React, { useEffect, useContext, useState, useMemo } from 'react';
import moment from 'moment';
// material
import {
  Card,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { saveAs } from 'file-saver';
import { write, utils } from 'xlsx';
// components
import ReportContext from '../../context/report/reportContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import TablePaginationActions from '../../components/TablePaginationActions';
import CircularLoading from '../../components/CircularLoading';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Fecha de Egreso'
  },
  {
    id: 1,
    name: 'Concepto'
  },
  {
    id: 2,
    name: 'Importe (Bs.)'
  },
  {
    id: 3,
    name: 'Tipo de Transacción'
  },
  {
    id: 4,
    name: 'Usuario'
  }
];

export default function ExpensesTransactionReport() {
  const reportContext = useContext(ReportContext);
  const {
    loading: loadingTransaction,
    errors,
    getTransactionsReport,
    clearData: clearTransactionData,
    setLoading,
    transactions
  } = reportContext;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [report, setReport] = useState({
    dateBegin: moment().format('YYYY-MM-DD'),
    dateEnd: moment().format('YYYY-MM-DD'),
    transactionType: 'Egreso'
  });
  const [totalExpensesAmount, setTotalExpensesAmount] = useState(0);

  useEffect(() => {
    setLoading(false);

    return () => clearTransactionData();
    // eslint-disable-next-line
  }, []);

  // Get data table
  // prettier-ignore
  const rows = useMemo(() => {
    let computedItems = transactions?.map((item) => ({
      id: item._id,
      date: moment(item.date.slice(0, 10)).format('DD/MM/YYYY'),
      concept: item.concept,
      amount: item.amount,
      transactionType: item.transactionType,
      provider: item.provider?.name || '-',
      createdAt: moment(item.createdAt).format('DD/MM/YYYY [a las] HH:mm'),
      user: item.user?.name || '-'
    })) || [];

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    // Sum total incomes
    const totalAmount = computedItems.reduce((prev, cur) => prev + cur.amount, 0);
    setTotalExpensesAmount(totalAmount)

    return computedItems;
  }, [transactions, page, rowsPerPage]);

  const handleChange = (e) => {
    setReport({
      ...report,
      [e.target.name]: e.target.value
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const formData = {
      dateBegin: report.dateBegin,
      dateEnd: report.dateEnd,
      transactionType: report.transactionType
    };

    getTransactionsReport({
      dateBegin: formData.dateBegin,
      dateEnd: formData.dateEnd,
      transactionType: formData.transactionType
    });
  };

  const onDownloadButton = () => {
    // Download file xlsx
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    // Generate name
    const fileName = `reporte-egresos-${moment().format('DD/MM/YYYY')}`;

    // Generate items
    // prettier-ignore
    const items = rows.map((item) => ({
      'Fecha de Egreso': item.date,
      'Concepto': item.concept,
      'Importe': item.amount,
      "Tipo": item.transactionType,
      'Fecha de Registro': item.createdAt,
      'Registrado por Usuario': item.user
    }));

    // Convert to xlsx
    const ws = utils.json_to_sheet(items);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, {
      bookType: 'xlsx',
      type: 'array'
    });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };

  const handleClearInputForm = () => {
    // clear data and store
    setReport({
      dateBegin: moment().format('YYYY-MM-DD'),
      dateEnd: moment().format('YYYY-MM-DD'),
      transactionType: report.transactionType
    });
    // reset store
    clearTransactionData();
    setLoading(false);
  };

  return (
    <Page title="Mis Reportes | G&G Asesor">
      <Container>
        <Card>
          <CardHeader
            subheader="Ingrese los datos en el formulario para realizar una consulta"
            title="Reporte de Egresos"
          />
          <Divider />
          <form onSubmit={onSubmit}>
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Fecha desde"
                    name="dateBegin"
                    onChange={handleChange}
                    required
                    value={report.dateBegin}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Fecha hasta"
                    name="dateEnd"
                    onChange={handleChange}
                    required
                    value={report.dateEnd}
                    variant="outlined"
                    type="date"
                  />
                </Grid>
                {/* Error */}
                {errors?.error && (
                  <Grid item md={12} xs={12}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              width="100%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
              p={3}
            >
              <Button
                variant="outlined"
                type="button"
                onClick={handleClearInputForm}
                startIcon={<Iconify icon="eva:trash-2-outline" />}
              >
                Limpiar
              </Button>
              <Button
                disabled={loadingTransaction}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Consultar
              </Button>
              <Button
                color="secondary"
                variant="contained"
                type="button"
                startIcon={<Iconify icon="eva:download-fill" />}
                onClick={onDownloadButton}
              >
                Descargar Datos
              </Button>
            </Box>
          </form>
          <Divider />

          {/* Loading */}
          {loadingTransaction && <CircularLoading height={100} />}

          {/* Table */}
          <TableContainer>
            <Box padding={3}>
              <Typography variant="h6" gutterBottom>
                Lista de Egresos
              </Typography>
            </Box>
            <TableStyle aria-label="simple table">
              <TableHead>
                <TableRow>
                  {columnsTable.map((item) => (
                    <TableCell key={item.id}>{item.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell component="th" scope="row">
                      {row.date}
                    </TableCell>
                    <TableCell>{row.concept}</TableCell>
                    <TableCell>{row.amount.toFixed(2)}</TableCell>
                    <TableCell>{row.transactionType}</TableCell>
                    <TableCell>{row.user}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                    colSpan={5}
                    count={transactions?.length || 0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="Filas por página"
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count }) =>
                      `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                    }
                  />
                </TableRow>
              </TableFooter>
            </TableStyle>
          </TableContainer>

          {/* Sum total */}
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <Box>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    {`Total Egresos: ${totalExpensesAmount.toFixed(2)} Bs.`}
                  </FormHelperText>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
