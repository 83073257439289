import * as Yup from 'yup';
import { useEffect, useContext } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { styled } from '@mui/material/styles';
// material
import { Link, Stack, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import AuthContext from '../../../context/auth/authContext';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  color: theme.palette.error.main
}));

export default function ForgotPasswordForm() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { forgotPassword, info, loading, errors: errorsAuth, clearAuthErrors, user } = authContext;

  // Check if user has been authenticated
  useEffect(() => {
    if (!loading && user && user.data) {
      navigate('/dashboard/app', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Unmount component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearAuthErrors(), []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('El email debe ser una dirección de correo electrónico válida')
      .required('Email is requerido')
  });

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      await forgotPassword(formik.values);
    }
  });

  const {
    errors,
    touched,
    // values,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  return (
    <RootStyle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Stack>

          {/* Info */}
          {info && (
            <Typography
              variant="body2"
              component="div"
              color="info.main"
              align="center"
              sx={{
                mt: 3
              }}
            >
              {info}
            </Typography>
          )}

          {/* General error */}
          {errorsAuth && errorsAuth.error && (
            <Typography
              variant="body2"
              component="div"
              align="center"
              sx={{
                mt: 3
              }}
            >
              {errorsAuth.error}
            </Typography>
          )}

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Typography variant="body2" align="center" color="primary">
              Ya tienes una cuenta?&nbsp;
              <Link variant="subtitle2" component={RouterLink} to="/login" underline="hover">
                Inicia Sesión
              </Link>
            </Typography>
          </Stack>

          <LoadingButton
            disabled={Boolean(info)}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Enviar Email
          </LoadingButton>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
