import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config } from '../../config/requestConfig';
import ClientContext from './clientContext';
import clientReducer from './clientReducer';
import SnackbarContext from '../snackbar/snackbarContext';
import {
  GET_CLIENTS,
  GET_CLIENT,
  GET_DOCUMENTS_CLIENT,
  GET_DOCUMENT_CLIENT,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  CREATE_RECORD_FILE_CLIENT_SUCCESS,
  SET_LOADING_CLIENT,
  CLIENT_ERROR,
  ADD_DOCUMENT_CLIENT_SUCCESS,
  UPDATE_DOCUMENT_CLIENT_SUCCESS,
  DELETE_DOCUMENT_CLIENT_SUCCESS,
  UPLOAD_FILE_CLIENT_SUCCESS,
  GET_MY_PROFILE_CLIENT,
  CLEAR_CLIENT,
  GET_CLIENT_FINANCIAL_KARDEX_VIEW,
  UPDATE_CLIENT_FINANCIAL_KARDEX_VIEW
} from '../types';
import { printFile } from '../../utils/printFile';

function ClientState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    clients: null,
    client: null,
    documents: null,
    document: null,
    enableFinancialKardexView: false,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(clientReducer, initialState);

  // Fetch clients
  const getClients = async ({
    keyword = '',
    type = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/clients?keyword=${keyword}&type=${type}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_CLIENTS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single client
  const getClient = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/clients/${id}`);
      dispatch({
        type: GET_CLIENT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new client
  const createClient = async (formData) => {
    try {
      setLoading(true);
      // Create
      const res = await axios.post(`/api/v1/clients`, formData, config);
      openSuccessSnackbar();
      // Get id created
      const id = res.data?.data?._id;
      // Fetch report
      if (id) {
        createClientRecordFile(id, {
          password: formData.userClient.password
        });
      }
      dispatch({
        type: CREATE_CLIENT_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update client
  const updateClient = async (id, formData) => {
    try {
      setLoading(true);
      const res = await axios.put(`/api/v1/clients/${id}`, formData, config);
      openSuccessSnackbar();
      // Get id item updated
      const clientId = res.data?.data?._id;
      // Fetch report
      if (clientId) {
        createClientRecordFile(clientId, {
          password: formData.userClient.password
        });
      }
      dispatch({
        type: UPDATE_CLIENT_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete client
  const deleteClient = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/clients/${id}`, config);
      openSuccessSnackbar();
      dispatch({
        type: DELETE_CLIENT_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Create client file record
  const createClientRecordFile = async (id, formData) => {
    try {
      setLoading(true);
      // Create file
      await axios.post(`/api/v1/reports/clients/${id}/record`, formData, config);
      // Get file and download
      const resFile = await axios.get(`/api/v1/reports/record.pdf`, {
        responseType: 'blob'
      });
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      // saveAs(pdfBlob, `cliente-${Date.now()}.pdf`);
      printFile(pdfBlob);
      dispatch({
        type: CREATE_RECORD_FILE_CLIENT_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: CLIENT_ERROR,
        payload: error.response?.data?.errors
      });
    }
  };

  // Fetch documents of clients
  const getDocuments = async (clientId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/clients/${clientId}/documents`);
      dispatch({
        type: GET_DOCUMENTS_CLIENT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch document of client
  const getDocument = async (clientId, documentId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/clients/${clientId}/documents/${documentId}`);
      dispatch({
        type: GET_DOCUMENT_CLIENT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Add document of client
  const addDocument = async (clientId, formData, formFile) => {
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/clients/${clientId}/documents`, formData, config);
      const documentId = res.data.data?._id;

      // If file was input
      if (typeof formFile.get(`file`) === 'object' && formFile.get(`file`) !== 'undefined') {
        await uploadDocument(documentId, formFile);
      }
      dispatch({
        type: ADD_DOCUMENT_CLIENT_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update document of client
  const updateDocument = async (clientId, documentId, formData, formFile) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/clients/${clientId}/documents/${documentId}`, formData, config);

      // If file was input
      if (typeof formFile.get(`file`) === 'object' && formFile.get(`file`) !== 'undefined') {
        await uploadDocument(documentId, formFile);
      }

      openSuccessSnackbar();
      dispatch({
        type: UPDATE_DOCUMENT_CLIENT_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Update document of client
  const deleteDocument = async (clientId, documentId) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/clients/${clientId}/documents/${documentId}`);
      openSuccessSnackbar();
      dispatch({
        type: DELETE_DOCUMENT_CLIENT_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Upload document of client
  const uploadDocument = async (documentId, formFile) => {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    };
    try {
      setLoading(true);
      await axios.post(`/api/v1/uploads/documents/${documentId}`, formFile, config);
      dispatch({
        type: UPLOAD_FILE_CLIENT_SUCCESS
      });
      openSuccessSnackbar();
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: error.response.data.errors
      });
    }
  };

  // Fetch my client details
  const getMyProfileClient = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/clients/me`);
      dispatch({
        type: GET_MY_PROFILE_CLIENT,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Get client's enableFinancialKardexView
  const getClientFinancialKardexView = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/clients/financialKardexView`);
      dispatch({
        type: GET_CLIENT_FINANCIAL_KARDEX_VIEW,
        payload: res.data.data.enableFinancialKardexView
      });
    } catch (err) {
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Update client's financial kardex view
  const updateClientFinancialKardexView = async (id) => {
    try {
      setLoading(true);
      const res = await axios.put(`/api/v1/clients/${id}/financialKardexView`, {}, config);
      openSuccessSnackbar();
      dispatch({
        type: UPDATE_CLIENT_FINANCIAL_KARDEX_VIEW,
        payload: res.data.data.enableFinancialKardexView
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CLIENT_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_CLIENT,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_CLIENT
    });
  };

  return (
    <ClientContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        clients: state.clients,
        client: state.client,
        documents: state.documents,
        document: state.document,
        enableFinancialKardexView: state.enableFinancialKardexView,
        errors: state.errors,
        loading: state.loading,
        setLoading,
        getClients,
        getClient,
        createClient,
        updateClient,
        deleteClient,
        createClientRecordFile,
        getDocuments,
        getDocument,
        addDocument,
        updateDocument,
        deleteDocument,
        getMyProfileClient,
        getClientFinancialKardexView,
        updateClientFinancialKardexView,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </ClientContext.Provider>
  );
}

export default ClientState;
