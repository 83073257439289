import {
  GET_QR_CONFIGURATION,
  GET_QR_ID_CONFIGURATION,
  CREATE_QR_CONFIGURATION_SUCCESS,
  UPDATE_QR_CONFIGURATION_SUCCESS,
  SET_LOADING_QR_CONFIGURATION,
  CONFIGURATION_ERROR,
  CLEAR_CONFIGURATION
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_QR_CONFIGURATION:
      return {
        ...state,
        errors: null,
        qrConfiguration: action.payload,
        loadingQRConfiguration: false
      };
    case SET_LOADING_QR_CONFIGURATION:
      return {
        ...state,
        loadingQRConfiguration: action.payload,
        errors: null
      };
    case CREATE_QR_CONFIGURATION_SUCCESS:
    case UPDATE_QR_CONFIGURATION_SUCCESS:
      return {
        qrConfiguration: action.payload,
        loadingQRConfiguration: false,
        errors: null
      };
    case GET_QR_ID_CONFIGURATION:
      return {
        ...state,
        loadingQRConfiguration: false,
        errors: null
      };
    case CONFIGURATION_ERROR:
      return {
        ...state,
        errors: action.payload,
        loadingQRConfiguration: false
      };
    case CLEAR_CONFIGURATION:
      return {
        qrConfiguration: null,
        loadingQRConfiguration: true,
        errors: null
      };
    default:
      return state;
  }
};
