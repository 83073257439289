import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
// routes
import PrivateRoute from './routes/PrivateRoute';
import PrivateAdminRoute from './routes/PrivateAdminRoute';
import PrivateAdminOrAssistantRoute from './routes/PrivateAdminOrAssistantRoute';
import PrivateClientRoute from './routes/PrivateClientRoute';
import PublicRoute from './routes/PublicRoute';
import PrivateNavigateRoute from './routes/PrivateNavigateRoute';
// pages
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import DashboardApp from './pages/DashboardApp';
import Home from './pages/Home';
import NotFound from './pages/Page404';
import UsersListing from './pages/Users/UsersListing';
import UpdateUser from './pages/Users/UpdateUser';
import CreateUser from './pages/Users/CreateUser';
import UpdateAuthUser from './pages/Users/UpdateUserAuth';
import UserDetails from './pages/Users/UserDetails';
import DepartmentsListing from './pages/departments/DepartmentsListing';
import MunicipalitiesListing from './pages/departments/MunicipalitiesListing';
import CreateMunicipality from './pages/departments/CreateMunicipality';
import UpdateMunicipality from './pages/departments/UpdateMunicipality';
import MunicipalityDetails from './pages/departments/MunicipalityDetails';
import ServicesListing from './pages/services/ServicesListing';
import CreateService from './pages/services/CreateService';
import UpdateService from './pages/services/UpdateService';
import ServiceDetails from './pages/services/ServiceDetails';
import CategoriesListing from './pages/categories/CategoriesListing';
import CreateCategory from './pages/categories/CreateCategory';
import UpdateCategory from './pages/categories/UpdateCategory';
import CategoryDetails from './pages/categories/CategoryDetails';
import HealthInsurancesListing from './pages/healthInsurances/HealthInsurancesListing';
import CreateHealthInsurance from './pages/healthInsurances/CreateHealthInsurance';
import UpdateHealthInsurance from './pages/healthInsurances/UpdateHealthInsurance';
import HealthInsuranceDetails from './pages/healthInsurances/HealthInsuranceDetails';

// Clients
import IndexListingClient from './pages/clients/IndexListingClient';
import IndexCreateClient from './pages/clients/IndexCreateClient';
import IndexUpdateClient from './pages/clients/IndexUpdateClient';
import IndexClientDetails from './pages/clients/IndexClientDetails';
import DocumentsListing from './pages/clients/DocumentsListing';
import AddClientDocument from './pages/clients/AddClientDocument';
import UpdateClientDocument from './pages/clients/UpdateClientDocument';
import ClientDocumentDetails from './pages/clients/ClientDocumentDetails';
import ClientSettings from './pages/clients/ClientSettings';

// Vouchers
import VouchersListing from './pages/clients/VouchersListing';
import CreateVoucher from './pages/clients/CreateVoucher';
import UpdateVoucher from './pages/clients/UpdateVoucher';
import VoucherDetails from './pages/clients/VoucherDetails';

// Invoices
import InvoicesListing from './pages/clients/InvoicesListing';
import CreateInvoice from './pages/clients/CreateInvoice';
import UpdateInvoice from './pages/clients/UpdateInvoice';
import InvoiceDetails from './pages/clients/InvoiceDetails';

// Controls
import ControlsListing from './pages/controls/ControlsListing';
import CreateControl from './pages/controls/CreateControl';
import UpdateControl from './pages/controls/UpdateControl';
import ControlDetails from './pages/controls/ControlDetails';
import ControlFusion from './pages/controls/ControlFusion';
import Kardex from './pages/controls/Kardex';
import AddPaymentControl from './pages/controls/AddPaymentControl';
import UpdatePaymentControl from './pages/controls/UpdatePaymentControl';
import PaymentDetailsControl from './pages/controls/PaymentDetailsControl';
import AddDiscountControl from './pages/controls/AddDiscountControl';
import UpdateDiscountControl from './pages/controls/UpdateDiscountControl';
import DiscountDetailsControl from './pages/controls/DiscountDetailsControl';
import AddServiceControl from './pages/controls/AddServiceControl';
import UpdateServiceControl from './pages/controls/UpdateServiceControl';
import ServiceControlDetails from './pages/controls/ServiceDetailsControl';
import UpdateBalanceNotification from './pages/controls/UpdateBalanceNotification';

// Transactions
import IndexFinances from './pages/transactions';
import CreateTransaction from './pages/transactions/CreateTransaction';
import UpdateTransaction from './pages/transactions/UpdateTransaction';
import TransactionDetails from './pages/transactions/TransactionDetails';

// Reports
import IncomesTransactionReport from './pages/reports/IncomesTransactionReport';
import ExpensesTransactionReport from './pages/reports/ExpensesTransactionReport';
import InvoicesReport from './pages/reports/InvoicesReport';

// Files
import FilesListing from './pages/files/FilesListing';
import CreateFile from './pages/files/CreateFile';
import UpdateFile from './pages/files/UpdateFile';
import FileDetails from './pages/files/FileDetails';

// Configuration
// QR
import QRConfiguration from './pages/configuration/QRConfiguration';

// Client screens
// Controls
import ControlsListingClient from './pages/client/ControlsListingClient';
import KardexClient from './pages/client/KardexClient';
import ProfileClient from './pages/client/ProfileClient';
import DocumentsListingClient from './pages/client/DocumentsListingClient';
import AddDocumentClient from './pages/client/AddDocumentClient';
import BalanceNotificationClient from './pages/client/BalanceNotificationClient';

// Vouchers
import VouchersListingClient from './pages/client/VouchersListingClient';
import CreateVoucherClient from './pages/client/CreateVoucherClient';
import UpdateVoucherClient from './pages/client/UpdateVoucherClient';
import VoucherDetailsClient from './pages/client/VoucherDetailsClient';

// Invoices
import InvoicesListingClient from './pages/client/InvoicesListingClient';
import CreateInvoiceClient from './pages/client/CreateInvoiceClient';
import UpdateInvoiceClient from './pages/client/UpdateInvoiceClient';
import InvoiceDetailsClient from './pages/client/InvoiceDetailsClient';
import InvoicesReportClient from './pages/client/InvoicesReportClient';

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <PrivateRoute component={DashboardLayout} />,
      children: [
        { path: 'app', element: <PrivateAdminRoute component={DashboardApp} /> },
        { path: 'home', element: <PrivateRoute component={Home} /> },
        // Users
        { path: 'users', element: <PrivateAdminRoute component={UsersListing} /> },
        { path: 'users/create', element: <PrivateAdminRoute component={CreateUser} /> },
        {
          path: 'users/user/update',
          element: <PrivateAdminRoute component={UpdateUser} />
        },
        {
          path: 'users/user/auth/update',
          element: <PrivateAdminRoute component={UpdateAuthUser} />
        },
        {
          path: 'users/user/details',
          element: <PrivateAdminRoute component={UserDetails} />
        },
        // Departments
        {
          path: 'configuration/departments/listing',
          element: <PrivateAdminRoute component={DepartmentsListing} />
        },
        // Municipalities
        {
          path: 'configuration/departments/municipalities',
          element: <PrivateAdminRoute component={MunicipalitiesListing} />
        },
        {
          path: 'configuration/departments/municipalities/create',
          element: <PrivateAdminRoute component={CreateMunicipality} />
        },
        {
          path: 'configuration/departments/municipalities/update',
          element: <PrivateAdminRoute component={UpdateMunicipality} />
        },
        {
          path: 'configuration/departments/municipalities/details',
          element: <PrivateAdminRoute component={MunicipalityDetails} />
        },
        // HealthInsurances
        {
          path: 'configuration/health-insurances',
          element: <PrivateAdminRoute component={HealthInsurancesListing} />
        },
        {
          path: 'configuration/health-insurances/create',
          element: <PrivateAdminRoute component={CreateHealthInsurance} />
        },
        {
          path: 'configuration/health-insurances/update',
          element: <PrivateAdminRoute component={UpdateHealthInsurance} />
        },
        {
          path: 'configuration/health-insurances/details',
          element: <PrivateAdminRoute component={HealthInsuranceDetails} />
        },
        // Categories
        {
          path: 'configuration/categories',
          element: <PrivateAdminRoute component={CategoriesListing} />
        },
        {
          path: 'configuration/categories/create',
          element: <PrivateAdminRoute component={CreateCategory} />
        },
        {
          path: 'configuration/categories/update',
          element: <PrivateAdminRoute component={UpdateCategory} />
        },
        {
          path: 'configuration/categories/details',
          element: <PrivateAdminRoute component={CategoryDetails} />
        },
        // Services
        {
          path: 'configuration/services',
          element: <PrivateAdminRoute component={ServicesListing} />
        },
        {
          path: 'configuration/services/create',
          element: <PrivateAdminRoute component={CreateService} />
        },
        {
          path: 'configuration/services/update',
          element: <PrivateAdminRoute component={UpdateService} />
        },
        {
          path: 'configuration/services/details',
          element: <PrivateAdminRoute component={ServiceDetails} />
        },
        // Clients
        {
          path: 'clients/listing',
          element: <PrivateAdminRoute component={IndexListingClient} />
        },
        {
          path: 'clients/create',
          element: <PrivateAdminRoute component={IndexCreateClient} />
        },
        {
          path: 'clients/update',
          element: <PrivateAdminRoute component={IndexUpdateClient} />
        },
        {
          path: 'clients/details',
          element: <PrivateAdminRoute component={IndexClientDetails} />
        },
        {
          path: 'clients/documents',
          element: <PrivateAdminRoute component={DocumentsListing} />
        },
        {
          path: 'clients/documents/add',
          element: <PrivateAdminRoute component={AddClientDocument} />
        },
        {
          path: 'clients/documents/update',
          element: <PrivateAdminRoute component={UpdateClientDocument} />
        },
        {
          path: 'clients/documents/details',
          element: <PrivateAdminRoute component={ClientDocumentDetails} />
        },
        {
          path: 'clients/settings/:id',
          element: <PrivateAdminRoute component={ClientSettings} />
        },
        {
          path: 'clients/vouchers/listing',
          element: <PrivateAdminRoute component={VouchersListing} />
        },
        {
          path: 'clients/vouchers/create',
          element: <PrivateAdminRoute component={CreateVoucher} />
        },
        {
          path: 'clients/vouchers/update',
          element: <PrivateAdminRoute component={UpdateVoucher} />
        },
        {
          path: 'clients/vouchers/details',
          element: <PrivateAdminRoute component={VoucherDetails} />
        },
        // Invoices
        {
          path: 'clients/invoices/listing',
          element: <PrivateAdminRoute component={InvoicesListing} />
        },
        {
          path: 'clients/invoices/create',
          element: <PrivateAdminRoute component={CreateInvoice} />
        },
        {
          path: 'clients/invoices/update',
          element: <PrivateAdminRoute component={UpdateInvoice} />
        },
        {
          path: 'clients/invoices/details',
          element: <PrivateAdminRoute component={InvoiceDetails} />
        },
        // Controls client
        {
          path: 'controls/listing',
          element: <PrivateAdminOrAssistantRoute component={ControlsListing} />
        },
        {
          path: 'controls/create',
          element: <PrivateAdminRoute component={CreateControl} />
        },
        {
          path: 'controls/update',
          element: <PrivateAdminRoute component={UpdateControl} />
        },
        {
          path: 'controls/details',
          element: <PrivateAdminRoute component={ControlDetails} />
        },
        {
          path: 'controls/fusion',
          element: <PrivateAdminRoute component={ControlFusion} />
        },
        {
          path: 'controls/kardex',
          element: <PrivateAdminOrAssistantRoute component={Kardex} />
        },
        {
          path: 'controls/kardex/payments/create',
          element: <PrivateAdminOrAssistantRoute component={AddPaymentControl} />
        },
        {
          path: 'controls/kardex/payments/update',
          element: <PrivateAdminRoute component={UpdatePaymentControl} />
        },
        {
          path: 'controls/kardex/payments/details',
          element: <PrivateAdminRoute component={PaymentDetailsControl} />
        },
        {
          path: 'controls/kardex/discounts/create',
          element: <PrivateAdminRoute component={AddDiscountControl} />
        },
        {
          path: 'controls/kardex/discounts/update',
          element: <PrivateAdminRoute component={UpdateDiscountControl} />
        },
        {
          path: 'controls/kardex/discounts/details',
          element: <PrivateAdminRoute component={DiscountDetailsControl} />
        },
        {
          path: 'controls/kardex/services/create',
          element: <PrivateAdminRoute component={AddServiceControl} />
        },
        {
          path: 'controls/kardex/services/update',
          element: <PrivateAdminRoute component={UpdateServiceControl} />
        },
        {
          path: 'controls/kardex/services/details',
          element: <PrivateAdminRoute component={ServiceControlDetails} />
        },
        {
          path: 'controls/kardex/notification/update',
          element: <PrivateAdminRoute component={UpdateBalanceNotification} />
        },
        // Finances
        {
          path: 'finances/transactions/listing',
          element: <PrivateRoute component={IndexFinances} />
        },
        {
          path: 'finances/transactions/incomes/create',
          element: <PrivateRoute component={CreateTransaction} />
        },
        {
          path: 'finances/transactions/incomes/update',
          element: <PrivateRoute component={UpdateTransaction} />
        },
        {
          path: 'finances/transactions/incomes/details',
          element: <PrivateRoute component={TransactionDetails} />
        },
        // Reports
        {
          path: 'reports/transactions/incomes',
          element: <PrivateRoute component={IncomesTransactionReport} />
        },
        {
          path: 'reports/transactions/expenses',
          element: <PrivateRoute component={ExpensesTransactionReport} />
        },
        {
          path: 'reports/invoices',
          element: <PrivateRoute component={InvoicesReport} />
        },
        {
          path: 'configuration/files',
          element: <PrivateRoute component={FilesListing} />
        },
        {
          path: 'configuration/files/create',
          element: <PrivateRoute component={CreateFile} />
        },
        {
          path: 'configuration/files/update',
          element: <PrivateRoute component={UpdateFile} />
        },
        {
          path: 'configuration/files/details',
          element: <PrivateRoute component={FileDetails} />
        },
        // Configurations
        {
          path: 'configuration/qr-payment',
          element: <PrivateRoute component={QRConfiguration} />
        },
        // Client screens
        // Controls client
        {
          path: 'client/controls/listing',
          element: <PrivateClientRoute component={ControlsListingClient} />
        },
        {
          path: 'client/controls/kardex',
          element: <PrivateClientRoute component={KardexClient} />
        },
        {
          path: 'client/profile/details',
          element: <PrivateClientRoute component={ProfileClient} />
        },
        {
          path: 'client/profile/documents/listing',
          element: <PrivateClientRoute component={DocumentsListingClient} />
        },
        {
          path: 'client/profile/documents/add',
          element: <PrivateClientRoute component={AddDocumentClient} />
        },
        // Notifications
        {
          path: 'client/notifications/balances',
          element: <PrivateClientRoute component={BalanceNotificationClient} />
        },
        // Vouchers
        {
          path: 'client/vouchers/listing',
          element: <PrivateClientRoute component={VouchersListingClient} />
        },
        {
          path: 'client/vouchers/create',
          element: <PrivateClientRoute component={CreateVoucherClient} />
        },
        {
          path: 'client/vouchers/update',
          element: <PrivateClientRoute component={UpdateVoucherClient} />
        },
        {
          path: 'client/vouchers/details',
          element: <PrivateClientRoute component={VoucherDetailsClient} />
        },
        // Invoices
        {
          path: 'client/invoices/listing',
          element: <PrivateClientRoute component={InvoicesListingClient} />
        },
        {
          path: 'client/invoices/create',
          element: <PrivateClientRoute component={CreateInvoiceClient} />
        },
        {
          path: 'client/invoices/update',
          element: <PrivateClientRoute component={UpdateInvoiceClient} />
        },
        {
          path: 'client/invoices/details',
          element: <PrivateClientRoute component={InvoiceDetailsClient} />
        },
        {
          path: 'reports/client/invoices',
          element: <PrivateClientRoute component={InvoicesReportClient} />
        }
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <PrivateNavigateRoute /> },
        { path: 'login', element: <PublicRoute component={Login} /> },
        { path: 'forgotpassword', element: <ForgotPassword /> },
        { path: 'resetpassword/:slug', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
