import React, { useEffect, useContext, useState } from 'react';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  FormHelperText,
  CardContent,
  Typography,
  Stack,
  Divider
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import ClientContext from '../../context/client/clientContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import CircularLoading from '../../components/CircularLoading';

const GridStyle = styled(Grid)({
  display: 'inline-flex',
  gap: 10
});

const TypographyStyle = styled(Typography)({
  display: 'inline-block',
  fontWeight: 'bold'
});

const TypographyTextStyle = styled(Typography)({
  display: 'inline-block'
});

export default function ClientProfile() {
  const clientContext = useContext(ClientContext);
  const {
    loading,
    clearData: clearDataClient,
    errors,
    client: clientData,
    getMyProfileClient
  } = clientContext;
  const [client, setClient] = useState({
    id: '',
    name: '',
    owner: '',
    nit: '',
    commercialRegister: '',
    phoneNumber: '',
    phone: '',
    address: '',
    department: '',
    municipality: '',
    mainActivity: '',
    secondaryActivity: '',
    userClient: {
      name: '',
      email: ''
    },
    observations: '',
    type: ''
  });

  useEffect(() => {
    getMyProfileClient();

    return () => clearDataClient();
    // eslint-disable-next-line
  }, []);

  // Load client data
  // prettier-ignore
  useEffect(() => {
    if (!loading && clientData) {
      // Independent Professional
      if (clientData.type === 'Profesionales Independientes') {
        setClient({
          ...client,
          id: clientData._id || '',
          owner: clientData.owner || '',
          nit: clientData.nit || '',
          phoneNumber: clientData.phoneNumber || '',
          phone: clientData.phone || '-',
          address: clientData.address || '',
          department: clientData.municipality?.department?.name || '',
          municipality: clientData.municipality?.name || '',
          userClient: {
            name: clientData.userClient?.name || '',
            email: clientData.userClient?.email || ''
          },
          mainActivity: clientData.mainActivity || '',
          secondaryActivity: clientData.secondaryActivity || '',
          type: clientData.type || ''
        });
        return;
      }

      if (clientData.type === 'Personas Jurídicas SRL') {
        setClient({
          ...client,
          id: clientData._id || '',
          name: clientData.name || '',
          nit: clientData.nit || '',
          commercialRegister: clientData.commercialRegister || '',
          phoneNumber: clientData.phoneNumber || '',
          phone: clientData.phone || '-',
          address: clientData.address || '',
          department: clientData.municipality?.department?.name || '',
          municipality: clientData.municipality?.name || '',
          userClient: {
            name: clientData.userClient?.name || '',
            email: clientData.userClient?.email || ''
          },
          mainActivity: clientData.mainActivity || '',
          secondaryActivity: clientData.secondaryActivity || '',
          observations: clientData.observations || '',
          type: clientData.type || ''
        });
        return;
      }

      if (clientData.type === 'Empresas Unipersonales') {
        setClient({
          ...client,
          id: clientData._id || '',
          name: clientData.name || '',
          owner: clientData.owner || '',
          nit: clientData.nit || '',
          commercialRegister: clientData.commercialRegister || '',
          phoneNumber: clientData.phoneNumber || '',
          phone: clientData.phone || '-',
          address: clientData.address || '',
          department: clientData.municipality?.department?.name || '',
          municipality: clientData.municipality?.name || '',
          mainActivity: clientData.mainActivity || '',
          secondaryActivity: clientData.secondaryActivity || '',
          userClient: {
            name: clientData.userClient?.name || '',
            email: clientData.userClient?.email || ''
          },
          observations: clientData.observations || '',
          type: clientData.type || ''
        });
        return;
      }

      if (clientData.type === 'Regímenes Especiales') {
        setClient({
          ...client,
          id: clientData._id || '',
          name: clientData.name || '',
          owner: clientData.owner || '',
          nit: clientData.nit || '',
          commercialRegister: clientData.commercialRegister || '',
          phoneNumber: clientData.phoneNumber || '',
          phone: clientData.phone || '-',
          address: clientData.address || '',
          department: clientData.municipality?.department?.name || '',
          municipality: clientData.municipality?.name || '',
          userClient: {
            name: clientData.userClient?.name || '',
            email: clientData.userClient?.email || ''
          },
          mainActivity: clientData.mainActivity || '',
          secondaryActivity: clientData.secondaryActivity || '',
          observations: clientData.observations || '',
          type: clientData.type || ''
        });
      }
    }
    // eslint-disable-next-line
  }, [loading, clientData]);

  const onReload = () => {
    getMyProfileClient();
  };

  return (
    <Page title="Mi Perfil Cliente | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Perfil del Cliente
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={12}>
            <Box
              mt={3}
              display="flex"
              width="100%"
              justifyContent={{ xs: 'center', md: 'flex-end' }}
              flexDirection={{ xs: 'column', md: 'row' }}
              flexWrap="wrap"
              gap={3}
            >
              <Button
                disabled={loading}
                variant="outlined"
                to="#"
                color="secondary"
                onClick={() => onReload()}
                startIcon={<Iconify icon="eva:refresh-fill" />}
              >
                Recargar
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Card>
          <CardContent>
            {/* Loading */}
            {loading && <CircularLoading height={100} />}

            {/* Client data */}
            <Grid container spacing={3}>
              {client.type === 'Profesionales Independientes' && (
                <GridStyle item md={12} xs={12}>
                  <TypographyStyle>Propietario:</TypographyStyle>
                  <Typography style={{ display: 'inline-block' }}>{client.owner}</Typography>
                </GridStyle>
              )}

              {client.type === 'Personas Jurídicas SRL' && (
                <GridStyle item md={12} xs={12}>
                  <TypographyStyle>Razón Social:</TypographyStyle>
                  <Typography style={{ display: 'inline-block' }}>{client.name}</Typography>
                </GridStyle>
              )}

              {client.type === 'Empresas Unipersonales' && (
                <>
                  <GridStyle item md={12} xs={12}>
                    <TypographyStyle>Razón Social:</TypographyStyle>
                    <Typography style={{ display: 'inline-block' }}>{client.name}</Typography>
                  </GridStyle>
                  <GridStyle item md={12} xs={12}>
                    <TypographyStyle>Propietario:</TypographyStyle>
                    <Typography>{client.owner}</Typography>
                  </GridStyle>
                </>
              )}

              {client.type === 'Regímenes Especiales' && (
                <>
                  <GridStyle item md={12} xs={12}>
                    <TypographyStyle>Razón Social:</TypographyStyle>
                    <Typography style={{ display: 'inline-block' }}>{client.name}</Typography>
                  </GridStyle>
                  <GridStyle item md={12} xs={12}>
                    <TypographyStyle>Propietario:</TypographyStyle>
                    <Typography>{client.owner}</Typography>
                  </GridStyle>
                </>
              )}

              {/* Details */}
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>NIT:</TypographyStyle>
                <TypographyTextStyle>{client.nit}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Teléfono 1:</TypographyStyle>
                <TypographyTextStyle>{client.phoneNumber}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Teléfono 2:</TypographyStyle>
                <TypographyTextStyle>{client.phone}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Actividad Comercial Principal:</TypographyStyle>
                <TypographyTextStyle>{client.mainActivity}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Actividad Comercial Secundaria:</TypographyStyle>
                <TypographyTextStyle>{client.secondaryActivity}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Dirección:</TypographyStyle>
                <TypographyTextStyle>{client.address}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Departamento:</TypographyStyle>
                <TypographyTextStyle>{client.department}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Municipio:</TypographyStyle>
                <TypographyTextStyle>{client.municipality}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={12} xs={12}>
                <TypographyStyle>Tipo de Cliente:</TypographyStyle>
                <Typography>{client.type}</Typography>
              </GridStyle>

              {/* Auth */}
              <GridStyle item md={12} xs={12} />
              <GridStyle item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Acceso al Sistema
                </FormHelperText>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Nombre:</TypographyStyle>
                <TypographyTextStyle>{client.userClient.name}</TypographyTextStyle>
              </GridStyle>
              <GridStyle item md={6} xs={12}>
                <TypographyStyle>Email:</TypographyStyle>
                <TypographyTextStyle>{client.userClient.email}</TypographyTextStyle>
              </GridStyle>
            </Grid>
            {errors?.error && (
              <Grid item md={12} xs={12} lg={12}>
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>{errors.error}</FormHelperText>
                </Box>
              </Grid>
            )}
          </CardContent>
          <Divider />
        </Card>
      </Container>
    </Page>
  );
}
