// material
import { Box, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
// ----------------------------------------------------------------------

export default function Home() {
  return (
    <Page title="Inicio | G&G Asesor ">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Hola, Bienvenido de nuevo</Typography>
        </Box>
      </Container>
    </Page>
  );
}
