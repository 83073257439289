import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';

// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const previousScreen = '/dashboard/controls/kardex';

export default function PaymentDetailsControl() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const { loading, errors, clearData, payment: paymentData, getPayment } = controlContext;
  const [payment, setPayment] = useState({
    paymentNumber: '',
    service: '',
    concept: '',
    observations: '',
    it: 0,
    iva: 0,
    iue: 0,
    other: 0,
    other2: 0,
    srv: 0,
    additionalAmount: 0,
    periods: [
      {
        month: moment().format('MMMM'),
        year: moment().format('YYYY')
      }
    ],
    years: [],
    accountAmount: 0,
    balanceAmount: 0,
    otherObservations: [],
    user: '',
    createdAt: '',
    updatedAt: ''
  });
  // Id received by previous screen
  const controlId = state?.controlId;
  const paymentId = state?.paymentId;

  // Fetch data
  useEffect(() => {
    if (controlId && paymentId) {
      getPayment(controlId, paymentId);
    } else {
      navigate(previousScreen, {
        state: {
          id: controlId
        }
      });
    }
    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [controlId, paymentId, previousScreen]);

  // Load data
  useEffect(() => {
    if (!loading && paymentData) {
      setPayment({
        paymentNumber: paymentData.paymentNumber || '',
        service: paymentData.service?.concept || '-',
        concept: paymentData.concept || '',
        observations: paymentData.observations || '',
        it: paymentData.it || paymentData.it === 0 ? paymentData.it : 0,
        iva: paymentData.iva || paymentData.iva === 0 ? paymentData.iva : 0,
        iue: paymentData.iue || paymentData.iue === 0 ? paymentData.iue : 0,
        other: paymentData.other || paymentData.other === 0 ? paymentData.other : 0,
        other2: paymentData.other2 || paymentData.other2 === 0 ? paymentData.other2 : 0,
        srv: paymentData.srv || paymentData.srv === 0 ? paymentData.srv : 0,
        additionalAmount:
          paymentData.additionalAmount || paymentData.additionalAmount === 0
            ? paymentData.additionalAmount
            : 0,
        periods:
          paymentData.periods?.length > 0
            ? paymentData.periods.map((item) => ({ month: item.month, year: item.year }))
            : [],
        years: paymentData.years || [],
        accountAmount:
          paymentData.accountAmount || paymentData.accountAmount === 0
            ? paymentData.accountAmount
            : 0,
        balanceAmount:
          paymentData.balanceAmount || paymentData.balanceAmount === 0
            ? paymentData.balanceAmount
            : 0,
        otherObservations:
          paymentData.otherObservations.map((item) => ({
            observation: item.observation
          })) || [],
        createdAt: paymentData.createdAt
          ? moment(paymentData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        updatedAt: paymentData.updatedAt
          ? moment(paymentData.updatedAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: paymentData?.user?.name || '-'
      });
    }
  }, [loading, paymentData]);

  // prettier-ignore
  const totalAmountReceived = (
    Number(payment.it) +
    Number(payment.iva) +
    Number(payment.iue) +
    Number(payment.other) +
    Number(payment.other2) +
    Number(payment.srv) || 0
  ).toFixed(2);

  return (
    <Page title="Pagos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: controlId }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del recibo de pago"
            title="Detalles del Recibo de pago"
            sx={{
              mb: 1
            }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Pago"
                  name="paymentNumber"
                  onChange={null}
                  value={payment.paymentNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  name="service"
                  label="Servicio"
                  onChange={null}
                  value={payment.service}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  name="concept"
                  label="Concepto"
                  onChange={null}
                  value={payment.concept}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Pagos de Impuestos
                  </FormHelperText>
                  <FormHelperText
                    sx={{
                      fontSize: 14
                    }}
                  >
                    Periodo
                  </FormHelperText>
                </Box>
              </Grid>

              {/* Months and Years */}
              <Grid item md={12} xs={12}>
                {payment.periods.map((period, i) => (
                  <Grid
                    key={i}
                    container
                    spacing={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginBottom: 3
                    }}
                    paddingLeft={{
                      sm: 0,
                      md: 10
                    }}
                    paddingRight={{
                      sm: 0,
                      md: 10
                    }}
                  >
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Mes"
                        name="month"
                        onChange={null}
                        value={period.month}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Año"
                        name="year"
                        onChange={null}
                        value={period.year}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="IT Bs."
                  name="it"
                  onChange={null}
                  value={payment.it}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="IVA Bs."
                  name="iva"
                  onChange={null}
                  value={payment.iva}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="IUE Bs."
                  name="iue"
                  onChange={null}
                  value={payment.iue}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Otro Bs."
                  name="other"
                  onChange={null}
                  value={payment.other}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Otro Bs."
                  name="other2"
                  onChange={null}
                  value={payment.other2}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Servicio Bs."
                  name="srv"
                  onChange={null}
                  value={payment.srv}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Monto Adicional Bs."
                  name="additionalAmount"
                  onChange={null}
                  value={payment.additionalAmount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observations"
                  onChange={null}
                  rows={4}
                  multiline
                  value={payment.observations}
                  variant="outlined"
                />
              </Grid>

              {/* Other observations */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Otras Observaciones
                </FormHelperText>
              </Grid>
              <Grid item md={12} xs={12}>
                {payment.otherObservations.map((item, i) => (
                  <Grid
                    key={i}
                    container
                    spacing={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginBottom: 3
                    }}
                    paddingLeft={{
                      sm: 0,
                      md: 10
                    }}
                    paddingRight={{
                      sm: 0,
                      md: 10
                    }}
                  >
                    <Grid item md={10} xs={12}>
                      <TextField
                        fullWidth
                        label="Observación"
                        name="observation"
                        onChange={null}
                        value={item.observation}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {/* Summary */}
              <Grid item md={12} xs={12}>
                <Box>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Resumen
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="A Cuenta Bs."
                  name="accountAmount"
                  onChange={null}
                  value={payment.accountAmount.toFixed(2)}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Saldo Actualizado Bs."
                  name="balanceAmount"
                  onChange={null}
                  value={payment.balanceAmount.toFixed(2)}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Gestiones"
                  name="years"
                  onChange={null}
                  value={payment.years.map((item) => item.year).join(' - ')}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Box>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    {`Total recibido por cliente ${totalAmountReceived} Bs.`}
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  value={payment.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Modificación"
                  name="createdAt"
                  onChange={null}
                  value={payment.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  value={payment.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              sx={{
                mr: 2
              }}
              variant="outlined"
              type="button"
              onClick={() =>
                navigate(previousScreen, {
                  state: {
                    id: controlId
                  }
                })
              }
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
