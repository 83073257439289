import * as Yup from 'yup';
import { useState, useEffect, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { styled } from '@mui/material/styles';
// material
import { Link, Stack, TextField, IconButton, InputAdornment, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import AuthContext from '../../../context/auth/authContext';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  color: theme.palette.error.main
}));

export default function LoginForm() {
  const authContext = useContext(AuthContext);
  const {
    login,
    errors: errorsAuth,
    isAuthenticated,
    clearAuthErrors,
    user,
    logout
    // loadUser
  } = authContext;
  const [accessDenied, setAccessDenied] = useState({
    visible: false,
    msg: ''
  });
  const [showPassword, setShowPassword] = useState(false);

  // If auth user is disabled, logout
  useEffect(() => {
    if (isAuthenticated && user && user.data && user.data.state === false) {
      setAccessDenied({
        visible: true,
        msg: 'El usuario se encuentra deshabilitado'
      });
      logout();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Unmount component
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearAuthErrors(), []);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('El email debe ser una dirección de correo electrónico válida')
      .required('Email is requerido'),
    password: Yup.string().required('Contraseña es requerido')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      await login(formik.values);
    }
  });

  const {
    errors,
    touched,
    // values,
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <RootStyle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Contraseña"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                )
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          {/* Access denied auth user */}
          {accessDenied.visible && (
            <Typography
              variant="body2"
              component="div"
              align="center"
              sx={{
                mt: 3
              }}
            >
              {accessDenied.msg}
            </Typography>
          )}

          {/* General error */}
          {errorsAuth && errorsAuth.error && (
            <Typography
              variant="body2"
              component="div"
              align="center"
              sx={{
                mt: 3
              }}
            >
              {errorsAuth.error}
            </Typography>
          )}

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <Link component={RouterLink} variant="subtitle2" to="/forgotpassword" underline="hover">
              Olvido Contraseña?
            </Link>
          </Stack>

          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Ingresar
          </LoadingButton>
        </Form>
      </FormikProvider>
    </RootStyle>
  );
}
