import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config } from '../../config/requestConfig';
import ServiceContext from './serviceContext';
import serviceReducer from './serviceReducer';
import SnackbarContext from '../snackbar/snackbarContext';

import {
  GET_SERVICES,
  GET_SERVICE,
  CREATE_SERVICE_SUCCESS,
  UPDATE_SERVICE_SUCCESS,
  DELETE_SERVICE_SUCCESS,
  SET_LOADING_SERVICE,
  SERVICE_ERROR,
  CLEAR_SERVICE
} from '../types';

function ServiceState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    services: null,
    service: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(serviceReducer, initialState);

  // Fetch all services
  const getServices = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/services`);
      dispatch({
        type: GET_SERVICES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: SERVICE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single service
  const getService = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/services/${id}`);
      dispatch({
        type: GET_SERVICE,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: SERVICE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new service
  const createService = async (formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/services`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: CREATE_SERVICE_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: SERVICE_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update service
  const updateService = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/services/${id}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: UPDATE_SERVICE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: SERVICE_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete service
  const deleteService = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/services/${id}`, config);
      openSuccessSnackbar();
      dispatch({
        type: DELETE_SERVICE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: SERVICE_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Set Loading
  const setLoading = (state) => {
    dispatch({
      type: SET_LOADING_SERVICE,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_SERVICE
    });
  };

  return (
    <ServiceContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        services: state.services,
        service: state.service,
        errors: state.errors,
        loading: state.loading,
        getServices,
        getService,
        createService,
        updateService,
        deleteService,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </ServiceContext.Provider>
  );
}

export default ServiceState;
