import {
  GET_DEPARTMENTS,
  SET_LOADING_DEPARTMENT,
  DEPARTMENT_ERROR,
  CLEAR_DEPARTMENT
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
        errors: null,
        loading: false
      };
    case SET_LOADING_DEPARTMENT:
      return {
        ...state,
        loading: true,
        errors: null
      };
    case DEPARTMENT_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_DEPARTMENT:
      return {
        ...state,
        departments: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
