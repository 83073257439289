import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config, configMultiPart } from '../../config/requestConfig';
import FileContext from './fileContext';
import fileReducer from './fileReducer';
import SnackbarContext from '../snackbar/snackbarContext';

import {
  GET_FILES,
  GET_FILE,
  CREATE_FILE_SUCCESS,
  UPDATE_FILE_SUCCESS,
  DELETE_FILE_SUCCESS,
  UPLOAD_FILE_SUCCESS,
  SET_LOADING_FILE,
  FILE_ERROR,
  CLEAR_FILE
} from '../types';

function FileState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    files: null,
    file: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(fileReducer, initialState);

  // Fetch all files
  const getFiles = async (fileType = '') => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/files?fileType=${fileType}`);
      dispatch({
        type: GET_FILES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: FILE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single file
  const getFile = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/files/${id}`);
      dispatch({
        type: GET_FILE,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: FILE_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new file
  const createFile = async (formData, formDataFile) => {
    try {
      setLoading(true);
      const res = await axios.post(`/api/v1/files`, formData, config);
      dispatch({
        type: CREATE_FILE_SUCCESS
      });
      // Upload image to uploads folder
      const fileId = res.data?.data?._id;
      if (formDataFile.get('file') instanceof File) {
        await uploadFile(fileId, formDataFile);
      }
      openSuccessSnackbar();
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: FILE_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update file
  const updateFile = async (id, formData, formDataFile) => {
    try {
      setLoading(true);
      const res = await axios.put(`/api/v1/files/${id}`, formData, config);
      dispatch({
        type: UPDATE_FILE_SUCCESS
      });
      // Upload image to uploads folder
      const fileId = res.data?.data?._id;
      if (formDataFile.get('file') instanceof File) {
        await uploadFile(fileId, formDataFile);
      }
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: FILE_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Delete file
  const deleteFile = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/files/${id}`, config);
      dispatch({
        type: DELETE_FILE_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: FILE_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Upload file
  const uploadFile = async (id, formData) => {
    try {
      setLoading(true);
      // Return path file uploaded
      await axios.post(`/api/v1/uploads/files/${id}`, formData, configMultiPart);
      dispatch({
        type: UPLOAD_FILE_SUCCESS
      });
    } catch (error) {
      dispatch({
        type: FILE_ERROR,
        payload: error.response?.data?.errors
      });
    }
  };

  // Set Loading
  const setLoading = (state) => {
    dispatch({
      type: SET_LOADING_FILE,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_FILE
    });
  };

  return (
    <FileContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        files: state.files,
        file: state.file,
        errors: state.errors,
        loading: state.loading,
        getFiles,
        getFile,
        createFile,
        updateFile,
        deleteFile,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </FileContext.Provider>
  );
}

export default FileState;
