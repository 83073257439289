import React from 'react';
import { useLocation } from 'react-router-dom';

import IncomesListing from './IncomesListing';
import ExpensesListing from './ExpensesListing';

export default function IndexTransactions() {
  const { search: searchURL } = useLocation();

  const query = new URLSearchParams(searchURL);
  const paramFilter = query.get('transactionType');

  if (paramFilter === 'Ingreso') {
    return <IncomesListing transactionType="Ingreso" />;
  }
  if (paramFilter === 'Egreso') {
    return <ExpensesListing transactionType="Egreso" />;
  }

  return <div></div>;
}
