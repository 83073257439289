export const yearOptionsList = [
  {
    _id: '0',
    label: 'Seleccione una opción',
    value: '0'
  },
  {
    _id: '22',
    label: '2022',
    value: '2022'
  },
  {
    _id: '23',
    label: '2023',
    value: '2023'
  },
  {
    _id: '24',
    label: '2024',
    value: '2024'
  },
  {
    _id: '25',
    label: '2025',
    value: '2025'
  },
  {
    _id: '26',
    label: '2026',
    value: '2026'
  },
  {
    _id: '27',
    label: '2027',
    value: '2027'
  },
  {
    _id: '28',
    label: '2028',
    value: '2028'
  },
  {
    _id: '29',
    label: '2029',
    value: '2029'
  },
  {
    _id: '30',
    label: '2030',
    value: '2030'
  }
];
