import React, { useEffect, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField,
  Alert,
  Typography
} from '@mui/material';
import { FileUploader } from 'react-drag-drop-files';
// components
import FileContext from '../../context/file/fileContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de crear un nuevo archivo?'
};

const fileTypes = ['JPG', 'PNG', 'JPEG', 'PDF'];

const previousScreen = '/dashboard/configuration/files';

export default function CreateFile() {
  const navigate = useNavigate();
  const fileContext = useContext(FileContext);
  const { loading, clearData, createFile, errors, setLoading } = fileContext;
  const [fileData, setFileData] = useState({
    name: '',
    description: ''
  });
  const [file, setFile] = useState(null);
  const [errorUpload, setErrorUpload] = useState('');
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    setLoading(false);

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  // File
  useEffect(() => {
    if (file) {
      setErrorUpload('');
    }
  }, [file]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setFileData({
      ...fileData,
      [name]: value
    });
  };

  const onChangeFile = (file) => {
    setFile(file);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      name: fileData.name,
      description: fileData.description
    };

    // Validate file
    if (!file) {
      setErrorUpload('Seleccione un archivo');
      setOpenDialog(false);
      return;
    }

    // Add file
    const formFile = new FormData();
    formFile.append('file', file);

    setOpenDialog(false);
    if ((await createFile(formData, formFile)) === true) {
      navigate(previousScreen);
    }
  };

  return (
    <Page title="Archivos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para crear un nuevo archivo"
            title="Crear Archivo"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    fullWidth
                    placeholder="Ingrese nombre del archivo"
                    label="Nombre"
                    name="name"
                    onChange={handleChange}
                    required
                    value={fileData.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.description)}
                    fullWidth
                    helperText={errors?.description}
                    label="Descripción"
                    placeholder="Información adicional"
                    name="description"
                    multiline
                    onChange={handleChange}
                    value={fileData.description}
                    rows={4}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <FormHelperText style={{ fontSize: 14, marginBottom: 10 }}>
                    Subir Archivo *
                  </FormHelperText>
                  <FileUploader
                    multiple={false}
                    handleChange={onChangeFile}
                    name="file"
                    types={fileTypes}
                    // eslint-disable-next-line
                    onSizeError={(file) => setErrorUpload(`El tipo de archivo es demasiado grande`)}
                    // eslint-disable-next-line
                    onTypeError={(err) =>
                      setErrorUpload('El tipo de archivo no es compatible')
                    }
                    required
                    maxSize={process.env.REACT_APP_MAX_SIZE_UPLOAD}
                    label="Cargue o suelte un archivo aquí mismo"
                    hoverTitle="Suelte aquí"
                  />
                  {errorUpload && <FormHelperText error>{errorUpload}</FormHelperText>}
                  {errors?.file && <FormHelperText error>{errors.file}</FormHelperText>}
                  <Typography variant="body2" style={{ marginTop: 10 }}>
                    {file?.name || 'No se ha seleccionado ningún archivo'}
                  </Typography>
                  {/* prettier-ignore */}
                  <Alert severity="warning" style={{ marginTop: 10 }}>
                    Nota: solo permite subir archivos con extensiones PDF, PNG, JPEG, JPG. Tamaño máximo para subir archivos al servidor:{' '}
                    {process.env.REACT_APP_MAX_SIZE_UPLOAD} MB
                  </Alert>
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate(previousScreen)}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
