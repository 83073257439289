import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import MunicipalityContext from './municipalityContext';
import municipalityReducer from './municipalityReducer';
import { config } from '../../config/requestConfig';
import SnackbarContext from '../snackbar/snackbarContext';

import {
  GET_MUNICIPALITIES,
  GET_MUNICIPALITY,
  SET_LOADING_MUNICIPALITY,
  CREATE_MUNICIPALITY_SUCCESS,
  UPDATE_MUNICIPALITY_SUCCESS,
  DELETE_MUNICIPALITY_SUCCESS,
  MUNICIPALITY_ERROR,
  CLEAR_DATA_MUNICIPALITY
} from '../types';

function MunicipalityState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    municipalities: null,
    municipality: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(municipalityReducer, initialState);

  // Fetch all municipalities
  const getMunicipalities = async (departmentId) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/departments/${departmentId}/municipalities`);
      dispatch({
        type: GET_MUNICIPALITIES,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors
      });
    }
  };

  // Fetch single municipality
  const getMunicipality = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/municipalities/${id}`);
      dispatch({
        type: GET_MUNICIPALITY,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors
      });
    }
  };

  // Create municipality
  const createMunicipality = async (departmentId, formData) => {
    try {
      setLoading(true);
      await axios.post(`/api/v1/departments/${departmentId}/municipalities`, formData, config);
      dispatch({
        type: CREATE_MUNICIPALITY_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors
      });
      return false;
    }
  };

  // Update municipality
  const updateMunicipality = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/municipalities/${id}`, formData, config);
      dispatch({
        type: UPDATE_MUNICIPALITY_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors
      });
      return false;
    }
  };

  // Delete municipality
  const deleteMunicipality = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/municipalities/${id}`);
      dispatch({
        type: DELETE_MUNICIPALITY_SUCCESS
      });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: MUNICIPALITY_ERROR,
        payload: err.response.data.errors
      });
      return false;
    }
  };

  // Set Loading
  const setLoading = (state) => {
    dispatch({
      type: SET_LOADING_MUNICIPALITY,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_MUNICIPALITY
    });
  };

  return (
    <MunicipalityContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        municipalities: state.municipalities,
        municipality: state.municipality,
        errors: state.errors,
        loading: state.loading,
        getMunicipalities,
        getMunicipality,
        createMunicipality,
        updateMunicipality,
        deleteMunicipality,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </MunicipalityContext.Provider>
  );
}

export default MunicipalityState;
