import React, { useReducer, useContext } from 'react';
import axios from 'axios';

import TransactionContext from './transactionContext';
import transactionReducer from './transactionReducer';
import { config } from '../../config/requestConfig';
import SnackbarContext from '../snackbar/snackbarContext';
import {
  GET_TRANSACTIONS,
  GET_TRANSACTION,
  SET_LOADING_TRANSACTION,
  TRANSACTION_CREATE_SUCCESS,
  TRANSACTION_UPDATE_SUCCESS,
  TRANSACTION_DELETE_SUCCESS,
  TRANSACTION_ERROR,
  CLEAR_DATA_TRANSACTION
} from '../types';

function TransactionState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    transactions: null,
    transaction: null,
    loading: true,
    errors: null
  };

  const [state, dispatch] = useReducer(transactionReducer, initialState);

  // Fetch all transactions
  const getTransactions = async ({
    transactionType = '',
    keyword = '',
    pageNumber = '',
    rowsPerPage = ''
  } = {}) => {
    try {
      setLoading(true);
      const res = await axios.get(
        `/api/v1/transactions?transactionType=${transactionType}&keyword=${keyword}&pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}`
      );
      dispatch({
        type: GET_TRANSACTIONS,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Fetch single transaction
  const getTransaction = async (id) => {
    try {
      setLoading(true);
      const res = await axios.get(`/api/v1/transactions/${id}`);
      dispatch({
        type: GET_TRANSACTION,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new transaction
  const createTransaction = async (formData) => {
    try {
      setLoading(true);
      await axios.post('/api/v1/transactions', formData, config);
      openSuccessSnackbar();
      dispatch({
        type: TRANSACTION_CREATE_SUCCESS
      });
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: TRANSACTION_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update transaction
  const updateTransaction = async (id, formData) => {
    try {
      setLoading(true);
      await axios.put(`/api/v1/transactions/${id}`, formData, config);
      openSuccessSnackbar();
      dispatch({
        type: TRANSACTION_UPDATE_SUCCESS
      });
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response.data.errors
      });
      openErrorSnackbar();
      return false;
    }
  };

  // Delete transaction
  const deleteTransaction = async (id) => {
    try {
      setLoading(true);
      await axios.delete(`/api/v1/transactions/${id}`, config);
      openSuccessSnackbar();
      dispatch({
        type: TRANSACTION_DELETE_SUCCESS
      });
      return true;
    } catch (err) {
      dispatch({
        type: TRANSACTION_ERROR,
        payload: err.response?.data?.errors
      });
      openErrorSnackbar();
      return false;
    }
  };

  // Set Loading
  const setLoading = async (state) => {
    dispatch({
      type: SET_LOADING_TRANSACTION,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_DATA_TRANSACTION
    });
  };

  return (
    <TransactionContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        transactions: state.transactions,
        transaction: state.transaction,
        errors: state.errors,
        loading: state.loading,
        getTransactions,
        getTransaction,
        createTransaction,
        updateTransaction,
        deleteTransaction,
        setLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </TransactionContext.Provider>
  );
}

export default TransactionState;
