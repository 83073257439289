import {
  GET_MUNICIPALITIES,
  GET_MUNICIPALITY,
  SET_LOADING_MUNICIPALITY,
  MUNICIPALITY_ERROR,
  CLEAR_DATA_MUNICIPALITY
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_MUNICIPALITIES:
      return {
        ...state,
        municipalities: action.payload,
        errors: null,
        loading: false
      };
    case GET_MUNICIPALITY:
      return {
        ...state,
        errors: null,
        municipality: action.payload,
        loading: false
      };
    case SET_LOADING_MUNICIPALITY:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case MUNICIPALITY_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_DATA_MUNICIPALITY:
      return {
        ...state,
        municipalities: null,
        municipality: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
