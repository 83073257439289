import {
  GET_INVOICES,
  GET_INVOICE,
  SET_LOADING_INVOICE,
  INVOICE_CREATE_SUCCESS,
  INVOICE_UPDATE_SUCCESS,
  INVOICE_DELETE_SUCCESS,
  INVOICE_ERROR,
  CLEAR_DATA_INVOICE
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        errors: null,
        loading: false
      };
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
        loading: false,
        errors: null
      };
    case SET_LOADING_INVOICE:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case INVOICE_CREATE_SUCCESS:
    case INVOICE_UPDATE_SUCCESS:
    case INVOICE_DELETE_SUCCESS:
      return {
        ...state,
        errors: null,
        loading: false
      };
    case INVOICE_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_DATA_INVOICE:
      return {
        ...state,
        invoices: null,
        invoice: null,
        loading: true,
        errors: null
      };
    default:
      return state;
  }
};
