import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import AuthContext from '../context/auth/authContext';
import { rolesUser } from '../constants';

function PrivateNavigateRoute() {
  const authContext = useContext(AuthContext);
  const { isAuthenticated, loading, user, loadUser, logout } = authContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loading && isAuthenticated) {
      // If auth is disabled
      if (user && user.data && !user.data.state) {
        logout();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isAuthenticated]);

  if (isAuthenticated && loading === false && user?.data?.role === rolesUser.administrator) {
    return <Navigate to="/dashboard/app" />;
  }

  if (isAuthenticated && loading === false && user?.data?.role === rolesUser.assistant) {
    return <Navigate to="/dashboard/home" />;
  }

  if (isAuthenticated && loading === false && user?.data?.role === rolesUser.client) {
    return <Navigate to="/dashboard/client/notifications/balances" />;
  }

  if (isAuthenticated === false && loading === false) {
    return <Navigate to="/login" />;
  }

  return null;
}

export default PrivateNavigateRoute;
