import React, { useReducer, useContext } from 'react';
import axios from 'axios';
import { config } from '../../config/requestConfig';
import ConfigurationContext from './configurationContext';
import ConfigurationReducer from './configurationReducer';
import SnackbarContext from '../snackbar/snackbarContext';

import {
  GET_QR_CONFIGURATION,
  CREATE_QR_CONFIGURATION_SUCCESS,
  UPDATE_QR_CONFIGURATION_SUCCESS,
  GET_QR_ID_CONFIGURATION,
  SET_LOADING_QR_CONFIGURATION,
  CONFIGURATION_ERROR,
  CLEAR_CONFIGURATION
} from '../types';

function ConfigurationState(props) {
  const { openSuccessSnackbar, openErrorSnackbar } = useContext(SnackbarContext);

  const initialState = {
    qrConfiguration: null,
    loadingQRConfiguration: true,
    errors: null
  };

  const [state, dispatch] = useReducer(ConfigurationReducer, initialState);

  // Fetch qr configuration
  const getQRConfiguration = async (id) => {
    try {
      setQrConfLoading(true);
      const res = await axios.get(`/api/v1/configurations/qr-payment/${id}`);
      dispatch({
        type: GET_QR_CONFIGURATION,
        payload: res.data?.data
      });
    } catch (err) {
      dispatch({
        type: CONFIGURATION_ERROR,
        payload: err.response?.data?.errors
      });
    }
  };

  // Create a new configuration
  const createQRConfiguration = async (formData) => {
    try {
      setQrConfLoading(true);
      const res = await axios.post(`/api/v1/configurations/qr-payment`, formData, config);
      dispatch({ type: CREATE_QR_CONFIGURATION_SUCCESS, payload: res?.data?.data });
      openSuccessSnackbar();
      return true;
    } catch (error) {
      openErrorSnackbar();
      dispatch({
        type: CONFIGURATION_ERROR,
        payload: error.response?.data?.errors
      });
      return false;
    }
  };

  // Update qr configuration
  const updateQRConfiguration = async (id, formData) => {
    try {
      setQrConfLoading(true);
      const res = await axios.put(`/api/v1/configurations/qr-payment/${id}`, formData, config);
      dispatch({ type: UPDATE_QR_CONFIGURATION_SUCCESS, payload: res?.data?.data });
      openSuccessSnackbar();
      return true;
    } catch (err) {
      openErrorSnackbar();
      dispatch({
        type: CONFIGURATION_ERROR,
        payload: err.response?.data?.errors
      });
      return false;
    }
  };

  // Get env qr configuration Id
  const getQRConfigurationId = async () => {
    try {
      const res = await axios.get('/api/v1/env/configuration/qr-payment-id');
      dispatch({
        type: GET_QR_ID_CONFIGURATION
      });
      return res.data;
    } catch (error) {
      return null;
    }
  };

  // Set Loading
  const setQrConfLoading = (state) => {
    dispatch({
      type: SET_LOADING_QR_CONFIGURATION,
      payload: state
    });
  };

  // Clear data
  const clearData = async () => {
    dispatch({
      type: CLEAR_CONFIGURATION
    });
  };

  return (
    <ConfigurationContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        qrConfiguration: state.qrConfiguration,
        errors: state.errors,
        loadingQRConfiguration: state.loadingQRConfiguration,
        getQRConfiguration,
        createQRConfiguration,
        updateQRConfiguration,
        getQRConfigurationId,
        setQrConfLoading,
        clearData
      }}
    >
      {/* eslint-disable react/prop-types */}
      {props.children}
    </ConfigurationContext.Provider>
  );
}

export default ConfigurationState;
