import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';

// components
import ControlContext from '../../context/control/controlContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const previousScreen = '/dashboard/controls/kardex';

export default function ServiceDetailsControl() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const controlContext = useContext(ControlContext);
  const { loading, errors, clearData, payment: serviceData, getService } = controlContext;
  const [payment, setPayment] = useState({
    paymentNumber: '',
    service: '',
    concept: '',
    observations: '',
    diffBalanceAmount: 0,
    years: [],
    accountAmount: 0,
    balanceAmount: 0,
    otherObservations: [],
    user: '',
    createdAt: '',
    updatedAt: ''
  });
  // Id received by previous screen
  const controlId = state?.controlId;
  const discountId = state?.paymentId;

  // Fetch data
  useEffect(() => {
    if (controlId && discountId) {
      getService(controlId, discountId);
    } else {
      navigate(previousScreen, {
        state: {
          id: controlId
        }
      });
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [controlId, discountId]);

  // Load payment and control data
  useEffect(() => {
    if (!loading && serviceData) {
      setPayment({
        paymentNumber: serviceData.paymentNumber || '',
        service: serviceData.service?.concept || '-',
        concept: serviceData.concept || '',
        observations: serviceData.observations || '',
        diffBalanceAmount:
          serviceData.diffBalanceAmount || serviceData.diffBalanceAmount === 0
            ? serviceData.diffBalanceAmount
            : 0,
        years: serviceData.years || [],
        accountAmount:
          serviceData.accountAmount || serviceData.accountAmount === 0
            ? serviceData.accountAmount
            : 0,
        balanceAmount:
          serviceData.balanceAmount || serviceData.balanceAmount === 0
            ? serviceData.balanceAmount
            : 0,
        otherObservations:
          serviceData.otherObservations.map((item) => ({
            observation: item.observation
          })) || [],
        createdAt: serviceData.createdAt
          ? moment(serviceData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        updatedAt: serviceData.updatedAt
          ? moment(serviceData.updatedAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: serviceData?.user?.name || '-'
      });
    }
  }, [loading, serviceData]);

  return (
    <Page title="Servicios | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: controlId }} />

        <Card>
          {/* Content */}
          <CardHeader subheader="Información del registro del pago" title="Detalles Servicio" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Nº de Pago"
                  name="paymentNumber"
                  onChange={null}
                  value={payment.paymentNumber}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  name="service"
                  label="Servicio"
                  onChange={null}
                  value={payment.service}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Concepto"
                  name="concept"
                  onChange={null}
                  value={payment.concept}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Monto Bs."
                  name="diffBalanceAmount"
                  onChange={null}
                  value={payment.diffBalanceAmount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observations"
                  onChange={null}
                  rows={4}
                  multiline
                  value={payment.observations}
                  variant="outlined"
                />
              </Grid>

              {/* Other observations */}
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Otras Observaciones
                </FormHelperText>
              </Grid>
              <Grid item md={12} xs={12}>
                {payment.otherObservations.map((item, i) => (
                  <Grid
                    key={i}
                    container
                    spacing={3}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-around',
                      marginBottom: 3
                    }}
                    paddingLeft={{
                      sm: 0,
                      md: 10
                    }}
                    paddingRight={{
                      sm: 0,
                      md: 10
                    }}
                  >
                    <Grid item md={10} xs={12}>
                      <TextField
                        fullWidth
                        label="Observación"
                        name="observation"
                        onChange={null}
                        value={item.observation}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              {/* Summary */}
              <Grid item md={12} xs={12}>
                <Box>
                  <FormHelperText
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 16
                    }}
                  >
                    Resumen
                  </FormHelperText>
                </Box>
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="A Cuenta Bs."
                  name="accountAmount"
                  onChange={null}
                  value={payment.accountAmount.toFixed(2)}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Saldo Actualizado Bs."
                  name="balanceAmount"
                  onChange={null}
                  value={payment.balanceAmount.toFixed(2)}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <TextField
                  fullWidth
                  label="Gestiones"
                  name="years"
                  onChange={null}
                  value={payment.years.map((item) => item.year).join(' - ')}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  value={payment.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Modificación"
                  name="createdAt"
                  onChange={null}
                  value={payment.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  value={payment.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <FormHelperText error>{errors.error}</FormHelperText>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              sx={{
                mr: 2
              }}
              variant="outlined"
              type="button"
              onClick={() =>
                navigate(previousScreen, {
                  state: {
                    id: controlId
                  }
                })
              }
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
