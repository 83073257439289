import {
  GET_CLIENTS,
  GET_CLIENT,
  GET_DOCUMENTS_CLIENT,
  GET_DOCUMENT_CLIENT,
  SET_LOADING_CLIENT,
  GET_MY_PROFILE_CLIENT,
  CLIENT_ERROR,
  CLEAR_CLIENT,
  GET_CLIENT_FINANCIAL_KARDEX_VIEW,
  UPDATE_CLIENT_FINANCIAL_KARDEX_VIEW
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_CLIENTS:
      return {
        ...state,
        clients: action.payload,
        errors: null,
        loading: false
      };
    case GET_DOCUMENTS_CLIENT:
      return {
        ...state,
        documents: action.payload,
        errors: null,
        loading: false
      };
    case GET_CLIENT:
    case GET_MY_PROFILE_CLIENT:
      return {
        ...state,
        errors: null,
        client: action.payload,
        loading: false
      };
    case GET_DOCUMENT_CLIENT:
      return {
        ...state,
        document: action.payload,
        errors: null,
        loading: false
      };
    case GET_CLIENT_FINANCIAL_KARDEX_VIEW:
    case UPDATE_CLIENT_FINANCIAL_KARDEX_VIEW:
      return {
        ...state,
        enableFinancialKardexView: action.payload,
        errors: null,
        loading: false
      };
    case SET_LOADING_CLIENT:
      return {
        ...state,
        loading: action.payload,
        errors: null
      };
    case CLIENT_ERROR:
      return {
        ...state,
        errors: action.payload,
        loading: false
      };
    case CLEAR_CLIENT:
      return {
        ...state,
        clients: null,
        client: null,
        loading: true,
        errors: null,
        documents: null,
        document: null,
        enableFinancialKardexView: false
      };
    default:
      return state;
  }
};
