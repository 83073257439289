import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, Button, ListItemIcon, ListItemText } from '@mui/material';
// component
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

export default function UserMoreMenu({ item, navigate, isCurrentUser, handleOpenDialog }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isClientUser = item.role === 'cliente';

  return (
    <>
      <Button
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<Iconify icon="eva:arrow-ios-downward-outline" width={20} height={20} />}
      >
        Opciones
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* Edit */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();

            if (!isCurrentUser) {
              navigate('/dashboard/users/user/update', { state: { id: item.id } });
            }
          }}
          disabled={isCurrentUser}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            navigate('/dashboard/users/user/auth/update', { state: { id: item.id } });
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:unlock-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar acceso" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Update state */}
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={async () => {
            if (!isCurrentUser) {
              // prettier-ignore
              const message = `${item.state
                ? 'Si deshabilitas el usuario no podrá ingresar al sistema'
                : 'Si habilitas el usuario podrá ingresar nuevamente al sistema'
                }. ¿Estas seguro de ${item.state ? 'deshabilitar' : 'habilitar'} a ${item.email}?`;

              handleOpenDialog(message, item.id, 'updateState');
              handleClose();
            }
          }}
          disabled={isCurrentUser}
        >
          {item.state ? (
            <ListItemIcon>
              <Iconify icon="eva:close-circle-outline" width={24} height={24} />
            </ListItemIcon>
          ) : (
            <ListItemIcon>
              <Iconify icon="eva:checkmark-circle-2-outline" width={24} height={24} />
            </ListItemIcon>
          )}
          <ListItemText
            primary={item.state ? 'Deshabilitar' : 'Habilitar'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/* Details */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            navigate('/dashboard/users/user/details', {
              state: { id: item.id }
            });
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:eye-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Ver Detalles" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Delete */}
        <MenuItem
          disabled={isCurrentUser || isClientUser}
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            if (!isCurrentUser) {
              const message = `Esta seguro de eliminar de forma permanente al usuario ${item.email}?`;

              handleOpenDialog(message, item.id, 'delete');
              handleClose();
            }
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Eliminar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

UserMoreMenu.propTypes = {
  item: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  handleOpenDialog: PropTypes.func.isRequired
};
