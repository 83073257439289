import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import moment from 'moment';
// components
import InvoiceContext from '../../context/invoice/invoiceContext';
import Page from '../../components/Page';
import GoBackButton from '../../components/GoBackButton';

const previousScreen = '/dashboard/clients/invoices/listing';

export default function InvoiceDetails() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const invoiceContext = useContext(InvoiceContext);
  const { loading, clearData, errors, getInvoice, invoice: invoiceData } = invoiceContext;
  const [invoice, setInvoice] = useState({
    voucher: '',
    invoiceDate: '',
    clientIdentification: '',
    clientName: '',
    amount: 0,
    observations: '',
    createdAt: '',
    updatedAt: '',
    user: ''
  });
  // If received by previous screen
  const clientId = state?.clientId;
  const typeClient = state?.typeClient;
  const invoiceId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (clientId && typeClient && invoiceId) {
      getInvoice(invoiceId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  // prettier-ignore
  useEffect(() => {
    if (!loading && invoiceData) {
      setInvoice({
        voucher: invoiceData.voucher
          ? `${moment(invoiceData.voucher.voucherExpiration.slice(0, 10)).format('DD/MM/YYYY')} | ${invoiceData.voucher.authorizationCode}`
          : '',
        invoiceDate: moment(invoiceData.invoiceDate.slice(0, 10)).format('DD/MM/YYYY') || '',
        clientIdentification: invoiceData.clientIdentification || '',
        clientName: invoiceData.clientName || '',
        amount: invoiceData.amount || 0,
        observations: invoiceData.observations,
        createdAt: invoiceData?.createdAt
          ? moment(invoiceData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        updatedAt: invoiceData?.createdAt
          ? moment(invoiceData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: invoiceData?.user?.name || ''
      });
    }
  }, [loading, invoiceData]);

  return (
    <Page title="Facturas | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: clientId, typeClient }} />
        <Card>
          <CardHeader subheader="Información de registro" title="Detalles del Registro" />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Vencimiento Talonario | Código de autorización"
                  name="voucher"
                  onChange={null}
                  required
                  value={invoice.voucher}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Factura"
                  name="invoiceDate"
                  onChange={null}
                  required
                  value={invoice.invoiceDate}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="NIT/CI/CEX"
                  name="clientIdentification"
                  onChange={null}
                  required
                  value={invoice.clientIdentification}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Nombre o Razón Social del Cliente"
                  name="clientName"
                  onChange={null}
                  required
                  value={invoice.clientName}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Importe (Bs.)"
                  placeholder="Ingrese monto en bolivianos"
                  name="amount"
                  onChange={null}
                  required
                  value={invoice.amount}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observations"
                  onChange={null}
                  multiline
                  rows={4}
                  value={invoice.observations}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  required
                  value={invoice.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Modificación"
                  name="createdAt"
                  onChange={null}
                  required
                  value={invoice.updatedAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  required
                  value={invoice.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              variant="outlined"
              type="button"
              onClick={() => navigate(previousScreen, { state: { id: clientId, typeClient } })}
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
