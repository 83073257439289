import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
// component
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function KardexMoreMenu({
  id,
  isCompleted,
  isAdminUser,
  isDisabled,
  handleOperation
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-expanded={open ? 'true' : undefined}
        variant="outlined"
        size="small"
        disableElevation
        onClick={handleClick}
        endIcon={<Iconify icon="eva:arrow-ios-downward-outline" width={20} height={20} />}
      >
        Opciones
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* Update */}
        {isAdminUser && (
          <MenuItem
            disabled={isCompleted || isDisabled}
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleOperation('update', id);
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:edit-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {/* Invoice PDF */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('pdf', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:printer-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Recibo PDF" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Invoice PDF */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('pdfWithCopy', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:printer-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Recibo PDF con copia"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/* Invoice PDF */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('pdfWithPaperRoll', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:printer-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Recibo PDF rollo" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Invoice PDF duplicate */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('pdfWithPaperDuplicatedRoll', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:printer-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Recibo PDF rollo con copia"
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/* Details */}
        {isAdminUser && (
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleOperation('details', id);
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:eye-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Ver Detalles" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {/* Delete */}
        {isAdminUser && (
          <MenuItem
            disabled={isCompleted || isDisabled}
            sx={{ color: 'text.secondary' }}
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleOperation('delete', id);
            }}
          >
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Eliminar" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

KardexMoreMenu.propTypes = {
  id: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  handleOperation: PropTypes.func.isRequired
};
