import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
import { saveAs } from 'file-saver';
import moment from 'moment';
// components
import ClientContext from '../../context/client/clientContext';
import SnackbarContext from '../../context/snackbar/snackbarContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import { getFileFromServer, getFileExtension } from '../../utils/functionUtils';

export default function DocumentDetails() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const clientContext = useContext(ClientContext);
  const { openErrorSnackbar } = useContext(SnackbarContext);
  const {
    loading,
    clearData,
    getDocument,
    document: documentData,
    errors,
    setLoading: setLoadingClient
  } = clientContext;
  const [document, setDocument] = useState({
    title: '',
    file: '',
    observations: '',
    createdAt: '',
    updatedAt: '',
    user: ''
  });
  // Id received by previous screen
  const clientId = state?.clientId;
  const documentId = state?.id;
  const typeClient = state?.typeClient;
  const previousScreen = '/dashboard/clients/documents';

  // Fetch data
  useEffect(() => {
    if (typeClient && clientId && documentId) {
      setLoadingClient(false);
      getDocument(clientId, documentId);
    } else {
      navigate('/');
    }
    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [typeClient, clientId && documentId]);

  // Load document
  useEffect(() => {
    if (!loading && documentData) {
      setDocument({
        title: documentData.title || '',
        file: documentData.file || '',
        observations: documentData.observations || '',
        createdAt: documentData?.createdAt
          ? moment(documentData.createdAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        updatedAt: documentData?.updatedAt
          ? moment(documentData.updatedAt).format('DD/MM/YYYY [a las] HH:mm a')
          : '',
        user: documentData.user?.name || ''
      });
    }
  }, [loading, documentData]);

  const onDownloadFile = async () => {
    const path = document.file;
    const titleFile = `${document.title}-${Date.now()}`;
    const ext = getFileExtension(path);
    try {
      const resFile = await getFileFromServer(path);
      const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
      saveAs(pdfBlob, `${titleFile}.${ext}`);
    } catch (error) {
      openErrorSnackbar();
    }
  };

  return (
    <Page title="Documentos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} params={{ id: clientId, typeClient }} />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Información del registro del documento"
            title="Detalles del documento"
            sx={{ mb: 1 }}
          />
          <Divider />
          <CardContent>
            <Grid container spacing={3}>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Título"
                  name="title"
                  onChange={null}
                  value={document.title}
                  variant="outlined"
                />
              </Grid>
              <Grid item style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                <Grid item>
                  <FormHelperText style={{ fontSize: 14 }}>Archivo</FormHelperText>
                </Grid>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    type="button"
                    onClick={onDownloadFile}
                    startIcon={<Iconify icon="eva:cloud-download-outline" />}
                  >
                    Descargar Archivo
                  </Button>
                </Grid>
              </Grid>
              <Grid item md={12} xs={12}>
                <TextField
                  fullWidth
                  label="Observaciones"
                  name="observations"
                  onChange={null}
                  rows={4}
                  multiline
                  value={document.observations}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <FormHelperText
                  sx={{
                    fontWeight: 'bold',
                    fontSize: 16
                  }}
                >
                  Registro
                </FormHelperText>
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Registro"
                  name="createdAt"
                  onChange={null}
                  value={document.createdAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Fecha de Modificación"
                  name="createdAt"
                  onChange={null}
                  value={document.updatedAt}
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Registrado por"
                  name="user"
                  onChange={null}
                  value={document.user}
                  variant="outlined"
                />
              </Grid>
              {errors?.error && (
                <Grid item md={12} xs={12} lg={12}>
                  <Box sx={{ mt: 2 }}>
                    <FormHelperText error>{errors.error}</FormHelperText>
                  </Box>
                </Grid>
              )}
            </Grid>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              sx={{
                mr: 2
              }}
              variant="outlined"
              type="button"
              onClick={() => navigate(previousScreen, { state: { id: clientId, typeClient } })}
            >
              Regresar
            </Button>
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
