// routes
import moment from 'moment';
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
// context
import SnackbarState from './context/snackbar/SnackbarState';
import AuthState from './context/auth/AuthState';
import UserState from './context/user/UserState';
import AfpState from './context/afp/afpState';
import HealthInsuranceState from './context/healthInsurance/HealthInsuranceState';
import DepartmentState from './context/department/DepartmentState';
import MunicipalityState from './context/municipality/MunicipalityState';
import ClientState from './context/client/ClientState';
import CategoryState from './context/category/CategoryState';
import ServiceState from './context/service/ServiceState';
import ControlState from './context/control/ControlState';
import TransactionState from './context/transaction/TransactionState';
import ReportState from './context/report/ReportState';
import FileState from './context/file/FileState';
import ConfigurationState from './context/configuration/ConfigurationState';
import VoucherState from './context/voucher/VoucherState';
import InvoiceState from './context/invoice/InvoiceState';
// additional
import 'moment/locale/es';
import Snackbar from './components/Snackbar';
// ----------------------------------------------------------------------

export default function App() {
  // Set config moment locale
  moment.locale('es');

  return (
    <ThemeConfig>
      <SnackbarState>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <VoucherState>
          <InvoiceState>
            <ConfigurationState>
              <FileState>
                <ReportState>
                  <TransactionState>
                    <ControlState>
                      <ServiceState>
                        <CategoryState>
                          <ClientState>
                            <ClientState>
                              <MunicipalityState>
                                <DepartmentState>
                                  <HealthInsuranceState>
                                    <AfpState>
                                      <UserState>
                                        <AuthState>
                                          <Snackbar />
                                          <Router />
                                        </AuthState>
                                      </UserState>
                                    </AfpState>
                                  </HealthInsuranceState>
                                </DepartmentState>
                              </MunicipalityState>
                            </ClientState>
                          </ClientState>
                        </CategoryState>
                      </ServiceState>
                    </ControlState>
                  </TransactionState>
                </ReportState>
              </FileState>
            </ConfigurationState>
          </InvoiceState>
        </VoucherState>
      </SnackbarState>
    </ThemeConfig>
  );
}
