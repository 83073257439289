import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// material
import {
  Card,
  Button,
  Container,
  Box,
  Grid,
  CardHeader,
  FormHelperText,
  Divider,
  CardContent,
  TextField
} from '@mui/material';
// components
import HealthInsuranceContext from '../../context/healthInsurance/healthInsuranceContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';

const dialog = {
  message: '¿Estas seguro de actualizar el Seguro de Salud?'
};

export default function UpdateHealthInsurance() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const healthInsuranceContext = useContext(HealthInsuranceContext);
  const {
    loading,
    getHealthInsurance,
    healthInsurance: healthInsuranceData,
    clearData,
    updateHealthInsurance,
    errors
  } = healthInsuranceContext;
  const [healthInsurance, setHealthInsurance] = useState({
    name: '',
    address: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  // Id received by previous screen
  const healthInsuranceId = state?.id;

  useEffect(() => {
    // Verify if id was passed
    if (healthInsuranceId) {
      getHealthInsurance(healthInsuranceId);
    } else {
      // Go back screen
      navigate('/');
    }

    return () => clearData();
    // eslint-disable-next-line
  }, [healthInsuranceId]);

  useEffect(() => {
    if (!loading && healthInsuranceData && !errors) {
      setHealthInsurance({
        name: healthInsuranceData.name || '',
        address: healthInsuranceData.address || ''
      });
    }
  }, [loading, healthInsuranceData, errors]);

  const handleChange = (e) => {
    const { value, name } = e.target;

    return setHealthInsurance({
      ...healthInsurance,
      [name]: value
    });
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const onSubmit = async () => {
    const formData = {
      name: healthInsurance.name,
      address: healthInsurance.address
    };

    setOpenDialog(false);
    if ((await updateHealthInsurance(healthInsuranceId, formData)) === true) {
      navigate('/dashboard/configuration/health-insurances');
    }
  };

  return (
    <Page title="Seguros de Salud | G&G Asesor">
      <Container>
        <GoBackButton path="/dashboard/configuration/health-insurances" />
        <Card>
          {/* Content */}
          <CardHeader
            subheader="Ingrese los datos en el formulario para actualizar el Seguro de Salud"
            title="Actualizar Seguro de Salud"
          />
          <Box sx={{ mb: 1, ml: 3 }}>
            <FormHelperText>Los campos con (*) son requeridos.</FormHelperText>
          </Box>
          <Divider />
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setOpenDialog(true);
            }}
            noValidate
          >
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.name)}
                    helperText={errors?.name}
                    fullWidth
                    placeholder="Ingrese nombre"
                    label="Nombre"
                    name="name"
                    onChange={handleChange}
                    required
                    value={healthInsurance.name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    error={Boolean(errors?.address)}
                    helperText={errors?.address}
                    fullWidth
                    placeholder="Ingrese dirección (Barrio, Calle, Nº)"
                    label="Dirección"
                    name="address"
                    onChange={handleChange}
                    required
                    value={healthInsurance.address}
                    variant="outlined"
                  />
                </Grid>
                {errors?.error && (
                  <Grid item md={12} xs={12} lg={12}>
                    <Box sx={{ mt: 2 }}>
                      <FormHelperText error>{errors.error}</FormHelperText>
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                sx={{
                  mr: 2
                }}
                variant="outlined"
                type="button"
                onClick={() => navigate('/dashboard/configuration/health-insurances')}
              >
                Cancelar
              </Button>
              <Button
                disabled={loading}
                color="primary"
                variant="contained"
                type="submit"
                startIcon={<Iconify icon="eva:save-outline" />}
              >
                Aceptar
              </Button>
            </Box>
          </form>
        </Card>
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
