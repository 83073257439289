import React from 'react';
import { useLocation } from 'react-router-dom';
import UpdateClientIndependentProfessional from './UpdateClientIP';
import UpdateClientLegalEntity from './UpdateClientLE';
import UpdateClientSpecialRegime from './UpdateClientSR';
import UpdateClientUnipersonalEnterprise from './UpdateClientUE';

export default function IndexUpdateClient() {
  const { search: searchURL } = useLocation();
  const query = new URLSearchParams(searchURL);
  const paramTypeClient = query.get('typeClient');

  const isClientIndependentProfessional =
    paramTypeClient === 'Profesionales Independientes' || false;
  const isClientLegalEntity = paramTypeClient === 'Personas Juridicas SRL' || false;
  const isClientSpecialRegime = paramTypeClient === 'Regimenes Especiales' || false;
  const isClientUnipersonalEnterprise = paramTypeClient === 'Empresas Unipersonales' || false;

  let typeClient = '';
  if (isClientIndependentProfessional) {
    typeClient = 'Profesionales%20Independientes';
  }
  if (isClientLegalEntity) {
    typeClient = 'Personas%20Juridicas%20SRL';
  }
  if (isClientSpecialRegime) {
    typeClient = 'Regimenes%20Especiales';
  }
  if (isClientUnipersonalEnterprise) {
    typeClient = 'Empresas%20Unipersonales';
  }

  if (isClientIndependentProfessional) {
    return (
      <UpdateClientIndependentProfessional
        isClientIndependentProfessional={isClientIndependentProfessional}
        typeClient={typeClient}
      />
    );
  }

  if (isClientLegalEntity) {
    return (
      <UpdateClientLegalEntity isClientLegalEntity={isClientLegalEntity} typeClient={typeClient} />
    );
  }

  if (isClientSpecialRegime) {
    return (
      <UpdateClientSpecialRegime
        isClientSpecialRegime={isClientSpecialRegime}
        typeClient={typeClient}
      />
    );
  }

  if (isClientUnipersonalEnterprise) {
    return (
      <UpdateClientUnipersonalEnterprise
        isClientUnipersonalEnterprise={isClientUnipersonalEnterprise}
        typeClient={typeClient}
      />
    );
  }

  return <div></div>;
}
