import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@mui/material';
// component
import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function ControlMoreMenu({ id, isCompleted, isAdminUser, handleOperation }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
        endIcon={<Iconify icon="eva:arrow-ios-downward-outline" width={20} height={20} />}
      >
        Opciones
      </Button>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {/* Fusion control */}
        <MenuItem
          disabled={isCompleted}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('fusion', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:book-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Fusionar Kardex" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Complete */}
        <MenuItem
          disabled={!isAdminUser}
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('complete', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:book-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary={isCompleted ? 'Habilitar Kardex' : 'Cerrar Kardex'}
            primaryTypographyProps={{ variant: 'body2' }}
          />
        </MenuItem>

        {/* Edit */}
        <MenuItem
          disabled={isCompleted}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('update', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Details */}
        <MenuItem
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={(e) => {
            e.preventDefault();
            handleClose();
            handleOperation('details', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:eye-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Ver Detalles" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        {/* Delete */}
        <MenuItem
          disabled={!isAdminUser}
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            handleClose();
            handleOperation('delete', id);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Eliminar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}

ControlMoreMenu.propTypes = {
  id: PropTypes.string.isRequired,
  isCompleted: PropTypes.bool.isRequired,
  handleOperation: PropTypes.func.isRequired,
  isAdminUser: PropTypes.bool.isRequired
};
