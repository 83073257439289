import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
// context
import ServiceContext from '../../context/service/serviceContext';
import AuthContext from '../../context/auth/authContext';
// components
import ButtonMoreMenu from '../../components/ButtonMoreMenu';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import DialogConfirmation from '../../components/DialogConfirmation';
import Search from '../../components/Search';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';
import { rolesUser } from '../../constants';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Concepto'
  },
  {
    id: 1,
    name: 'Precio (Bs)'
  },
  {
    id: 2,
    name: 'Categoría'
  },
  {
    id: 3,
    name: ''
  }
];

export default function ServicesListing() {
  const serviceContext = useContext(ServiceContext);
  const authContext = useContext(AuthContext);
  const { loading, getServices, services, clearData, deleteService, errors } = serviceContext;
  const { user } = authContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const isAdminUser = user?.data?.role === rolesUser.administrator;

  useEffect(() => {
    getServices();

    return () => clearData();
    // eslint-disable-next-line
  }, []);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      services?.map((item) => ({
        id: item._id,
        concept: item.concept,
        category: item.category?.name || '-',
        price: item.price > 0 ? item.price.toFixed(2) : '0.00'
      })) || [];

    if (search) {
      computedItems = computedItems.filter(
        (comment) =>
          comment.concept.toLowerCase().includes(search.toLowerCase()) ||
          comment.price.toLowerCase().includes(search.toLowerCase()) ||
          comment.category.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [services, page, rowsPerPage, search]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    getServices();
  };

  const handleAction = (action, id, data = {}) => {
    if (action === 'update') {
      navigate(`/dashboard/configuration/services/update`, {
        state: { id }
      });
    }

    if (action === 'delete') {
      const { name } = data;
      const message = `Esta seguro de eliminar de forma permanente el servicio ${name}?`;
      handleOpenDialog(message, id, 'delete');
    }

    if (action === 'details') {
      navigate(`/dashboard/configuration/services/details`, {
        state: { id }
      });
    }
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteService(itemRowSelected.id)) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  const onClearSearch = () => {
    setSearch('');
    getServices();
  };

  return (
    <Page title="Configuración | G&G Asesor">
      <Container>
        <Grid container mb={5}>
          <Grid item xs={12} md={4}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Servicios-Tarifas
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={() => onReload()}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                {isAdminUser && (
                  <Button
                    variant="contained"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate('/dashboard/configuration/services/create');
                    }}
                    startIcon={<Iconify icon="eva:plus-fill" />}
                  >
                    Nuevo Servicio
                  </Button>
                )}
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Servicios
                </Typography>
                <Box
                  display="flex"
                  gap={2}
                  justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                >
                  <Search
                    search={search}
                    setSearch={setSearch}
                    placeholder="Buscar por concepto, precio, categoría"
                  />
                  <Button onClick={onClearSearch} variant="outlined" color="secondary">
                    Limpiar
                  </Button>
                </Box>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.concept}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.price}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.category}
                      </TableCell>
                      {isAdminUser && (
                        <TableCell>
                          <ButtonMoreMenu
                            id={row.id}
                            name={row.concept}
                            handleAction={handleAction}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={5}
                      count={services?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
