import React, { useEffect, useContext, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import { saveAs } from 'file-saver';
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Grid,
  Box,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Paper,
  TablePagination,
  TableFooter
} from '@mui/material';
import { styled } from '@mui/material/styles';
// components
import ClientContext from '../../context/client/clientContext';
import SnackbarContext from '../../context/snackbar/snackbarContext';
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import ButtonMoreMenu from '../../components/ButtonMoreMenu';
import GoBackButton from '../../components/GoBackButton';
import DialogConfirmation from '../../components/DialogConfirmation';
import Search from '../../components/Search';
import CircularLoading from '../../components/CircularLoading';
import TablePaginationActions from '../../components/TablePaginationActions';
import { getFileFromServer, getFileExtension } from '../../utils/functionUtils';

const TableStyle = styled(Table)(() => ({
  minWidth: 650
}));

const columnsTable = [
  {
    id: 0,
    name: 'Título'
  },
  {
    id: 1,
    name: 'Fecha de Modificación'
  },
  {
    id: 3,
    name: 'Observaciones'
  },
  {
    id: 4,
    name: 'Usuario'
  },
  {
    id: 5,
    name: ''
  },
  {
    id: 6,
    name: ''
  }
];

export default function DocumentListing() {
  const { state } = useLocation();
  const { openErrorSnackbar } = useContext(SnackbarContext);
  const clientContext = useContext(ClientContext);
  const { loading, getDocuments, documents, clearData, deleteDocument, errors } = clientContext;
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const [itemRowSelected, setItemRowSelected] = useState({
    id: ''
  });
  const [dialog, setDialog] = useState({
    message: '',
    action: ''
  });
  const [search, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  // Id received by previous screen
  const clientId = state?.id;
  const typeClient = state?.typeClient;
  const previousScreen = `/dashboard/clients/listing?typeClient=${typeClient}`;

  useEffect(() => {
    if (typeClient && clientId) {
      getDocuments(clientId);
    } else {
      navigate('/');
    }

    return () => {
      clearData();
    };
    // eslint-disable-next-line
  }, [typeClient, clientId]);

  // Get data table
  const rows = useMemo(() => {
    let computedItems =
      documents?.map((item) => ({
        id: item._id,
        title: item.title || '',
        file: item.file || '',
        observations: item.observations || '-',
        updatedAt: item.createdAt
          ? `${moment(item.updatedAt).format('DD/MM/YYYY [a las] HH:mm')}`
          : '',
        user: item.user?.name || ''
      })) || [];

    if (search) {
      computedItems = computedItems.filter(
        (item) =>
          item.title.toLowerCase().includes(search.toLowerCase()) ||
          item.updatedAt.toLowerCase().includes(search.toLowerCase()) ||
          item.user.toLowerCase().includes(search.toLowerCase())
      );
    }

    // Filter with pagination pages
    computedItems =
      rowsPerPage > 0
        ? computedItems.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : computedItems;

    return computedItems;
  }, [documents, page, search, rowsPerPage]);

  // Reset dialog
  useEffect(() => {
    if (!openDialog && itemRowSelected === '') {
      setDialog({ message: '', action: '' });
    }
  }, [openDialog, itemRowSelected]);

  const onReload = () => {
    if (clientId) {
      getDocuments(clientId);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleOpenDialog = (message, id, action) => {
    setDialog({ message, action });
    setItemRowSelected({ id });
    setOpenDialog(true);
  };

  const handleCloseDialog = () => setOpenDialog(false);

  const handleAction = async (action, id, data = {}) => {
    if (action === 'download') {
      const { name, path } = data;
      const titleFile = `${name}-${Date.now()}`;
      const ext = getFileExtension(path);
      try {
        const resFile = await getFileFromServer(path);
        const pdfBlob = new Blob([resFile.data], { type: 'application/pdf' });
        saveAs(pdfBlob, `${titleFile}.${ext}`);
      } catch (error) {
        openErrorSnackbar();
      }
    }

    if (action === 'update') {
      navigate(`/dashboard/clients/documents/update`, {
        state: { clientId, id, typeClient }
      });
    }

    if (action === 'delete') {
      const { name } = data;
      const message = `Esta seguro de eliminar de forma permanente el documento con título ${name}?`;
      handleOpenDialog(message, id, 'delete');
    }

    if (action === 'details') {
      navigate(`/dashboard/clients/documents/details`, {
        state: { clientId, id, typeClient }
      });
    }
  };

  const onSubmit = async () => {
    if (dialog.action === 'delete') {
      if ((await deleteDocument(clientId, itemRowSelected.id)) === true) {
        onReload();
      }
    }

    // Reset dialog data
    setOpenDialog(false);
    setItemRowSelected({ id: '' });
  };

  return (
    <Page title="Documentos | G&G Asesor">
      <Container>
        <GoBackButton path={previousScreen} />
        <Grid container mb={5}>
          <Grid item xs={12} md={8}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography variant="h4" gutterBottom>
                Documentos Subidos
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box display="flex" justifyContent={{ sm: 'flex-end', xs: 'center', md: 'flex-end' }}>
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  to="#"
                  color="secondary"
                  onClick={onReload}
                  startIcon={<Iconify icon="eva:refresh-fill" />}
                >
                  Recargar
                </Button>
                <Button
                  variant="contained"
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`/dashboard/clients/documents/add`, {
                      state: {
                        clientId,
                        typeClient
                      }
                    });
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Nuevo Documento
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        {/* Loading */}
        {loading && <CircularLoading height={100} />}

        {/* Error */}
        {errors?.error ? (
          <Box padding={3} display="flex" justifyContent="center">
            <Typography variant="body2" color="red">
              {errors.error}
            </Typography>
          </Box>
        ) : (
          // Table
          <Paper>
            <TableContainer>
              <Box padding={3}>
                <Typography variant="h6" gutterBottom>
                  Lista de Documentos del Cliente
                </Typography>
                <Box
                  display="flex"
                  gap={2}
                  justifyContent={{ sm: 'flex-start', xs: 'start', md: 'flex-start' }}
                >
                  <Search
                    search={search}
                    setSearch={setSearch}
                    placeholder="Buscar por título, fecha de modificación DD/MM/YYYY, usuario"
                  />
                  <Button type="submit" variant="outlined" color="secondary">
                    Buscar
                  </Button>
                </Box>
              </Box>
              <TableStyle aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columnsTable.map((item) => (
                      <TableCell key={item.id}>{item.name}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.title}
                      </TableCell>
                      <TableCell>{row.updatedAt}</TableCell>
                      <TableCell>{row.observations}</TableCell>
                      <TableCell>{row.user}</TableCell>
                      <TableCell>
                        <Button
                          variant="contained"
                          color="primary"
                          size="medium"
                          onClick={() =>
                            handleAction('download', row.id, { name: row.title, path: row.file })
                          }
                          startIcon={<Iconify icon="eva:cloud-download-outline" />}
                        >
                          Descargar Archivo
                        </Button>
                      </TableCell>
                      <TableCell>
                        <ButtonMoreMenu id={row.id} name={row.title} handleAction={handleAction} />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'Todos', value: -1 }]}
                      colSpan={6}
                      count={documents?.length || 0}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      labelRowsPerPage="Filas por página"
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                      labelDisplayedRows={({ from, to, count }) =>
                        `${from}–${to} de ${count !== -1 ? count : `más que ${to}`}`
                      }
                    />
                  </TableRow>
                </TableFooter>
              </TableStyle>
            </TableContainer>
          </Paper>
        )}
      </Container>

      {/* Dialog */}
      <DialogConfirmation
        openDialog={openDialog}
        handleCloseDialog={handleCloseDialog}
        onSubmit={onSubmit}
        dialog={dialog}
        loading={loading}
      />
    </Page>
  );
}
